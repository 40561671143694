import * as Yup from 'yup'

import { addressValidation, numberBasicValidation } from 'utils'

export const validationSchema = Yup.object({
  code: Yup.string().max(50, 'Max length of 50 characters').required('Location Code is required'),
  name: Yup.string().max(50, 'Max length of 50 characters').required('Name is required'),
  latitude: numberBasicValidation
    .min(-90, 'Latitude should be in [-90, 90] range')
    .max(90, 'Latitude should be in [-90, 90] range')
    .required('Latitude is required'),
  longitude: numberBasicValidation
    .min(-180, 'Longitude should be in [-180, 180] range')
    .max(180, 'Longitude should be in [-180, 180] range')
    .required('Longitude is required'),
  ...addressValidation,
})
