import * as Yup from 'yup'

import { FlatFee } from 'api'
import { addressValidation, numberBasicValidation, phoneNumberValidation, uniqueEmailValidation } from 'utils'

export const validationSchema = Yup.object({
  locationCodeId: numberBasicValidation.required('Location Code is required'),
  code: Yup.string().max(100, 'Max length of 100 characters').required('Code is required'),
  accountNumber: Yup.string().max(100, 'Max length of 100 characters').nullable(),
  gateInFee: numberBasicValidation.nullable().notRequired(),
  gateOutFee: numberBasicValidation.nullable().notRequired(),
  liftFee: numberBasicValidation.nullable().notRequired(),
  flatFee: Yup.mixed<FlatFee>().oneOf(Object.values(FlatFee)).nullable(),
  tiltBetPerMile: numberBasicValidation.nullable().positive('Tilt bet per mile should be positive number'),
  flatBetPerMile: numberBasicValidation.nullable().positive('Flat bet per mile should be positive number'),
  minimumDeliveryFee: numberBasicValidation.nullable().positive('Minimum delivery fee should be positive number'),
  dailyRatePerTEU: numberBasicValidation.nullable().notRequired(),
  dailyRatePerX2TEU: numberBasicValidation.nullable().notRequired(),
  laborRatePerHour: numberBasicValidation.nullable().notRequired(),
  bookingEmails: Yup.array().of(uniqueEmailValidation),
  redeliveryEmails: Yup.array().of(uniqueEmailValidation),
  secondaryEmails: Yup.array().of(uniqueEmailValidation),
  primaryEmails: Yup.array().of(uniqueEmailValidation),
  acceptTransload: Yup.boolean().default(false),
  availableInCalculator: Yup.boolean().default(false),
  phoneNumber: phoneNumberValidation.nullable(),
  ...addressValidation,
})
