import { api } from 'api'

export interface SubmitResetPasswordPayload {
  params: {
    token: string
  }
  data: {
    password: string
  }
}

export const SubmitResetPassword = (payload: SubmitResetPasswordPayload): Promise<void> => {
  const { params, data } = payload
  return api({
    method: 'patch',
    url: '/api/v1/auth/password/reset',
    data,
    params,
    headers: { withAuth: false },
  })
}
