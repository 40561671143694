import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { GetReferenceItemsPayload } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteReferenceItem } from '../queryApi'

export const useDeleteReferenceItem = (referenceId?: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(deleteReferenceItem, {
    onSuccess: (data, payload) => {
      dispatchAppEvent(AppEvent.ReferenceNumberItemsUpdate)
      queryClient.setQueryData(
        [QueryKey.GetReferenceItems, referenceId],
        (prev: GetReferenceItemsPayload | undefined) => {
          if (!prev) {
            return undefined
          }
          return {
            ...prev,
            data: prev.data.filter((item) => item.id !== payload.itemId),
          }
        },
      )
      showNotification({ title: 'Item deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (itemId: number) => {
      mutate({ referenceId, itemId })
    },
    [mutate, referenceId],
  )

  return {
    remove,
    ...rest,
  }
}
