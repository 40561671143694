import { FC } from 'react'

import theme from 'styles/theme'

import { Container, Icon, Spacer, Typography } from 'designSystem'
import { Card } from 'components'

import { CarrierEquipments } from './elements/CarrierEquipments'
import { CarrierLocations } from './elements/CarrierLocations'
import * as Styled from './styles'

import { CarrierInfo } from '../CarrierInfo'

interface CarrierSettingsProps {
  isEdit?: boolean
}
export const CarrierSettings: FC<CarrierSettingsProps> = ({ isEdit }) => (
  <Card bordered={!isEdit} pa={isEdit ? 0 : undefined}>
    <Container display="flex" fd="column" gap={16}>
      {!isEdit && (
        <Typography size="l">
          <Typography size="l" fontWeight="xl">
            Carrier
          </Typography>{' '}
          Settings
        </Typography>
      )}
      {!isEdit && (
        <Styled.InfoWrapper>
          <Icon icon="info" color={theme.colors.accent[400]} size={16} />
          <Typography size="xs" fontWeight="l">
            Additional information you can add after vendor will be created
          </Typography>
        </Styled.InfoWrapper>
      )}
      <CarrierInfo name="carrierSettings.type" />
      <Spacer mb={4} />
      <Typography fontWeight="xl" size="s">
        Locations
      </Typography>
      <CarrierLocations />

      <Typography fontWeight="xl" size="s">
        Equipment Settings
      </Typography>
      <CarrierEquipments />
    </Container>
  </Card>
)
