import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'
import { useSetPurchaseOrderData } from 'store'

import { getPurchaseOrderById } from 'modules/purchaseOrders/queryApi'

export const useGetPurchaseOrderById = (id: EntityId) => {
  const setPurchaseOrderData = useSetPurchaseOrderData()
  const navigate = useNavigate()

  const { data, ...rest } = useQuery([QueryKey.GetPurchaseOrderById, id], () => getPurchaseOrderById(id), {
    enabled: !!id,
    onSuccess: (data) => {
      setPurchaseOrderData(data)
    },
    onError: () => {
      navigate(route.pos)
    },
  })

  return {
    data,
    ...rest,
  }
}
