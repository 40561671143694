import { api, EntityId, LocationCode } from 'api'

export const getLocationCodeById = (id?: EntityId): Promise<LocationCode> => {
  return api({
    method: 'get',
    url: `/api/v1/location-codes/${id}`,
  })
}

export const deleteLocationCode = (id?: EntityId): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/location-codes/${id}`,
  })
}
