import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getCustomerById } from '../queryApi'

export const useGetCustomerById = (id: EntityId) => {
  const navigate = useNavigate()

  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetCustomerById, id],
    () => getCustomerById(id),
    {
      enabled: !!id,
      onError: () => {
        navigate(route.customers)
      },
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
