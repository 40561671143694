import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Contact, GetCustomerContactsResponse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { patchCustomerContact } from '../queryApi'

export const usePatchCustomerContact = (id: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(patchCustomerContact, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.CustomerContactUpdate)

      queryClient.setQueryData([QueryKey.GetCustomerContacts, id], (old: GetCustomerContactsResponse | undefined) =>
        old
          ? {
              ...old,
              data: old?.data.map((contact) => (contact.id === data.id ? data : contact)),
            }
          : undefined,
      )
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const patch = useCallback(
    (payload: Contact) => {
      mutate({ customerId: id, contactId: payload.id, data: payload })
    },
    [mutate, id],
  )

  return {
    patch,
    ...rest,
  }
}
