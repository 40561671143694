import type { ApiQueryParams, Asset } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export const getAssetsListJoinQueryParams = [
  'locationCode||id,code',
  'items||id,sku,locationCode',
  'items.locationCode||id,code',
]

export const getAssetsJoinQueryParams = [
  ...getAssetsListJoinQueryParams,
  'vendorDepot||id,code',
  'vendor||id,companyName',
  'purchaseOrderSubLine||poItem',
  'purchaseOrderSubLine.poItem||purchaseOrder',
  'purchaseOrderSubLine.poItem.purchaseOrder||id,number,createdAt,type,vendorId,vendorName,expirationDate,status,notes',
  'vendorReleaseReferenceNumber||id,number,status',
]

export const getAssetsSortQueryParams = ['items.id,ASC']

export interface GetAssetsResponse extends Pagination {
  data?: Asset[]
}

export type GetAssetsQueryParams = ApiQueryParams

export const getAssets = (params?: GetAssetsQueryParams): Promise<GetAssetsResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/assets/',
    params: {
      join: getAssetsListJoinQueryParams,
      sort: getAssetsSortQueryParams,
      ...params,
    },
  })
}
