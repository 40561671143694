import { PurchaseOrderItemsSubLine } from 'api'
import { PurchaseOrderSubLineAccessor } from 'types'
import { formatDate } from 'utils'

import { getFormattedTableCellValue, TableColumnsType } from 'components'
import { getAccessorSubLineTitle } from 'modules/purchaseOrders/utils/getAccessorSubLineTitle'

export const columns: TableColumnsType<Partial<PurchaseOrderItemsSubLine>> = [
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.ExpectedDeliveryDate),
    key: PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
    dataIndex: PurchaseOrderSubLineAccessor.ExpectedDeliveryDate,
    width: 200,
    render: (value) => {
      return getFormattedTableCellValue(formatDate(value))
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.LocationCodeId),
    key: PurchaseOrderSubLineAccessor.LocationCodeId,
    dataIndex: PurchaseOrderSubLineAccessor.LocationCodeId,
    width: 120,
    render: (value, record) => {
      return getFormattedTableCellValue(record.asset?.locationCode?.code)
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.VendorDepotId),
    key: PurchaseOrderSubLineAccessor.VendorDepotId,
    dataIndex: PurchaseOrderSubLineAccessor.VendorDepotId,
    width: 120,
    render: (value, record) => {
      return getFormattedTableCellValue(record.asset?.vendorDepot?.code)
    },
  },
  {
    title: getAccessorSubLineTitle(PurchaseOrderSubLineAccessor.AssetNumber),
    key: PurchaseOrderSubLineAccessor.AssetNumber,
    dataIndex: PurchaseOrderSubLineAccessor.AssetNumber,
    width: 120,
    render: (value, record) => {
      return getFormattedTableCellValue(record.asset?.assetNumber)
    },
  },
]
