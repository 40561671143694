import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Warehouse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postWarehouse } from '../queryApi'

export const usePostWarehouse = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(postWarehouse, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.WarehouseCreate)

      queryClient.setQueryData([QueryKey.GetWarehouses], (old: Warehouse[] | undefined) => {
        const updatedData = old ? [...old, data] : [data]
        return updatedData as Warehouse[]
      })

      showNotification({ title: 'Warehouse added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: Warehouse) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    post,
    ...rest,
  }
}
