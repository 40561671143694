export enum ReferenceSubLineFormAccessor {
  Asset = 'number',
  AssetId = 'assetId',
  PickupDate = 'pickupDate',
  RedeliveryReferenceId = 'redeliveryReferenceId',
  Status = 'status',
  IsModificationRequired = 'isModificationRequired',
  IsRepairRequired = 'isRepairRequired',
  GateOutDate = 'gateOutDate',
  ReceivedDate = 'receivedDate',
  Condition = 'condition',
  ContainerColor = 'containerColor',
  PickupReferenceId = 'pickupReferenceId',
}
