import { useDeletePOContact } from './useDeletePOContact'
import { usePostContactFromVendorToPO } from './usePostPOContacts'
import { usePostPONewContact } from './usePostPONewContact'

interface UsePOContactsRequestsProps {
  purchaseOrderId: number
  vendorId?: number
}

export const usePOContactsRequests = ({ purchaseOrderId, vendorId }: UsePOContactsRequestsProps) => {
  const {
    post: postPOContacts,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isLoading: isPostLoading,
  } = usePostContactFromVendorToPO({ vendorId, purchaseOrderId })

  const {
    post: postNewContact,
    isSuccess: isPostNewSuccess,
    isError: isPostNewError,
    isLoading: isPostNewLoading,
  } = usePostPONewContact(purchaseOrderId)

  const {
    remove: deleteContact,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeletePOContact(purchaseOrderId)

  return {
    isSuccess: isPostSuccess || isPostNewSuccess || isDeleteSuccess,
    isError: isPostError || isPostNewError || isDeleteError,
    isLoading: isPostLoading || isPostNewLoading || isDeleteLoading,
    postPOContacts,
    postNewContact,
    deleteContact,
  }
}
