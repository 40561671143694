import { FC } from 'react'
import { Skeleton } from 'antd'

import { HashRouterComponentProps } from 'types'

import { Drawer } from 'designSystem'

import { useGetSalesOrderById } from './hooks/useGetSalesOrderById'
import { SalesOrderTabs } from './elements/SalesOrderTabs'
import { SalesOrderTitle } from './elements/SalesOrderTitle'

export const SalesOrder: FC<HashRouterComponentProps> = ({ hash }) => {
  const salesOrderId = Number(hash.split('/')?.[1])
  const { data, isLoading } = useGetSalesOrderById(salesOrderId)

  return (
    <Drawer
      hashUrl={hash}
      width={1050}
      bg="transparent"
      pa={0}
      title={isLoading && !data?.number ? <Skeleton.Input active /> : data && <SalesOrderTitle number={data.number} />}
      isLoading={isLoading}
    >
      {!isLoading && <SalesOrderTabs data={data} />}
    </Drawer>
  )
}
