import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Customer, EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteCustomerBillingAddress } from '../queryApi'

export const useRemoveCustomerBillingAddress = (customerId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(deleteCustomerBillingAddress, {
    onSuccess: (data, payload) => {
      dispatchAppEvent(AppEvent.CustomerUpdate)
      queryClient.setQueryData([QueryKey.GetCustomerById, customerId], (prev: Customer | undefined) => {
        return prev
          ? { ...prev, billingAddresses: prev.billingAddresses?.filter((address) => address.id !== payload.id) }
          : undefined
      })
      showNotification({ title: 'Billing Address deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (id: number) => {
      mutate({ customerId, id })
    },
    [mutate, customerId],
  )

  return {
    remove,
    ...rest,
  }
}
