import { salesOrdersRoute } from 'constant'

import { NotFoundPage } from 'components'

// import { SalesOrderCreate } from '../routes/SalesOrderCreate'
import { SalesOrdersList } from '../routes/SalesOrdersList'

export const routes = [
  {
    path: salesOrdersRoute.list,
    element: <SalesOrdersList />,
  },
  // {
  //   path: salesOrdersRoute.create,
  //   element: <SalesOrderCreate />,
  // },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
