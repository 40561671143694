import { PurchaseOrder } from 'api'

export const prepareNewSubLineData = (poData?: PurchaseOrder) => ({
  expectedDeliveryDate: poData?.expectedDate,
  locationCodeId: poData?.locationCodeId,
  referenceType: poData?.type || null,
  asset: {
    vendorDepotId: poData?.depotId,
    vendorReleaseRefId: poData?.vendorReleaseReferenceNumber?.id || null,
    redeliveryRefId: poData?.redeliveryReferenceNumber?.id || null,
  },
})
