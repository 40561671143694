import { FC, useEffect } from 'react'

import { AssetNumberType } from 'api'
import { useFormContext, useWatch } from 'hooks'
import { assetNumberTypeLongLabel } from 'constant'

import { Container, Typography } from 'designSystem'

import { getAssetNumberTypeByAssetNumber } from './utils/getAssetNumberTypeByAssetNumber'

export const AssetNumberTypeView: FC = () => {
  const { setValue } = useFormContext()
  const assetNumber = useWatch({ name: 'assetNumber' })
  const assetNumberType = useWatch({ name: 'assetNumberType' }) as AssetNumberType
  const assetNumberTypeLabel = assetNumberTypeLongLabel[assetNumberType]

  useEffect(() => {
    const newType = getAssetNumberTypeByAssetNumber(assetNumber)
    if (newType !== assetNumberType) {
      setValue('assetNumberType', newType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetNumber])

  return (
    !!assetNumber && (
      <Container mt={2} bg="accent.80" display="inline-flex" br="small" px={3}>
        <Typography color="info" fontWeight="l">
          {assetNumberTypeLabel}
        </Typography>
      </Container>
    )
  )
}
