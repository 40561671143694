import { FC, useCallback } from 'react'

import { LocationCode } from 'api'
import { useForm } from 'hooks'

import { Container, Divider, EditFormItem } from 'designSystem'
import {
  Card,
  ConfirmationRequiredPopover,
  FormItemEditAddress,
  FormItemEditInput,
  FormItemEditInputNumber,
  GoogleMap,
  StripLoader,
} from 'components'

import { useUpdateLocationCode } from './hooks/useUpdateLocationCode'
import { validationSchema } from '../../../../utils/validationSchema'
import { LocationCodeForm } from '../../../../types/locationCodeForm'
import { LocationCodePayload } from '../../../../types/locationCodePayload'

interface LocationCodeDetailsProps {
  data: LocationCode
}

export const LocationCodeDetails: FC<LocationCodeDetailsProps> = ({ data }) => {
  const { update, isError, isLoading } = useUpdateLocationCode(Number(data.id))

  const onSubmit = useCallback(
    (payload: LocationCodePayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<LocationCodeForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: data,
  })

  return (
    <Container pa={4} fd="column" gap={16} bg="white">
      <Card maxWidth={390}>
        <StripLoader isLoading={isLoading} />
        <Form>
          <EditFormItem
            name="code"
            render={({ field }) => (
              <FormItemEditInput
                size="large"
                align="left"
                {...field}
                onConfirm={triggerSubmit}
                popover={<ConfirmationRequiredPopover withPopover />}
              />
            )}
          />
          <Divider mt={7} mb={4} />
          <EditFormItem
            name="name"
            label="Name"
            render={({ field }) => <FormItemEditInput {...field} onBlur={triggerSubmit} />}
          />
          <Divider my={4} />
          <FormItemEditAddress label="Address" onConfirm={triggerSubmit} hideFields={['street', 'zipCode']} />
          <Divider my={4} />
          <Container fd="column" gap={4}>
            <EditFormItem
              name="latitude"
              label="Latitude"
              render={({ field }) => (
                <FormItemEditInputNumber {...field} precision={13} positive={false} onBlur={triggerSubmit} />
              )}
            />
            <EditFormItem
              name="longitude"
              label="Longitude"
              render={({ field }) => (
                <FormItemEditInputNumber {...field} precision={13} positive={false} onBlur={triggerSubmit} />
              )}
            />
          </Container>
        </Form>
      </Card>
      <GoogleMap
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        mapContainerStyle={{ width: 390, height: 360, borderRadius: 6 }}
        center={{ lat: data.latitude, lng: data.longitude }}
        markers={[{ lat: data.latitude, lng: data.longitude, title: data.name }]}
      />
    </Container>
  )
}
