import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { EquipmentPayload } from '../types/equipmentCreateForm'

import { postEquipment } from '../queryApi'

export const usePostEquipment = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(postEquipment, {
    onSuccess: () => {
      dispatchAppEvent(AppEvent.EquipmentCreate)
      showNotification({ title: 'Equipment added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: EquipmentPayload) => {
      mutate({ vendorId, data: payload })
    },
    [mutate, vendorId],
  )

  return {
    post,
    ...rest,
  }
}
