import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { VendorModificationsSettings } from 'api'

import { getPatchPayload } from '../utils/getPatchPayload'
import { ModificationFormPayload } from '../types/modificationCreateForm'

import { useDeleteModifications } from './useDeleteModifications'
import { usePatchModifications } from './usePatchModifications'
import { usePostModifications } from './usePostModifications'

export const useModificationsRequests = (
  setIsAddNew: (isAddNew: boolean) => void,
  data: VendorModificationsSettings[],
) => {
  const { id: vendorId } = useParams()

  const {
    post,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isLoading: isPostLoading,
  } = usePostModifications(Number(vendorId))

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isLoading: isPatchLoading,
  } = usePatchModifications(Number(vendorId))

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeleteModifications(Number(vendorId))

  const onSubmitPost = useCallback(
    (payload: ModificationFormPayload) => {
      if (payload.item) {
        const payloadData = {
          ...payload.item,
          ...(!payload.item.resaleMargin?.operand && { resaleMargin: null }),
        }
        post(payloadData)
        setIsAddNew(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: ModificationFormPayload) => {
      const patchData = getPatchPayload(payload, data)
      patchData?.forEach(({ id, payload }) => {
        patch(id as number, payload)
      })
    },
    [patch, data],
  )

  const onDelete = useCallback(
    (modificationId?: number) => {
      if (modificationId) {
        remove(modificationId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPostSuccess || isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteError,
    isEditLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
  }
}
