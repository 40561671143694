import { accessManagementRoot } from './constants/accessManagementRoute'
import { assetCodesRoot, assetCodesRoute } from './constants/assetCodesRoute'
import { assetsRoot, assetsRoute } from './constants/assetsRoute'
import { customersRoot, customersRoute } from './constants/customersRoute'
import { globalInventoryRoot, globalInventoryRoute } from './constants/globalInventoryRoute'
import { globalSettingsRoot, globalSettingsRoute } from './constants/globalSettingsRoute'
import { itemsRoot, itemsRoute } from './constants/itemsRoute'
import { locationCodesRoot, locationCodesRoute } from './constants/locationCodesRoute'
import { profileRoute, profileRouteRoot } from './constants/profileRoute'
import { purchaseOrderRoot, purchaseOrdersRoute } from './constants/purchaseOrdersRoute'
import { referencesRoot, referencesRoute } from './constants/rederencesRoute'
import { salesOrderRoot, salesOrdersRoute } from './constants/salesOrderRoute'
import { settingsRoot, settingsRoute } from './constants/settingsRoute'
import { truckCodesRoot, truckCodesRoute } from './constants/truckCodesRoute'
import { userManagementRoot, userManagementRoute } from './constants/userManagementRoute'
import { vendorsRoot, vendorsRoute } from './constants/vendorsRoute'

export const hash = {
  reference: '#reference',
  po: '#po',
  so: '#so',
}

export const route = {
  //Auth
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',

  //Home
  home: '/',
  transfers: '/transfers',
  redeliveries: '/redeliveries',
  company: '/company',

  // Customers
  customers: customersRoot,
  customersCreate: `${customersRoot}${customersRoute.create}`,

  // Global Inventory
  globalInventory: globalInventoryRoot,
  globalInventoryVendor: `${globalInventoryRoot}${globalInventoryRoute.vendor}`,

  // Vendors
  vendors: vendorsRoot,
  vendorsCreate: `${vendorsRoot}${vendorsRoute.create}`,

  // Items
  items: itemsRoot,
  itemsCreate: `${itemsRoot}${itemsRoute.create}`,

  // Assets
  assets: assetsRoot,
  assetsCreate: `${assetsRoot}${assetsRoute.create}`,

  //Settings
  settings: settingsRoot,
  settingsCompany: `${settingsRoot}${settingsRoute.company}`,
  settingsNotifications: `${settingsRoot}${settingsRoute.notifications}`,

  // Global Settings
  globalSettings: globalSettingsRoot,
  accessManagement: `${globalSettingsRoot}${globalSettingsRoute.accessManagement}`,
  emailSettings: `${globalSettingsRoot}${globalSettingsRoute.emailSettings}`,
  assetStatuses: `${globalSettingsRoot}${globalSettingsRoute.assetStatuses}`,
  depotCodes: `${globalSettingsRoot}${globalSettingsRoute.depotCodes}`,

  //User Management
  userManagement: `${globalSettingsRoot}${accessManagementRoot}${userManagementRoot}`,
  userManagementCreate: `${globalSettingsRoot}${accessManagementRoot}${userManagementRoot}${userManagementRoute.create}`,

  // Asset Codes
  assetCodes: `${globalSettingsRoot}${globalSettingsRoute.assetCodes}`,
  assetCodesCreate: `${globalSettingsRoot}${assetCodesRoot}${assetCodesRoute.create}`,

  // Location Codes
  locationCodes: `${globalSettingsRoot}${locationCodesRoot}`,
  locationCodesCreate: `${globalSettingsRoot}${locationCodesRoot}${locationCodesRoute.create}`,

  // Truck Codes
  truckCodes: `${globalSettingsRoot}${truckCodesRoot}`,
  truckCodesCreate: `${globalSettingsRoot}${truckCodesRoot}${truckCodesRoute.create}`,

  // Profile
  profile: `${settingsRoot}${profileRouteRoot}`,
  profileMainInfo: `${settingsRoot}${profileRouteRoot}${profileRoute.mainInfo}`,
  profilePassword: `${settingsRoot}${profileRouteRoot}${profileRoute.password}`,

  // Purchase Orders
  pos: purchaseOrderRoot,
  posCreate: `${purchaseOrderRoot}${purchaseOrdersRoute.create}`,
  po: `${purchaseOrderRoot}${hash.po}`,

  // References
  references: referencesRoot,
  referencesCreateChooseType: `${referencesRoot}${referencesRoute.createChooseType}`,
  reference: `${referencesRoot}${hash.reference}`,

  // SO
  salesOrders: salesOrderRoot,
  salesOrdersCreate: `${salesOrderRoot}${salesOrdersRoute.create}`,
  so: `${salesOrderRoot}${hash.so}`,
}

// TODO: Think how to navigate to the hash routes and also multi navigation inside drawer
// Would be nice to leave routes in "route" constant for same usage

export const hashRoute = {
  reference: (id: number | string) => `${route.reference}/${id}`,
  po: (id: number | string) => `${route.po}/${id}`,
  so: (id: number | string) => `${route.so}/${id}`,
}
