import { FC } from 'react'

import { PurchaseOrder } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { usePurchaseOrders } from './hooks/usePurchaseOrders'
import { getTableColumns } from './utils/getTableColumns'
import { purchaseOrdersFilterOptions } from './constants/purchaseOrdersFilterOptions'
import { purchaseOrdersVisibleColumnsOptions } from './constants/purchaseOrdersVisibleColumns'

export const PurchaseOrdersList: FC = () => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = usePurchaseOrders()
  const allColumns = getTableColumns(getFieldSorter, offsetCount)

  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<PurchaseOrder>({
    module: AppModule.PurchaseOrders,
    allColumns,
  })

  const purchaseOrdersList: PurchaseOrder[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="PO's"
        total={pagination?.total}
        addRoute={route.posCreate}
        search={<TableSearchSingle searchFieldName={['locationCode.code', 'vendorName', 'items.item.sku', 'number']} />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={purchaseOrdersFilterOptions}
            favoriteFieldName="fv.vendor_id"
            module={AppModule.PurchaseOrders}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={purchaseOrdersVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<PurchaseOrder>
        withBorderRadius={false}
        scroll={{ x: 750 }}
        dataSource={purchaseOrdersList}
        loading={isLoading}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </TableContextProvider>
  )
}
