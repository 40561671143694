import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CustomerDetailsPayload } from '../types/customerDetailsPayload'

import { patchCustomer } from '../queryApi'

export const useUpdateCustomer = (id?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(patchCustomer, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.CustomerUpdate)

      queryClient.setQueryData([QueryKey.GetCustomerById, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const update = useCallback(
    (payload: CustomerDetailsPayload) => {
      if (id) {
        mutate({ id, data: payload })
      }
    },
    [mutate, id],
  )

  return {
    update,
    ...rest,
  }
}
