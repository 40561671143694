import { styled } from 'styled-components'

interface WrapperProps {
  $isLinkAndViewOnly?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  min-width: 0;
  display: flex;
  ${({ $isLinkAndViewOnly }) => $isLinkAndViewOnly && 'pointer-events: initial'};
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`
