export enum Accessor {
  Index = 'index',
  Name = 'name',
  Description = 'description',
  Sku = 'sku',
  GroupedSKUs = 'groupedSKUs',
  Category = 'category',
  Price = 'price',
  LocationCode = 'locationCode',
  Type = 'type',
}
