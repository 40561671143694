import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveAsset } from './hooks/useRemoveAsset'

export const ActionButtons: FC = () => {
  const { id } = useParams()
  const { removeAsset, isLoading } = useRemoveAsset(Number(id))

  const handleRemoveAsset = useCallback(() => {
    removeAsset()
  }, [removeAsset])

  return (
    <ConfirmationPopover text="Are you sure you want to remove asset?" onOk={handleRemoveAsset} isLoading={isLoading}>
      <Button icon="delete" danger size="small" />
    </ConfirmationPopover>
  )
}
