import { useQuery } from '@tanstack/react-query'

import { getSalesOrders, GetSalesOrdersQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetSalesOrders = (params: GetSalesOrdersQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetSalesOrders, params], () => getSalesOrders(params), {
    keepPreviousData: true,
  })

  const { total, data: salesOrders } = data || {}

  return {
    data: salesOrders,
    total,
    ...rest,
  }
}
