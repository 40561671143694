import { FC, useEffect } from 'react'

import { useFieldArray, useFormContext } from 'hooks'
import { AsyncSelectGetItemsProps, AsyncSelectQueryParams, typedMemo } from 'types'

import { Button, Container, Spacer } from 'designSystem'

import { FieldArrayItem } from '../FieldArrayItem'
import { FormItemSelectSingleAsync } from '../FormItemSelectSingleAsync'

interface FormItemSelectSingleAsyncGroupProps {
  name: string
  shouldClear?: boolean
  getItems: (props: AsyncSelectGetItemsProps) => any
  placeholder?: string
  maxFieldsCount?: number
  isRequired?: boolean
  queryParams?: AsyncSelectQueryParams
}

export const FormItemSelectSingleAsyncGroupBase: FC<FormItemSelectSingleAsyncGroupProps> = ({
  name,
  getItems,
  shouldClear,
  isRequired,
  maxFieldsCount,
  ...props
}) => {
  const { watch } = useFormContext()
  const { fields, remove, append, replace } = useFieldArray({
    name,
  })

  const values = watch(name) || []
  const isFieldsExist = !!values.length
  const showAddButton =
    !values?.some((v: number) => v === undefined) && (maxFieldsCount === undefined || fields.length < maxFieldsCount)

  const addNewItem = () => append(undefined)

  useEffect(() => {
    if (shouldClear) {
      replace([])
    }
  }, [shouldClear, replace])

  useEffect(() => {
    if (isRequired && values.length === 0) {
      addNewItem()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequired])

  return (
    <>
      {!isFieldsExist && (
        <Button type="link" icon="stack" onClick={addNewItem}>
          Add first item
        </Button>
      )}
      <Container display="flex" fd="column" jc="flex-start" ai="flex-start" gap={8}>
        {isFieldsExist && (
          <>
            {fields.map((field, index) => (
              <FieldArrayItem
                key={field.id}
                onDelete={() => remove(index)}
                showDelete={isRequired ? fields.length > 1 : true}
              >
                <FormItemSelectSingleAsync
                  {...props}
                  id={field.id}
                  name={`${name}.${index}`}
                  disabledValues={values}
                  index={index}
                  getItems={getItems}
                />
              </FieldArrayItem>
            ))}
            {showAddButton && (
              <>
                <Spacer mt={3} />
                <Button type="link" icon="plus" onClick={addNewItem}>
                  Add
                </Button>
              </>
            )}
          </>
        )}
      </Container>
    </>
  )
}

export const FormItemSelectSingleAsyncGroup = typedMemo(FormItemSelectSingleAsyncGroupBase)
