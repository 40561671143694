import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { preparePatchPayload } from '../utils/preparePatchPayload'
import { CarrierPriceListForm } from '../types/carrierPriceListForm'
import { CarrierPriceListPayloadOption } from '../types/carrierPriceListPayload'

import { useDeletePriceList } from './useDeletePriceList'
import { usePatchCarrierPriceList } from './usePatchCarrierPriceList'

export const useCarrierPriceListRequests = (data: CarrierPriceListPayloadOption[]) => {
  const { id: vendorId } = useParams()

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isLoading: isPatchLoading,
  } = usePatchCarrierPriceList(Number(vendorId))

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeletePriceList(Number(vendorId))

  const onSubmitPatch = useCallback(
    (payload: CarrierPriceListForm) => {
      payload.list?.forEach((item) => {
        const { id, ...rest } = preparePatchPayload(item, data)
        patch(id as number, { option: { ...rest } })
      })
    },
    [patch, data],
  )

  const onDelete = useCallback(
    (priceListItemId?: number) => {
      if (priceListItemId) {
        remove(priceListItemId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPatchError || isDeleteError,
    isEditLoading: isPatchLoading || isDeleteLoading,
    onSubmitPatch,
    onDelete,
  }
}
