import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Address, Customer } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postCustomerBillingAddress } from '../queryApi'

export const usePostCustomerBillingAddress = (customerId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(postCustomerBillingAddress, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.CustomerUpdate)
      queryClient.setQueryData([QueryKey.GetCustomerById, customerId], (old: Customer | undefined) =>
        old
          ? {
              ...old,
              billingAddresses: old.billingAddresses?.length ? [...old.billingAddresses, data] : [data],
            }
          : undefined,
      )
      showNotification({ title: 'Billing Address added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: Address) => {
      mutate({ customerId, data: payload })
    },
    [mutate, customerId],
  )

  return {
    post,
    ...rest,
  }
}
