import type { ApiQueryParams, VendorSKU } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetVendorSKUsResponse extends Pagination {
  data?: VendorSKU[]
}

export const getVendorSKUs =
  (vendorId?: number) =>
  (params?: ApiQueryParams): Promise<GetVendorSKUsResponse> => {
    return api({
      method: 'get',
      url: `/api/v1/vendors/${vendorId}/skus`,
      params,
    })
  }
