import { FC, ReactNode, useCallback } from 'react'

import { useFieldArray, useFormContext } from 'hooks'
import { AsyncSelectGetItemsProps, AsyncSelectQueryParams, typedMemo } from 'types'
import { getIsFormFieldViewOnly } from 'utils'

import { Button, Container, SelectSingleOption } from 'designSystem'

import { FieldArrayItem } from '../FieldArrayItem'
import { FormItemEditSelectSingleAsync } from '../FormItemEditSelectSingleAsync'

interface FormItemEditSelectSingleAsyncGroupProps {
  name: string
  label: string
  onSubmit?: () => void
  getItems: (props: AsyncSelectGetItemsProps) => any
  placeholder?: string
  maxFieldsCount?: number
  isRequired?: boolean
  disabledValues?: SelectSingleOption['value'][]
  additionalItemContent?: ReactNode[]
  queryParams?: AsyncSelectQueryParams
}

export const FormItemEditSelectSingleAsyncGroupBase: FC<FormItemEditSelectSingleAsyncGroupProps> = ({
  name,
  onSubmit,
  getItems,
  label,
  maxFieldsCount,
  isRequired,
  disabledValues,
  additionalItemContent,
  queryParams,
  ...props
}) => {
  const {
    watch,
    formState: { errors },
    viewOnlyFields,
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name,
  })

  const currentError = errors[name]

  const isFieldViewOnly = getIsFormFieldViewOnly(viewOnlyFields, name)

  const values = watch(name) as SelectSingleOption[]
  const isFieldsExist = !!values?.length
  const showAddButton =
    !isFieldViewOnly &&
    !values?.some((v) => v?.value === undefined) &&
    (maxFieldsCount === undefined || fields.length < maxFieldsCount)

  const handleSubmit = useCallback(() => {
    const emptyValueIndexes: number[] = []

    values?.forEach((option, index) => {
      const isErrorExist = Array.isArray(currentError) && currentError?.[index]
      if (!option?.value && !isErrorExist) {
        emptyValueIndexes.push(index)
      }
    })
    remove(emptyValueIndexes)
    onSubmit?.()
  }, [onSubmit, values, remove, currentError])

  const handleDelete = useCallback(
    (index: number) => () => {
      remove(index)
      handleSubmit()
    },
    [remove, handleSubmit],
  )

  const selectedValues = values?.map((v) => v?.value) || []
  const allDisabledValues = [...selectedValues, ...(disabledValues || [])]

  const addNewItem = () => append(undefined)

  const isShowDelete = (isRequired ? values?.filter(Boolean).length > 1 : true) && !isFieldViewOnly
  const showAddFirstItem = !isFieldsExist && !isFieldViewOnly

  return (
    <Container display="flex" fd="column" jc="flex-start" ai="flex-start" gap={8}>
      {showAddFirstItem && (
        <Button type="link" icon="stack" onClick={addNewItem}>
          Add first item
        </Button>
      )}
      {isFieldsExist && (
        <>
          {fields.map((field, index) => (
            <FieldArrayItem key={field.id} onDelete={handleDelete(index)} showDelete={isShowDelete}>
              <Container fd="column">
                <FormItemEditSelectSingleAsync
                  {...props}
                  required
                  value={values?.[index]?.value}
                  id={field?.id}
                  name={`${name}.${index}`}
                  label={`${label} ${index + 1}`}
                  disabledValues={allDisabledValues}
                  defaultOption={values?.[index]}
                  index={index}
                  onSubmit={handleSubmit}
                  getItems={getItems}
                  queryParams={queryParams}
                />
                {!!additionalItemContent?.length && additionalItemContent[index]}
              </Container>
            </FieldArrayItem>
          ))}
          {showAddButton && (
            <Button type="link" icon="plus" onClick={addNewItem}>
              Add
            </Button>
          )}
        </>
      )}
    </Container>
  )
}

export const FormItemEditSelectSingleAsyncGroup = typedMemo(FormItemEditSelectSingleAsyncGroupBase)
