import { ReferenceNumberType } from 'api'
import { useDepotCodes, useItems, useLocationCodes, useReferences, useVendors } from 'hooks'
import {
  paymentTermsOptions,
  purchaseOrderBillingStatusOptions,
  purchaseOrderReceivingStatusOptions,
  purchaseOrderStatusOptions,
  purchaseOrderTypeOptions,
} from 'constant'
import { PurchaseOrderFormAccessor } from 'types'

import { TableFilterDateRange, TableFilterSelectMulti, TableFilterSelectMultiAsync } from 'components'

export const purchaseOrdersFilterOptions = [
  {
    value: PurchaseOrderFormAccessor.Status,
    label: 'PO Status',
    component: (
      <TableFilterSelectMulti
        key={PurchaseOrderFormAccessor.Status}
        name={PurchaseOrderFormAccessor.Status}
        options={purchaseOrderStatusOptions}
        placeholder="PO Status"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.Type,
    label: 'Type',
    component: (
      <TableFilterSelectMulti
        key={PurchaseOrderFormAccessor.Type}
        name={PurchaseOrderFormAccessor.Type}
        options={purchaseOrderTypeOptions}
        placeholder="Type"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.ExpectedDate,
    label: 'Expected Date',
    component: (
      <TableFilterDateRange
        key={PurchaseOrderFormAccessor.ExpectedDate}
        name={PurchaseOrderFormAccessor.ExpectedDate}
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.VendorReleaseNumber,
    label: 'Vendor Release Number',
    component: (
      <TableFilterSelectMultiAsync
        key={PurchaseOrderFormAccessor.VendorReleaseNumber}
        name={PurchaseOrderFormAccessor.VendorReleaseRefId}
        width={180}
        getItems={useReferences}
        fields="id,number,type"
        queryParams={{ type: { $eq: ReferenceNumberType.VendorRelease } }}
        placeholder="Vendor Release Number"
      />
    ),
  },
  {
    value: `${PurchaseOrderFormAccessor.LocationCode}.id`,
    label: 'Location',
    component: (
      <TableFilterSelectMultiAsync
        key={`${PurchaseOrderFormAccessor.LocationCode}.id`}
        name={`${PurchaseOrderFormAccessor.LocationCode}.id`}
        getItems={useLocationCodes}
        placeholder="Location"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.DepotId,
    label: 'Depot',
    component: (
      <TableFilterSelectMultiAsync
        key={PurchaseOrderFormAccessor.DepotId}
        name={PurchaseOrderFormAccessor.DepotId}
        getItems={useDepotCodes}
        placeholder="Depot"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.PaymentTerms,
    label: 'Payment Terms',
    component: (
      <TableFilterSelectMulti
        key={PurchaseOrderFormAccessor.PaymentTerms}
        name={PurchaseOrderFormAccessor.PaymentTerms}
        options={paymentTermsOptions}
        placeholder="Payment Terms"
      />
    ),
  },
  {
    value: 'items.sku',
    label: 'SKU',
    component: (
      <TableFilterSelectMultiAsync
        key="items.item.sku"
        name="items.item.sku"
        valuePropName="sku"
        getItems={useItems}
        placeholder="SKU"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.VendorId,
    label: 'Vendor Name',
    component: (
      <TableFilterSelectMultiAsync
        key={PurchaseOrderFormAccessor.VendorId}
        name={PurchaseOrderFormAccessor.VendorId}
        getItems={useVendors}
        placeholder="Vendor Name"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.BillingStatus,
    label: 'Billing Status',
    component: (
      <TableFilterSelectMulti
        key={PurchaseOrderFormAccessor.BillingStatus}
        name={PurchaseOrderFormAccessor.BillingStatus}
        options={purchaseOrderBillingStatusOptions}
        placeholder="Billing Status"
      />
    ),
  },
  {
    value: PurchaseOrderFormAccessor.ReceivingStatus,
    label: 'Receiving Status',
    component: (
      <TableFilterSelectMulti
        key={PurchaseOrderFormAccessor.ReceivingStatus}
        name={PurchaseOrderFormAccessor.ReceivingStatus}
        options={purchaseOrderReceivingStatusOptions}
        placeholder="Receiving Status"
      />
    ),
  },
  {
    value: 'fv.vendor_id',
  },
]
