import { PurchaseOrder } from 'api'

import { Tab } from 'designSystem'

import { PurchaseOrderDetails } from '../elements/PurchaseOrderDetails'
import { PurchaseOrderHistory } from '../elements/PurchaseOrderHistory'
import { PurchaseOrderIntegrations } from '../elements/PurchaseOrderIntegrations'

export const getPurchaseOrderTabs = (data: PurchaseOrder): Tab[] => [
  {
    label: 'PO Details',
    icon: 'gridNoOutline',
    children: <PurchaseOrderDetails data={data} />,
  },
  {
    label: 'Integrations',
    icon: 'linked',
    children: <PurchaseOrderIntegrations zohoId={data.zohoId} />,
  },
  {
    label: 'History',
    icon: 'history',
    children: <PurchaseOrderHistory id={data.id} />,
  },
]
