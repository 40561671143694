import { create } from 'zustand'

import { PurchaseOrder } from 'api'

interface PurchaseOrderDataStore {
  data?: PurchaseOrder
  setData: (data?: PurchaseOrder) => void
  requestId?: string
  setRequestId: (requestId?: string) => void
}

const usePurchaseOrderDataStore = create<PurchaseOrderDataStore>((set) => ({
  data: undefined,
  setData: (data?: PurchaseOrder) => set({ data }),
  requestId: undefined,
  setRequestId: (requestId?: string) => set({ requestId }),
}))

export const usePurchaseOrderData = () => usePurchaseOrderDataStore((state) => state.data)
export const useSetPurchaseOrderData = () => usePurchaseOrderDataStore((state) => state.setData)

export const usePurchaseOrderRequestId = () => usePurchaseOrderDataStore((state) => state.requestId)
export const useSetPurchaseOrderRequestId = () => usePurchaseOrderDataStore((state) => state.setRequestId)
