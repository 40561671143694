import { FC, FocusEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { TextAreaRef } from 'antd/lib/input/TextArea'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'
import { getObjectValueByKey } from 'utils'

import { ConfirmationButtons, EditTextArea, EditTextAreaProps, EditView } from 'designSystem'

import * as Styled from './styles'

export interface FormItemEditTextAreaProps extends Omit<EditTextAreaProps, 'name' | 'error'> {
  name: string
  onConfirm?: (value?: string) => void
  isFieldViewOnly?: boolean
}

export const FormItemEditTextAreaBase: FC<FormItemEditTextAreaProps> = forwardRef(
  ({ disabled = false, size, onChange, onConfirm, name, onBlur, value, isFieldViewOnly, ...props }, ref) => {
    const [isEdit, setIsEdit] = useState(false)
    const inputRef = useRef<TextAreaRef>(null)

    useImperativeHandle(ref, () => inputRef.current as HTMLTextAreaElement)

    const {
      getFieldState,
      formState: { defaultValues },
    } = useFormContext()

    const { error } = getFieldState(name)
    const defaultValue = getObjectValueByKey(name, defaultValues)

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const onClickConfirm = useCallback(() => {
      if (error) {
        return
      }

      onChange?.(value?.trim())
      onConfirm?.(value)
      setIsEdit(false)
    }, [error, onConfirm, value, onChange])

    const onClickCancel = useCallback(() => {
      onChange?.(defaultValue)
      setIsEdit(false)
    }, [setIsEdit, onChange, defaultValue])

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        onBlur?.(event)
        setIsEdit(false)
        onChange?.(defaultValue)
      },
      [onBlur, onChange, defaultValue],
    )

    useEffect(() => {
      if (isEdit) {
        inputRef?.current?.focus({
          cursor: 'end',
        })
      }
    }, [isEdit])

    return (
      <>
        {isEdit ? (
          <Styled.Wrapper>
            <EditTextArea
              {...props}
              name={name}
              onBlur={handleBlur}
              autoSize={{ minRows: 1 }}
              value={value}
              status={error && 'error'}
              onChange={onChange}
              ref={inputRef}
              disabled={disabled}
              size={size}
            />
            <ConfirmationButtons
              size={size}
              disabled={!!error || defaultValue === value?.trim()}
              onConfirm={onClickConfirm}
              onCancel={onClickCancel}
            />
          </Styled.Wrapper>
        ) : (
          <EditView
            layout="vertical"
            size={size}
            disabled={isFieldViewOnly ? false : disabled}
            value={value}
            onClick={handleViewClick}
          />
        )}
      </>
    )
  },
)

export const FormItemEditTextArea = typedMemo(FormItemEditTextAreaBase)
