import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'antd'

import { useForm, useItems } from 'hooks'
import {
  assetCodeAdditionalAttributesOptions,
  assetCodeGradeOptions,
  assetCodeHeightOptions,
  assetCodeLengthOptions,
  assetCodeTypeOptions,
  assetCodeWidthOptions,
} from 'constant'

import { Checkbox, Container, Divider, EditFormItem, FormItem, Spacer, Typography } from 'designSystem'
import {
  Breadcrumbs,
  Card,
  ConfirmationRequiredPopover,
  FormItemEditInput,
  FormItemEditSelectMulti,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsyncGroup,
  Spinner,
  StripLoader,
} from 'components'

import { useGetAssetCodeById } from './hooks/useGetAssetCodeById'
import { useUpdateAssetCode } from './hooks/useUpdateAssetCode'
import { validationSchema } from '../../utils/validationSchema'
import { getBreadcrumbsData } from './utils/getBreadcrumbsData'
import { AssetCodeForm } from '../../types/assetCodeForm'
import { AssetCodePayload } from '../../types/assetCodePayload'
import { ActionButtons } from './elements/ActionButtons'

export const AssetCodeView: FC = () => {
  const { id } = useParams()
  const { data, isFetching } = useGetAssetCodeById(Number(id))
  const { update, isError, isLoading } = useUpdateAssetCode(Number(id))

  const onSubmit = useCallback(
    (payload: AssetCodePayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<AssetCodeForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: data
      ? {
          ...data,
          items: data?.items?.map(({ sku, id }) => {
            return { value: id, label: sku }
          }),
        }
      : {},
    viewOnlyFields: ['items'],
  })

  if (isFetching) {
    return <Spinner />
  }

  return (
    <>
      {!!data && (
        <>
          <Breadcrumbs breadcrumbItems={getBreadcrumbsData({ title: data.code })} actions={<ActionButtons />} />
          <Container pa={4} fd="column" gap={12}>
            <Card maxWidth={390}>
              <StripLoader isLoading={isLoading} />
              <Form>
                <EditFormItem
                  name="code"
                  render={({ field }) => (
                    <FormItemEditInput
                      size="large"
                      align="left"
                      {...field}
                      onConfirm={triggerSubmit}
                      popover={<ConfirmationRequiredPopover withPopover />}
                    />
                  )}
                />
                <Divider mt={3} mb={4} />
                <Container fd="column" gap={4}>
                  <EditFormItem
                    name="type"
                    label="Type"
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        {...field}
                        width={120}
                        options={assetCodeTypeOptions}
                        onSubmit={triggerSubmit}
                      />
                    )}
                  />
                  <EditFormItem
                    name="length"
                    label="Length"
                    render={({ field }) => (
                      <FormItemEditSelectSingle {...field} options={assetCodeLengthOptions} onSubmit={triggerSubmit} />
                    )}
                  />

                  <EditFormItem
                    name="width"
                    label="Width"
                    render={({ field }) => (
                      <FormItemEditSelectSingle {...field} options={assetCodeWidthOptions} onSubmit={triggerSubmit} />
                    )}
                  />
                  <EditFormItem
                    name="height"
                    label="Height"
                    render={({ field }) => (
                      <FormItemEditSelectSingle {...field} options={assetCodeHeightOptions} onSubmit={triggerSubmit} />
                    )}
                  />
                  <EditFormItem
                    name="grade"
                    label="Grade"
                    render={({ field }) => (
                      <FormItemEditSelectSingle {...field} options={assetCodeGradeOptions} onSubmit={triggerSubmit} />
                    )}
                  />
                  <EditFormItem
                    name="additionalAttributes"
                    label="Additional Attributes"
                    render={({ field }) => (
                      <FormItemEditSelectMulti
                        {...field}
                        width={150}
                        options={assetCodeAdditionalAttributesOptions}
                        onBlur={triggerSubmit}
                      />
                    )}
                  />
                </Container>
                <Divider my={5} />
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <FormItem name="showInCalculator">
                      <Checkbox onChange={triggerSubmit}>
                        <Typography size="s">Display in Calculator</Typography>
                      </Checkbox>
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name="showInGlobalInventory">
                      <Checkbox onChange={triggerSubmit}>
                        <Typography size="s">Display in Global Inventory</Typography>
                      </Checkbox>
                    </FormItem>
                  </Col>
                </Row>
                {!!data?.items?.length && (
                  <>
                    <Divider my={5} />
                    <Typography fontWeight="xl" size="m">
                      Related SKU
                    </Typography>
                    <Spacer mt={3} />

                    <FormItemEditSelectSingleAsyncGroup
                      name="items"
                      label="Item SKU"
                      onSubmit={triggerSubmit}
                      getItems={useItems}
                    />
                  </>
                )}
              </Form>
            </Card>
          </Container>
        </>
      )}
    </>
  )
}
