import { FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import type { DrawerProps as DrawerBaseProps } from 'antd/lib/drawer'

import { DeepObjectKeys, typedMemo } from 'types'
import theme from 'styles/theme'

import { Spinner } from 'components/Spinner'

import * as Styled from './styles'

import { Container } from '../Container'
import { Icon } from '../Icon'
import { SpacerLevel } from '../Spacer'
import { Typography } from '../Typography'

export interface DrawerProps extends Omit<DrawerBaseProps, 'width'> {
  width?: number
  hashUrl?: string
  isLoading?: boolean
  bg?: DeepObjectKeys<typeof theme.colors>
  pa?: SpacerLevel
}

export const DrawerBase: FC<DrawerProps> = ({
  open: openControlled,
  title,
  hashUrl,
  onClose,
  width = 400,
  isLoading,
  children,
  bg = 'white',
  pa = 4,
  ...props
}) => {
  const navigate = useNavigate()
  const { hash, pathname } = useLocation()
  const [open, setOpen] = useState(openControlled || false)

  useEffect(() => {
    if (openControlled !== open) {
      setOpen(openControlled || false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openControlled])

  useEffect(() => {
    if (hashUrl) {
      setOpen(hashUrl === hash)
    }
  }, [hashUrl, hash])

  const handleClose = useCallback(
    (event: MouseEvent | KeyboardEvent) => {
      setOpen(false)

      // Need delay to close Drawer with transition first and then change url(unmount via hash router)
      setTimeout(() => {
        if (hashUrl) {
          navigate(pathname)
        }

        onClose?.(event)
      }, 300)
    },
    [navigate, onClose, hashUrl, pathname],
  )

  return (
    <Styled.Drawer
      {...props}
      open={open}
      onClose={handleClose}
      width={width}
      closeIcon={<Icon icon="cross" />}
      title={
        typeof title === 'string' ? (
          <Typography fontWeight="xl" size="l" lineEllipsis={1} nowrap>
            {title}
          </Typography>
        ) : (
          title
        )
      }
    >
      <Container
        position="unset"
        bg={isLoading ? undefined : bg}
        height={isLoading ? '100%' : undefined}
        pa={pa}
        fd="column"
      >
        {isLoading ? <Spinner /> : children}
      </Container>
    </Styled.Drawer>
  )
}

export const Drawer = typedMemo(DrawerBase)
