import * as Yup from 'yup'

import { ItemType } from 'api'
import { numberBasicValidation, skuValidation } from 'utils'

import { SelectSingleOption } from 'designSystem/components'

export const validationSchema = Yup.object({
  name: Yup.string().max(100, 'Max length of 100 characters').required('Name is required'),
  description: Yup.string().max(250, 'Max length of 250 characters').required('Description is required'),
  sku: skuValidation.test('skuDuplicates', 'This SKU is already selected in the Grouped SKU', (value, context) => {
    const groupedSKUs = context?.parent?.groupedSKUs || []
    const result = groupedSKUs.some((item: SelectSingleOption) => item?.label === value)
    return !result
  }),
  type: Yup.mixed<ItemType>().oneOf(Object.values(ItemType)).required('Type is required'),
  locationCodeId: Yup.number().nullable().default(null),
  categoryId: Yup.number().required('Category is required'),
  price: numberBasicValidation
    .required('Price is required')
    .positive('Should be positive number')
    .max(1000000, 'Should be less than 1,000,000'),
  groupedSKUs: Yup.array().default([]),
})
