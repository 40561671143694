import { api, EntityId, VendorContainerSettings, VendorFeedSettings, VendorPartsSettings } from 'api'

import { FeedPayload } from './types/FeedPayload'

export const patchModificationFeed = ({
  id,
  data,
}: {
  id: EntityId
  data: FeedPayload
}): Promise<VendorFeedSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${id}/types/modification`,
    data,
  })
}

export const patchContainerVendorFeed = ({
  id,
  data,
}: {
  id: EntityId
  data: FeedPayload
}): Promise<VendorFeedSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${id}/types/container-supplier`,
    data,
  })
}

export const getContainerVendorByVendorId = (id?: EntityId): Promise<VendorContainerSettings> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${id}/types/container-supplier`,
  })
}

export const getPartsByVendorId = (id?: EntityId): Promise<VendorPartsSettings> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${id}/types/parts`,
  })
}

export const patchPartsFeed = ({ id, data }: { id: EntityId; data: FeedPayload }): Promise<VendorFeedSettings> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${id}/types/parts`,
    data,
  })
}
