import { FC } from 'react'

import { AssetStatus, ReferenceNumberItem } from 'api'
import { useGetAssets, useMemoCompare, useWatch } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { Container, Typography } from 'designSystem'
import { RelatedLinksList, Spinner } from 'components'

import { useGetReferenceItems } from '../ReferenceItemsEdit/hooks/useGetReferenceItems'

interface AvailableAssetsProps {
  referenceId?: number
}

export const AvailableAssets: FC<AvailableAssetsProps> = ({ referenceId }) => {
  const { data: itemsData, isLoading: isItemsLoading } = useGetReferenceItems(referenceId)

  const depotId = useWatch({ name: ReferenceFormAccessor.DepotSettingId })
  const vendorReleaseId = useWatch({ name: ReferenceFormAccessor.VendorReleaseId })
  const items = useWatch({ name: ReferenceFormAccessor.Items }) as ReferenceNumberItem[]

  const itemsIds = useMemoCompare(
    (itemsData?.length ? itemsData : items)?.reduce((acc, { itemId }) => {
      if (!!itemId && !acc.includes(itemId)) {
        acc.push(itemId)
      }

      return acc
    }, [] as number[]),
  )

  const textFontSize = referenceId ? 's' : 'xs'

  const { isLoading, data } = useGetAssets(!!depotId && !isItemsLoading, {
    join: ['items||id'],
    sort: [],
    fields: 'id,assetNumber,items',
    s: JSON.stringify({
      status: { $in: [AssetStatus.Available, AssetStatus.VendorAccount] },
      ...(depotId && { vendorDepotId: { $eq: depotId } }),
      ...(vendorReleaseId && { vendorReleaseRefId: { $eq: vendorReleaseId } }),
      ...(itemsIds?.length && { 'items.id': { $in: itemsIds } }),
    }),
  })

  const linksData = data?.map((asset) => ({ id: asset.id, title: asset.assetNumber }))
  const isData = data !== undefined
  const isAllLoading = isItemsLoading || isLoading

  return (
    <Container ai="center" jc="space-between" gap={8}>
      <Typography size={textFontSize} color={referenceId ? 'secondary' : undefined} fontWeight="l">
        {referenceLabels.availableAssets}
      </Typography>
      <Container gap={4} fg={1} jc="flex-end" nowrap>
        {isAllLoading && (
          <Container ai="center" gap={4}>
            <Spinner size="small" autoWidth />
            <Typography size={textFontSize} color="placeholder">
              Searching...
            </Typography>
          </Container>
        )}

        {!isAllLoading && !isData && (
          <Typography size={textFontSize} color="placeholder">
            Select Depot first
          </Typography>
        )}

        {!isAllLoading &&
          isData &&
          (linksData?.length ? (
            <RelatedLinksList entity="assets" data={linksData} linkProps={{ fontSize: textFontSize }} />
          ) : (
            <Typography size={textFontSize} fontWeight="l">
              Not found
            </Typography>
          ))}
      </Container>
    </Container>
  )
}
