import { VendorDataMapping } from 'api'
import { useEditableTable, useTable } from 'hooks'

import { useGetDataMapping } from './useGetDataMapping'

export const useDataMapping = (vendorId: number) => {
  const { pagination, onChange, searchQueryParams, ...props } = useTable<Partial<VendorDataMapping>>({})

  const {
    data = [],
    total,
    isLoading,
    isFetching,
  } = useGetDataMapping({
    vendorId,
    limit: pagination.limit,
    page: pagination.page,
    s: searchQueryParams,
  })

  const editableTable = useEditableTable<VendorDataMapping>({
    data,
  })

  return {
    ...editableTable,
    data,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
