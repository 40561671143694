import { VendorDataMapping } from 'api'

import { DataMappingFormPayload } from '../types/dataMappingForm'

export const getPatchPayload = (payload: DataMappingFormPayload, defaultData: VendorDataMapping[]) => {
  return payload.list?.map((equipment) => {
    const { id, vendorSKU, itemSKU } = equipment
    const prev = defaultData.find((item) => item.id === id)

    const payload = {
      ...(prev?.vendorSKU !== vendorSKU && { vendorSKU }),
      ...(prev?.itemSKU !== itemSKU && { itemSKU }),
    }

    return {
      id,
      payload,
    }
  })
}
