import { ActivityStreamline, ActivityStreamlineType } from 'api'
import { purchaseOrderItemLabels, purchaseOrderItemSubLineLabels, purchaseOrderLabels } from 'constant'

export const getEntityData = (type: ActivityStreamlineType, data: ActivityStreamline['data']) => {
  return {
    [ActivityStreamlineType.PurchaseOrderEntity]: {
      title: 'Purchase Order',
      labels: purchaseOrderLabels,
    },
    [ActivityStreamlineType.PurchaseOrderItemEntity]: {
      subTitle: JSON.stringify(data.input?.title),
      labels: purchaseOrderItemLabels,
    },
    [ActivityStreamlineType.PurchaseOrderSubLineEntity]: {
      subTitle: JSON.stringify(data.input?.asset?.assetNumber),
      labels: purchaseOrderItemSubLineLabels,
    },
    [ActivityStreamlineType.PurchaseOrderContactEntity]: {
      labels: purchaseOrderItemLabels,
    },
  }[type]
}
