import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorType } from 'api'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, Spinner } from 'components'
import { useGetContainerVendorByVendorId } from 'modules/vendors/hooks/useGetContainerVendorByVendorId'
import { useUpdateContainerVendorFeed } from 'modules/vendors/hooks/useUpdateContainerVendorFeed'

import { InfoCard } from 'modules/vendors/routes/Vendor/elements/InfoCard'
import { ResalePriceOverwriteRules } from 'modules/vendors/routes/Vendor/elements/ResalePriceOverwriteRules'

export const ContainerVendorDetails: FC = () => {
  const { id } = useParams()

  const { data, isLoading: isLoadingContainerVendor } = useGetContainerVendorByVendorId({ id: Number(id) })
  const { update, isLoading, isError } = useUpdateContainerVendorFeed({ id: Number(id) })

  if (isLoadingContainerVendor) {
    return <Spinner />
  }

  if (!data) {
    return null
  }
  return (
    <Container pa={4} fd="column">
      <Typography fontWeight="xl" size="l">
        Container Vendor Info
      </Typography>
      <Spacer mb={4} />
      <Card maxWidth={390}>
        <InfoCard data={data} isLoading={isLoading} isError={isError} onSubmit={update} />
      </Card>
      <Spacer mb={7} />
      <ResalePriceOverwriteRules type={VendorType.ContainerVendor} />
    </Container>
  )
}
