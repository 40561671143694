import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteDataMapping } from '../queryApi'

export const useDeleteDataMapping = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(deleteDataMapping, {
    onSuccess: () => {
      dispatchAppEvent(AppEvent.DataMappingDelete)

      showNotification({ title: 'Data mapping deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (id: number) => {
      mutate({ vendorId, id })
    },
    [mutate, vendorId],
  )

  return {
    remove,
    ...rest,
  }
}
