import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'

import { ReferenceNumberBooking, ReferenceNumberBookingType } from 'api'
import { useFormContext, useWatch } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor } from 'types'

import { DatePicker, FormItem, Input, SelectSingleOption, TextArea } from 'designSystem'

import { AvailableAssets } from 'modules/references/elements/AvailableAssets'
import { VendorRelease } from 'modules/references/elements/VendorRelease'

interface BookingNumberSecondaryDetailsProps {
  defaultData?: ReferenceNumberBooking
  setVendorReleaseItems?: (items: Record<string, number>) => void
  setDepotSettingSelectedOption: (option?: SelectSingleOption) => void
}

export const BookingNumberSecondaryDetails: FC<BookingNumberSecondaryDetailsProps> = ({
  defaultData,
  setVendorReleaseItems,
  setDepotSettingSelectedOption,
}) => {
  const { unregister } = useFormContext()

  const bookingType = useWatch({ name: ReferenceFormAccessor.BookingType })
  const isVendorReleaseBookingType = bookingType === ReferenceNumberBookingType.VendorRelease

  useEffect(() => {
    if (isVendorReleaseBookingType) {
      unregister([ReferenceFormAccessor.AssetPrefix, ReferenceFormAccessor.DepotNotes])
    } else {
      unregister([ReferenceFormAccessor.VendorReleaseId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVendorReleaseBookingType])

  return (
    <>
      {isVendorReleaseBookingType && (
        <Row>
          <Col span={24}>
            <VendorRelease
              defaultValue={defaultData?.vendorRelease}
              setVendorReleaseItems={setVendorReleaseItems}
              setDepotSettingSelectedOption={setDepotSettingSelectedOption}
            />
          </Col>
        </Row>
      )}
      {!isVendorReleaseBookingType && (
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.AssetPrefix} label={referenceLabels.assetPrefix}>
              <Input placeholder="Type asset prefix" />
            </FormItem>
          </Col>
        </Row>
      )}
      <AvailableAssets />
      {!isVendorReleaseBookingType && (
        <Row>
          <Col span={24}>
            <FormItem name={ReferenceFormAccessor.DepotNotes} label={referenceLabels.depotNotes}>
              <TextArea size="small" placeholder="Type notes" autoSize={{ minRows: 4 }} />
            </FormItem>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <FormItem name={ReferenceFormAccessor.ExpirationDate} label={referenceLabels.expirationDate}>
            <DatePicker />
          </FormItem>
        </Col>
      </Row>
    </>
  )
}
