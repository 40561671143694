import { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AssetHolder, AssetStatus } from 'api'
import { useForm, useItems, useVendors } from 'hooks'
import {
  assetConditionOptions,
  assetHolderOptions,
  assetLabels,
  assetTransactionTypeOptions,
  containerColorOptions,
  currency,
  hash,
  route,
} from 'constant'
import { getAvailableAssetStatusOptions } from 'utils'
import theme from 'styles/theme'

import { Checkbox, Container, Divider, EditFormItem, FormItem, Icon, Spacer, Typography } from 'designSystem'
import {
  AssetStatusTag,
  BilledItemModal,
  Card,
  ConfirmationRequiredPopover,
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditSelectSingleAsyncGroup,
  FormItemsEditLocationDepot,
  LabelValueMarkup,
  PopoverForBillNumber,
  Spinner,
  StripLoader,
} from 'components'

import { useGetAssetById } from './hooks/useGetAssetById'
import { useUpdateAsset } from './hooks/useUpdateAsset'
import { validationSchema } from '../../../../utils/validationSchema'
import { maxItemsSKU } from '../../../../constants/maxRecordsCount'
import { AssetDetailsFormData, AssetForm } from './types/assetForm'
import { AssetNumberTypeView } from '../../../../elements/AssetNumberTypeView'
import { LoanFieldsEdit } from './elements/LoanFieldsEdit'

export const AssetDetails: FC = () => {
  const { id } = useParams()
  const { data: assetData, isLoading: isGetAssetLoading } = useGetAssetById(Number(id))
  const { data: assetUpdatedData, updateAsset, isError, isLoading } = useUpdateAsset(Number(id))
  const currentAssetStatus = assetUpdatedData?.status || assetData?.status
  const availableAssetStatusOptions = getAvailableAssetStatusOptions(currentAssetStatus)
  const [openModal, setOpenModal] = useState(false)

  const onSubmit = useCallback(
    (data: AssetDetailsFormData) => {
      updateAsset(data)
    },
    [updateAsset],
  )

  const { items, vendorReleaseReferenceNumber, ...rest } = assetData || {}

  const { Form, triggerSubmit, setValue } = useForm<AssetForm>({
    validationSchema,
    context: {
      assetView: true,
      prevAssetNumberType: assetData?.assetNumberType,
      prevAssetNumber: assetData?.assetNumber,
    },
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    viewOnlyFields: ['purchaseOrderSubLine.poItem.purchaseOrder.id'],
    defaultValues: assetData
      ? {
          ...rest,
          itemsIds: items?.map(({ id, sku }) => ({ value: id, label: sku })),
        }
      : {},
  })

  const handleSubmitBilled = useCallback(
    (data: string) => {
      setValue('isBilled', true)
      setValue('billingReference', data)
      triggerSubmit()
    },
    [setValue, triggerSubmit],
  )

  const onCancel = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleChangeIsBilled = useCallback(() => {
    setOpenModal(true)
  }, [])

  if (isGetAssetLoading) {
    return <Spinner />
  }

  const { purchaseOrder } = assetData?.purchaseOrderSubLine?.poItem || {}

  return (
    <>
      {assetData && (
        <>
          <BilledItemModal open={openModal} onSubmit={handleSubmitBilled} onCancel={onCancel} />
          <Container pa={4}>
            <Card maxWidth={390}>
              <StripLoader isLoading={isLoading} />
              <Form>
                <EditFormItem
                  name="assetNumber"
                  render={({ field }) => (
                    <FormItemEditInput
                      size="large"
                      align="left"
                      {...field}
                      uppercase
                      onConfirm={triggerSubmit}
                      popover={<ConfirmationRequiredPopover withPopover />}
                    />
                  )}
                />
                <AssetNumberTypeView />
                <Spacer pb={4} />
                <Container fd="column" gap={4}>
                  <EditFormItem
                    name="status"
                    label={assetLabels.status}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        disabled={currentAssetStatus === AssetStatus.PendingDelete}
                        customPreview={
                          <AssetStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
                        }
                        width={160}
                        placeholder="Type Asset Status"
                        {...field}
                        onSubmit={triggerSubmit}
                        options={availableAssetStatusOptions}
                      />
                    )}
                  />
                  <EditFormItem
                    name="assetHolder"
                    label={assetLabels.assetHolder}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        width={140}
                        placeholder="Type Asset Holder"
                        {...field}
                        onSubmit={triggerSubmit}
                        options={assetHolderOptions}
                      />
                    )}
                  />
                  {assetData?.assetHolder === AssetHolder.VendorAccount && (
                    <FormItemEditSelectSingleAsync
                      name="vendorId"
                      label={assetLabels.vendorId}
                      onSubmit={triggerSubmit}
                      getItems={useVendors}
                      defaultOption={{
                        value: assetData?.vendor?.id as number,
                        label: assetData?.vendor?.companyName as string,
                      }}
                      href={route.vendors}
                    />
                  )}
                  <EditFormItem
                    name="birthmarkNumber"
                    label={assetLabels.birthmarkNumber}
                    render={({ field }) => (
                      <FormItemEditInput placeholder="Type Birthmark Number" {...field} onBlur={triggerSubmit} />
                    )}
                  />
                  <EditFormItem
                    name="birthmarkSize"
                    label={assetLabels.birthmarkSize}
                    render={({ field }) => (
                      <FormItemEditInput placeholder="Type Birthmark Size" {...field} onBlur={triggerSubmit} />
                    )}
                  />
                </Container>
                <Divider my={4} />
                <Typography fontWeight="xl">{assetLabels.items}</Typography>
                <Spacer mb={3} />
                <FormItemEditSelectSingleAsyncGroup
                  name="itemsIds"
                  label="Item"
                  onSubmit={triggerSubmit}
                  getItems={useItems}
                  maxFieldsCount={maxItemsSKU}
                  isRequired
                  queryParams={{ type: { $eq: 'assets' } }}
                  additionalItemContent={assetData?.items?.map(
                    ({ locationCode }) =>
                      locationCode && (
                        <Container key={locationCode.code} jc="flex-end" ai="center" gap={4}>
                          <Icon icon="pin" size={16} color={theme.colors.secondary} />
                          <Typography size="xs" color="secondary" fontWeight="l">
                            {locationCode.code}
                          </Typography>
                        </Container>
                      ),
                  )}
                />
                <Divider my={4} />
                <Container fd="column" gap={4}>
                  <EditFormItem
                    name="cost"
                    label={assetLabels.cost}
                    render={({ field }) => (
                      <FormItemEditInputNumber
                        placeholder="Cost"
                        {...field}
                        onBlur={triggerSubmit}
                        precision={2}
                        suffix={currency}
                      />
                    )}
                  />
                  <LabelValueMarkup
                    showTooltip={false}
                    typographyPropsValue={purchaseOrder ? undefined : { color: 'secondary' }}
                    label={assetLabels.purchaseOrderSubLine}
                    value={purchaseOrder ? purchaseOrder.number : 'Empty'}
                    href={purchaseOrder ? `${hash.po}/${purchaseOrder.id}` : undefined}
                    typographyPropsLabel={{ nowrap: true }}
                    hrefTarget="_self"
                  />
                  <LabelValueMarkup
                    showTooltip={false}
                    typographyPropsValue={vendorReleaseReferenceNumber ? undefined : { color: 'secondary' }}
                    label={assetLabels.vendorReleaseReferenceNumber}
                    value={vendorReleaseReferenceNumber ? vendorReleaseReferenceNumber.number : 'Empty'}
                    href={
                      vendorReleaseReferenceNumber ? `${hash.reference}/${vendorReleaseReferenceNumber.id}` : undefined
                    }
                    typographyPropsLabel={{ nowrap: true }}
                    hrefTarget="_self"
                  />
                  <Container fd="row" gap={24}>
                    <PopoverForBillNumber
                      placement="topLeft"
                      show={!!assetData.isBilled}
                      popoverName="billingReference"
                    >
                      <FormItem name="isBilled" disableOnChange>
                        <Checkbox onChange={handleChangeIsBilled} disabled={!!assetData.isBilled}>
                          <Typography size="s">{assetLabels.isBilled}</Typography>
                        </Checkbox>
                      </FormItem>
                    </PopoverForBillNumber>
                    <FormItem name="isReceived">
                      <Checkbox onChange={triggerSubmit}>
                        <Typography size="s">{assetLabels.isReceived}</Typography>
                      </Checkbox>
                    </FormItem>
                  </Container>
                  <EditFormItem
                    name="transactionType"
                    label={assetLabels.transactionType}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        placeholder="Type Transaction Type"
                        {...field}
                        onSubmit={triggerSubmit}
                        options={assetTransactionTypeOptions}
                      />
                    )}
                  />
                </Container>
                <Divider my={4} />
                <LoanFieldsEdit />
                <Divider my={4} />
                <FormItemsEditLocationDepot
                  locationFieldName="locationCodeId"
                  depotFieldName="vendorDepotId"
                  locationCode={assetData?.locationCode}
                  depotCode={assetData?.vendorDepot}
                />
                <Divider my={4} />
                <Container fd="column" gap={4}>
                  <EditFormItem
                    name="condition"
                    label={assetLabels.condition}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        width={150}
                        placement="topLeft"
                        options={assetConditionOptions}
                        placeholder="Type Condition"
                        {...field}
                        onSubmit={triggerSubmit}
                      />
                    )}
                  />
                  <EditFormItem
                    name="containerColor"
                    label={assetLabels.containerColor}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        {...field}
                        width={200}
                        placeholder="Container Color"
                        onSubmit={triggerSubmit}
                        options={containerColorOptions}
                      />
                    )}
                  />
                  <EditFormItem
                    name="ageOfUnit"
                    label={assetLabels.ageOfUnit}
                    render={({ field }) => (
                      <FormItemEditDatePicker {...field} placeholder="Type Age Of Unit" onBlur={triggerSubmit} />
                    )}
                  />
                </Container>
              </Form>
            </Card>
          </Container>
        </>
      )}
    </>
  )
}
