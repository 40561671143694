import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorResalePriceOverwriteRule, VendorType } from 'api'
import { useForm } from 'hooks'

import { Button, Container } from 'designSystem'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { useResaleRule } from './hooks/useResaleRule'
import { useResaleRuleRequests } from './hooks/useResaleRuleRequests'
import { getTableColumns } from './utils/getTableColumns'
import { validationSchema } from './constants/validationSchema'

export interface ResalePriceOverwriteRulesProps {
  type: VendorType.ContainerVendor | VendorType.Parts
}

export const ResalePriceOverwriteRules: FC<ResalePriceOverwriteRulesProps> = ({ type }) => {
  const { id } = useParams()

  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    offsetCount,
    TableContextProvider,
    // editable table
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = useResaleRule(Number(id), type)

  const { onSubmitPost, onSubmitPatch, onDelete, isSubmitError, isEditLoading } = useResaleRuleRequests(
    setIsAddNew,
    data,
    type,
  )

  const { Form, handleSubmit, triggerSubmit } = useForm<any>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data,
    },
  })

  const columns = getTableColumns({ offsetCount, isAddNew, onCancelNew, onDelete, triggerSubmit })

  return (
    <Container fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle withoutDefaultPadding title="Resale Price Overwrite Rule" total={pagination.total}>
          <TableSearchSingle searchFieldName="assetCode.code" />
          <Button icon="plus" type="primary" size="small" onClick={onAddNew} disabled={isAddNew}>
            Add
          </Button>
        </TableTitle>
        <Form onSubmit={handleSubmit(onSubmitPost)}>
          <Table<Partial<VendorResalePriceOverwriteRule>>
            scroll={{ x: isAddNew ? 950 : 600 }}
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
