import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { route } from 'constant'

import { Icon } from 'designSystem'

import { headerNavigationItems } from './constants'
import { HeaderAvatar } from './elements'
import * as Styled from './styles'

export const Header: FC = () => {
  const location = useLocation()
  const firstPath = `/${location.pathname.split('/')[1]}`
  const menuItemsKeys = headerNavigationItems.map((item) => item?.key)
  const menuItemLocationExist = menuItemsKeys.includes(firstPath)
  const defaultMenuItem = menuItemLocationExist ? firstPath : null
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(defaultMenuItem)

  const handleClickMenuItem = useCallback(({ key }: { key: string }) => {
    setActiveMenuItem(key)
  }, [])

  useEffect(() => {
    if (menuItemLocationExist) {
      setActiveMenuItem(firstPath)
    } else {
      setActiveMenuItem(null)
    }
  }, [menuItemLocationExist, firstPath])

  return (
    <Styled.Header>
      <Styled.Link to={route.home}>
        <Icon icon="logo" />
      </Styled.Link>
      <Styled.Navigation
        mode="horizontal"
        overflowedIndicator={<Icon icon="dots" size={24} />}
        selectedKeys={activeMenuItem ? [activeMenuItem] : []}
        items={headerNavigationItems}
        onClick={handleClickMenuItem}
      />
      <HeaderAvatar />
    </Styled.Header>
  )
}
