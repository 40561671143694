import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { VendorType } from 'api'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { UploadLocaleFilePayload } from '../types/useForm'
import {
  postFileToInventory,
  postFileToModifications,
  postFileToPartsInventory,
} from '../../../routes/VendorsList/elements/UploadSheet/queryApi'

interface UseUploadFileProps {
  vendorId: number
  onSuccess?: () => void
}

export const useUploadFile = ({ onSuccess, vendorId }: UseUploadFileProps) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate: mutateModifications, isLoading: isModificationsLoading } = useMutation(postFileToModifications, {
    onSuccess: () => {
      onSuccess?.()
      dispatchAppEvent(AppEvent.VendorUploadModifications)
    },
  })
  const { mutate: mutateInventory, isLoading: isInventoryLoading } = useMutation(postFileToInventory, {
    onSuccess: () => {
      onSuccess?.()
      dispatchAppEvent(AppEvent.VendorContainerInventoryUpload)
    },
  })

  const { mutate: mutatePartsInventory, isLoading: isInventoryPartsLoading } = useMutation(postFileToPartsInventory, {
    onSuccess: () => {
      onSuccess?.()
      dispatchAppEvent(AppEvent.VendorPartsInventoryUpload)
    },
  })

  const uploadFile = useCallback(
    (payload: UploadLocaleFilePayload) => {
      const uploadRequests = {
        [VendorType.ContainerVendor]: mutateInventory,
        [VendorType.Parts]: mutatePartsInventory,
        [VendorType.Carrier]: null,
        [VendorType.Depot]: null,
        [VendorType.Modifications]: mutateModifications,
      }
      const { vendorType, files } = payload
      const formData = new FormData()
      formData.append('file', files?.[0].originFileObj as Blob)
      const payloadData = { vendorId, formData }
      const request = uploadRequests[vendorType]
      request?.(payloadData)
    },
    [mutateInventory, mutateModifications, mutatePartsInventory, vendorId],
  )

  return {
    uploadFile,
    isLoading: isModificationsLoading || isInventoryLoading || isInventoryPartsLoading,
  }
}
