import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const requiredColumns = [Accessor.Index, Accessor.Name]

export const itemsDefaultColumns = [
  Accessor.Index,
  Accessor.Name,
  Accessor.Description,
  Accessor.Sku,
  Accessor.GroupedSKUs,
  Accessor.Category,
  Accessor.Price,
]

export const itemsVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: requiredColumns.includes(accessor),
}))
