import { Link } from 'react-router-dom'

import { assetsRoute, route } from 'constant'

export const getAssetsSidebarItems = (id: string) => {
  const assetRoute = `${route.assets}/${id}`

  return [
    {
      label: <Link to={`${assetRoute}${assetsRoute.details}`}>Asset Details</Link>,
      key: `${assetRoute}${assetsRoute.details}`,
    },
    {
      label: <Link to={`${assetRoute}${assetsRoute.purchaseOrders}`}>Purchase Order</Link>,
      key: `${assetRoute}${assetsRoute.purchaseOrders}`,
    },
    {
      label: <Link to={`${assetRoute}${assetsRoute.integrations}`}>Integrations</Link>,
      key: `${assetRoute}${assetsRoute.integrations}`,
    },
    {
      label: <Link to={`${assetRoute}${assetsRoute.bookingNumbers}`}>Booking Numbers</Link>,
      key: `${assetRoute}${assetsRoute.bookingNumbers}`,
    },
    {
      label: <Link to={`${assetRoute}${assetsRoute.redeliveryNumbers}`}>Redelivery Numbers</Link>,
      key: `${assetRoute}${assetsRoute.redeliveryNumbers}`,
    },
  ]
}
