import { AssetNumberType } from 'api'
import { LettersRegexp, OnlyNumbersRegexp } from 'constant'

export const getAssetNumberTypeByAssetNumber = (number?: string): AssetNumberType => {
  if (number) {
    if (number.length === 4 && OnlyNumbersRegexp.test(number)) {
      return AssetNumberType.Rental
    }

    if (number.length === 11) {
      const firstPart = number.slice(0, 4)
      const secondPart = number.slice(4)
      const allLetters = LettersRegexp.test(firstPart)
      const allNumbers = OnlyNumbersRegexp.test(secondPart)
      if (allLetters && allNumbers) {
        return AssetNumberType.Original
      }
    }
  }

  return AssetNumberType.Temporary
}
