import { FC } from 'react'

import {
  PurchaseOrderStatus,
  ReferenceNumber,
  ReferenceNumberStatus,
  ReferenceNumberType,
  ReferenceNumberVendorRelease,
} from 'api'
import { useForm, usePOs, useReferences } from 'hooks'
import { referenceLabels, route, vendorReleaseStatusOptions } from 'constant'
import { ReferenceFormAccessor, typedMemo } from 'types'

import { Container, EditFormItem, GridLayout, Icon, Spacer, Typography } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditSelectMultiAsync,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditTextArea,
  ReferenceStatusTag,
  StripLoader,
} from 'components'
import { ReferenceNumberByTypeProps, VendorReleaseNumberForm } from 'modules/references/types'
import { getViewOnlyFields } from 'modules/references/utils/getViewOnlyFields'

import { useUpdateVendorReleaseNumber } from './hooks/useUpdateVendorReleaseNumber'
import { vendorReleaseNumberFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { ReferenceItemsEdit } from 'modules/references/elements/ReferenceItemsEdit'

import { LocationDepotFields } from '../LocationDepotFields'
import { RelatedPOs } from '../RelatedPOs'
import { RelatedReferenceNumber } from '../RelatedReferenceNumber'

interface VendorReleaseProps extends ReferenceNumberByTypeProps {
  data: ReferenceNumberVendorRelease
}

const VendorReleaseBase: FC<VendorReleaseProps> = ({ canEditOnlyStatus, data }) => {
  const { update, isError, isLoading } = useUpdateVendorReleaseNumber(data.id)

  const { Form, triggerSubmit } = useForm<Omit<VendorReleaseNumberForm, 'items'>>({
    mode: 'onChange',
    onSubmit: update,
    isSubmitError: isError,
    validationSchema: vendorReleaseNumberFieldsValidation,
    defaultValues: { ...data, bookingNumbersIds: data?.bookingNumbers?.map(({ id }) => id) },
    viewOnlyFields: canEditOnlyStatus
      ? getViewOnlyFields(ReferenceFormAccessor.Status)
      : [ReferenceFormAccessor.CreatedAt, ReferenceFormAccessor.PurchaseOrderId],
  })

  const purchaseOrderDefaultOption = data?.purchaseOrder
    ? {
        value: data.purchaseOrder.id,
        label: data.purchaseOrder.number,
      }
    : undefined

  return (
    <>
      <Form>
        <StripLoader noBorderRadius isLoading={isLoading} />
        <GridLayout columns={2} gap={16}>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Vendor Release Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Status}
                label={referenceLabels.status}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    customPreview={
                      <ReferenceStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
                    }
                    options={vendorReleaseStatusOptions}
                    onSubmit={triggerSubmit}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.CreatedAt}
                label={referenceLabels.createdAt}
                render={({ field }) => <FormItemEditDatePicker {...field} />}
              />

              <EditFormItem
                name={ReferenceFormAccessor.ExpirationDate}
                label={referenceLabels.expirationDate}
                render={({ field }) => <FormItemEditDatePicker {...field} onBlur={triggerSubmit} />}
              />
              <LocationDepotFields
                locationCode={data?.locationCode}
                depotSetting={data?.depotSetting}
                type={ReferenceNumberType.VendorRelease}
              />
              <Container fd="column">
                <EditFormItem
                  name={ReferenceFormAccessor.Notes}
                  label={referenceLabels.notes}
                  layout="vertical"
                  render={({ field }) => (
                    <FormItemEditTextArea placeholder="Type notes" {...field} onConfirm={triggerSubmit} />
                  )}
                />
              </Container>
            </Container>
          </Container>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Related Records</Typography>
            <Container mt={4} gap={4} fd="column">
              <FormItemEditSelectSingleAsync
                name={ReferenceFormAccessor.PurchaseOrderId}
                label={referenceLabels.purchaseOrderId}
                href={route.po}
                getItems={usePOs}
                queryParams={{ status: { $notin: [PurchaseOrderStatus.Draft, PurchaseOrderStatus.Closed] } }}
                defaultOption={purchaseOrderDefaultOption}
                onSubmit={triggerSubmit}
              />
              <FormItemEditSelectMultiAsync
                name={ReferenceFormAccessor.BookingNumbersIds}
                label={referenceLabels.bookingNumbers}
                href={route.reference}
                getItems={useReferences}
                queryParams={{
                  type: { $eq: ReferenceNumberType.Booking },
                  [ReferenceFormAccessor.Status]: {
                    $notin: [ReferenceNumberStatus.Closed, ReferenceNumberStatus.Voided],
                  },
                }}
                defaultOptions={data.bookingNumbers?.map((booking) => ({ value: booking.id, label: booking.number }))}
                onBlur={triggerSubmit}
              />
            </Container>
          </Container>
        </GridLayout>
      </Form>
      <Spacer mt={9} />
      <ReferenceItemsEdit id={data?.id} type={data?.type} data={data?.items} isFormViewOnly={canEditOnlyStatus} />
      <Container gap={16} mt={4} fd="column">
        {data?.purchaseOrder && <RelatedPOs data={data.purchaseOrder} />}
        {!!data?.bookingNumbers?.length && (
          <RelatedReferenceNumber type={ReferenceNumberType.Booking} data={data.bookingNumbers as ReferenceNumber[]} />
        )}
      </Container>
    </>
  )
}

export const VendorRelease = typedMemo(VendorReleaseBase)
