import { useQuery } from '@tanstack/react-query'

import { getCustomers, GetCustomersQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetCustomers = (params: GetCustomersQueryParams) => {
  const { data, ...rest } = useQuery(
    [QueryKey.GetCustomers, params],
    () => getCustomers({ ...params, join: ['billingAddresses'] }),
    {
      keepPreviousData: true,
    },
  )

  const { total, data: customers } = data || {}

  return {
    data: customers,
    total,
    ...rest,
  }
}
