import { Tabs as BaseTabs } from 'antd'
import { css, styled } from 'styled-components'

import { Container } from '../Container'
import type { TabsProps } from './Tabs'

export const Tabs = styled(BaseTabs)<Omit<TabsProps, 'isTransparentView'> & { $isTransparentView?: boolean }>`
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lines};
  }

  &&& .ant-tabs-tab {
    padding: 12px ${({ theme }) => theme.spacers[4]};

    &:not(.ant-tabs-tab-active, &:hover) {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &&& .ant-tabs-tab-btn {
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    font-size: ${({ theme }) => theme.fontSize.s};
    line-height: ${({ theme }) => theme.lineHeight.m};
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav-wrap {
    padding: 0 ${({ theme }) => theme.spacers[3]};
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  &&.ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }

  ${({ size, theme }) =>
    size === 'small' &&
    css`
      && .ant-tabs-tab {
        padding: ${theme.spacers[3]} 12px;
      }
    `}

  ${({ $isTransparentView }) =>
    $isTransparentView &&
    css`
      background-color: transparent;

      &:before {
        display: none;
      }

      && .ant-tabs-nav-wrap {
        border-bottom: none;
        padding: 0;
      }

      &&& .ant-tabs-tab {
        border-radius: ${({ theme }) => `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0`};
        padding: ${({ theme }) => `${theme.spacers[3]} ${theme.spacers[4]}`};

        &-active {
          background-color: ${({ theme }) => theme.colors.white};
        }
      }
    `}
`

export const TabBarWrapper = styled(Container)<{ sticky?: boolean }>`
  .ant-tabs-nav {
    margin: 0 !important;
    width: 100%;
  }

  ${({ sticky, theme }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: ${theme.zIndexLevel.tabs};
      background-color: ${theme.colors.underline};
    `}
`
