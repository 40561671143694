import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrder } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { PostPickupCustomerContactsPayload, postPOContact } from '../queryApi'

interface PostContactFromVendorToPOProps {
  purchaseOrderId?: EntityId
  vendorId?: EntityId
}

export const usePostContactFromVendorToPO = ({ purchaseOrderId, vendorId }: PostContactFromVendorToPOProps) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()

  const { mutate, ...rest } = useMutation(postPOContact, {
    onSuccess: ({ data, headers }) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      dispatchAppEvent(AppEvent.PurchaseOrderPostContactFromVendorToPO)

      queryClient.setQueryData<PurchaseOrder | undefined>([QueryKey.GetPurchaseOrderById, purchaseOrderId], (old) => {
        if (!old) return old
        return {
          ...old,
          contacts: old.contacts.length ? [...old.contacts, ...data] : data,
        }
      })
      showNotification({ title: 'Contacts added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: PostPickupCustomerContactsPayload) => {
      mutate({ purchaseOrderId, vendorId, data: payload })
    },
    [mutate, purchaseOrderId, vendorId],
  )

  return {
    post,
    ...rest,
  }
}
