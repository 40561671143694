import { FC, useCallback, useRef, useState } from 'react'
import { Col, Row, Tooltip } from 'antd'

import { PurchaseOrder, PurchaseOrderStatus, PurchaseOrderType } from 'api'
import { useDepotCodes, useFormContext, useGetWarehouses, useLocationCodes, useSalesOrders } from 'hooks'
import {
  confirmationText,
  containerColorOptions,
  currency,
  paymentTermsOptions,
  purchaseOrderDepartmentOptions,
  purchaseOrderLabels,
  purchaseOrderTypeOptions,
  route,
} from 'constant'
import { PurchaseOrderFormAccessor } from 'types'
import { formatDate, isDateBeforeCurrentDateEndOfDay } from 'utils'
import theme from 'styles/theme'

import { Container, EditFormItem, Icon } from 'designSystem'
import {
  ConfirmationModal,
  FormContactsWithDetailsModal,
  FormItemCheckbox,
  FormItemEditAddress,
  FormItemEditDatePicker,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditTextArea,
  LabelValueMarkup,
} from 'components'
import { usePOContactsRequests } from 'modules/purchaseOrders/hooks/usePOContactsRequests'

import { minContactsCount } from 'modules/purchaseOrders/constants/maxRecordsCount'
import { ModalType } from './types'
import { ChangeStatusModal } from './elements/ChangeStatusModal'
import { POStatus } from './elements/POStatus'
import { PurchaseOrderStatuses } from './elements/PurchaseOrderStatuses'
import * as Styled from './styles'

interface PurchaseOrderDetailsFormProps {
  data?: PurchaseOrder
  handleSubmit: () => void
  poStatus: PurchaseOrderStatus
}

export const PurchaseOrderDetailsForm: FC<PurchaseOrderDetailsFormProps> = ({ data, handleSubmit, poStatus }) => {
  const { triggerSubmit, watch, setValue } = useFormContext()
  const { options: warehouseOptions } = useGetWarehouses()
  const { deleteContact } = usePOContactsRequests({
    purchaseOrderId: Number(data?.id),
  })
  const changeStatus = useRef<PurchaseOrderStatus | undefined>(undefined)

  const locationCodeId = watch(PurchaseOrderFormAccessor.LocationCodeId)
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined)

  const handleCloseModal = useCallback(() => {
    setModalType(undefined)
  }, [])

  const handleOpenModal = useCallback((modalType: ModalType) => {
    setModalType(modalType)
  }, [])

  const handleSubmitStatus = useCallback(
    (value: string | number | null | undefined) => {
      changeStatus.current = value as PurchaseOrderStatus
      const activeModalType = poStatus === PurchaseOrderStatus.Closed ? ModalType.Closed : ModalType.Other
      handleOpenModal(activeModalType)
    },
    [handleOpenModal, poStatus],
  )

  const handleConfirmFromModal = useCallback(() => {
    setValue(PurchaseOrderFormAccessor.Status, changeStatus.current)
    triggerSubmit?.()
    setModalType(undefined)
  }, [triggerSubmit, setValue])

  return (
    <>
      <ConfirmationModal
        open={modalType === ModalType.Closed}
        onCancel={handleCloseModal}
        onOk={handleConfirmFromModal}
        title="Confirmation Required"
        text={confirmationText.confirmationRequired}
      />
      <ChangeStatusModal
        onCancel={handleCloseModal}
        open={modalType === ModalType.Other}
        onOk={handleConfirmFromModal}
        poStatus={changeStatus.current}
      />
      <Row gutter={16}>
        <Styled.Col span={8}>
          <POStatus handleSubmitStatus={handleSubmitStatus} />
          <EditFormItem
            name={PurchaseOrderFormAccessor.Department}
            label={purchaseOrderLabels.department}
            render={({ field }) => (
              <FormItemEditSelectSingle
                {...field}
                width={140}
                placeholder="Type Department"
                onSubmit={triggerSubmit}
                options={purchaseOrderDepartmentOptions}
              />
            )}
          />
          <EditFormItem
            name={PurchaseOrderFormAccessor.Type}
            label={purchaseOrderLabels.type}
            render={({ field }) => (
              <FormItemEditSelectSingle {...field} placeholder="Type PO Type" options={purchaseOrderTypeOptions} />
            )}
          />
          <EditFormItem
            name={PurchaseOrderFormAccessor.VendorName}
            label="Vendor Name"
            render={({ field }) => <FormItemEditInput {...field} placeholder="Type Vendor Name" />}
          />
          <FormContactsWithDetailsModal
            name={PurchaseOrderFormAccessor.Contacts}
            title={purchaseOrderLabels.contacts}
            contacts={data?.contacts}
            onDelete={deleteContact}
            showDelete={data?.contacts.length !== minContactsCount}
          />
          <Container jc="flex-end" ai="center">
            <FormItemCheckbox
              name={PurchaseOrderFormAccessor.IsEmailRequired}
              label={purchaseOrderLabels.isEmailRequired}
              okText="Confirm"
              confirmText={data?.isEmailRequired ? confirmationText.willNotSendEmails : confirmationText.willSendEmails}
              showPopover
              onConfirm={triggerSubmit}
            />
            <Tooltip title="Email will be send to selected contacts">
              <Icon icon="info" size={16} color={theme.colors.placeholder} />
            </Tooltip>
          </Container>
          <Container>
            <LabelValueMarkup label="Created Date" value={formatDate(data?.createdAt, 'Standard')} />
          </Container>
        </Styled.Col>
        <Col span={8}>
          {data?.type === PurchaseOrderType.VendorRelease && (
            <EditFormItem
              name={PurchaseOrderFormAccessor.VendorReleaseNumber}
              label={purchaseOrderLabels.vendorReleaseNumber}
              render={({ field }) => (
                <FormItemEditInput
                  {...field}
                  placeholder="Type Vendor Release Number"
                  onBlur={triggerSubmit}
                  href={`${route.reference}/${data.vendorReleaseReferenceNumber?.id}`}
                />
              )}
            />
          )}
          {data?.type === PurchaseOrderType.Redelivery && (
            <EditFormItem
              name={PurchaseOrderFormAccessor.RedeliveryNumber}
              label={purchaseOrderLabels.redeliveryNumber}
              render={({ field }) => (
                <FormItemEditInput
                  {...field}
                  placeholder="Type Redelivery Number"
                  onBlur={triggerSubmit}
                  href={`${route.reference}/${data.redeliveryReferenceNumber?.id}`}
                />
              )}
            />
          )}
          {data?.type === PurchaseOrderType.Standard && (
            <>
              <EditFormItem
                name={PurchaseOrderFormAccessor.WarehouseId}
                label={purchaseOrderLabels.warehouseId}
                render={({ field }) => (
                  <FormItemEditSelectSingle {...field} options={warehouseOptions} onSubmit={triggerSubmit} />
                )}
              />
              <FormItemEditAddress
                onConfirm={triggerSubmit}
                name={PurchaseOrderFormAccessor.Warehouse}
                label={purchaseOrderLabels.warehouse}
              />
            </>
          )}
          {data?.type !== PurchaseOrderType.Standard && (
            <>
              <FormItemEditSelectSingleAsync
                name={PurchaseOrderFormAccessor.LocationCodeId}
                label={purchaseOrderLabels.locationCodeId}
                href={route.locationCodes}
                onSubmit={handleSubmit}
                defaultOption={{
                  value: data?.locationCode?.id as number,
                  label: data?.locationCode?.code as string,
                }}
                nestedEntityId={data?.vendorId}
                getItems={useLocationCodes}
              />
              <FormItemEditSelectSingleAsync
                name={PurchaseOrderFormAccessor.DepotId}
                label={purchaseOrderLabels.depotId}
                disabled={!locationCodeId}
                onSubmit={triggerSubmit}
                getItems={useDepotCodes}
                returnDefaultValueOnBlur={data?.locationCodeId === locationCodeId}
                defaultOption={{
                  value: data?.depot?.id || '',
                  label: data?.depot?.code || '',
                }}
                queryParams={{
                  ...(locationCodeId && {
                    locationCodeId: { $eq: locationCodeId },
                    vendorId: { $eq: data?.vendorId },
                    accountNumber: { $notnull: 'true' },
                  }),
                }}
              />
            </>
          )}
          {data?.type === PurchaseOrderType.Redelivery && (
            <>
              <EditFormItem
                name={PurchaseOrderFormAccessor.Dpp}
                label={purchaseOrderLabels.dpp}
                render={({ field }) => (
                  <FormItemEditInputNumber
                    {...field}
                    placeholder="DPP"
                    onBlur={triggerSubmit}
                    precision={2}
                    suffix={currency}
                  />
                )}
              />
              <EditFormItem
                name={PurchaseOrderFormAccessor.ContainerColor}
                label={purchaseOrderLabels.containerColor}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    returnDefaultValueOnBlur={false}
                    placeholder="Container Color"
                    onSubmit={triggerSubmit}
                    options={containerColorOptions}
                  />
                )}
              />
            </>
          )}
          <EditFormItem
            name={PurchaseOrderFormAccessor.ExpectedDate}
            label={purchaseOrderLabels.expectedDate}
            render={({ field }) => (
              <FormItemEditDatePicker
                {...field}
                onBlur={triggerSubmit}
                disabledDate={isDateBeforeCurrentDateEndOfDay}
              />
            )}
          />
          <EditFormItem
            name={PurchaseOrderFormAccessor.ExpirationDate}
            label={purchaseOrderLabels.expirationDate}
            render={({ field }) => (
              <FormItemEditDatePicker
                {...field}
                onBlur={triggerSubmit}
                disabledDate={isDateBeforeCurrentDateEndOfDay}
              />
            )}
          />
          <EditFormItem
            name={PurchaseOrderFormAccessor.PaymentTerms}
            label={purchaseOrderLabels.paymentTerms}
            render={({ field }) => (
              <FormItemEditSelectSingle
                {...field}
                placeholder={purchaseOrderLabels.paymentTerms}
                onSubmit={triggerSubmit}
                options={paymentTermsOptions}
              />
            )}
          />
        </Col>
        <Col span={8}>
          <Container fd="column">
            <EditFormItem
              name={PurchaseOrderFormAccessor.Notes}
              label={purchaseOrderLabels.notes}
              layout="vertical"
              render={({ field }) => (
                <FormItemEditTextArea placeholder="Type notes" {...field} onConfirm={triggerSubmit} />
              )}
            />
          </Container>
          <Container fd="column">
            <EditFormItem
              name={PurchaseOrderFormAccessor.ExternalPoNotes}
              label={purchaseOrderLabels.externalPoNotes}
              layout="vertical"
              render={({ field }) => (
                <FormItemEditTextArea placeholder="Type notes" {...field} onConfirm={triggerSubmit} />
              )}
            />
          </Container>
          {data?.type !== PurchaseOrderType.Standard && (
            <FormItemEditSelectSingleAsync
              returnDefaultValueOnBlur={false}
              name={PurchaseOrderFormAccessor.SalesOrderId}
              label={purchaseOrderLabels.salesOrderId}
              getItems={useSalesOrders}
              defaultOption={{
                value: data?.salesOrder?.id as number,
                label: data?.salesOrder?.number as string,
              }}
              onSubmit={triggerSubmit}
              href={route.so}
            />
          )}
          <PurchaseOrderStatuses />
        </Col>
      </Row>
    </>
  )
}
