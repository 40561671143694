import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getDataMapping, GetDataMappingQueryParams } from '../queryApi'

export const useGetDataMapping = (params: GetDataMappingQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetDataMapping, params], () => getDataMapping(params), {
    keepPreviousData: true,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
