import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getEquipment, GetEquipmentQueryParams } from '../queryApi'

export const useGetEquipment = (params: GetEquipmentQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetEquipment, params], () => getEquipment(params), {
    keepPreviousData: true,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
