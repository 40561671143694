import { FC, useCallback, useState } from 'react'

import { VendorType } from 'api'

import { Button, Modal, Tabs } from 'designSystem'

import { getTabItems } from './utils/getTabItems'
import { uploadTypeOptions } from '../../../../constants/uploadTypeOptions'

interface UploadSheetProps {
  vendorId: number
  type: VendorType[]
}

export const UploadSheet: FC<UploadSheetProps> = ({ vendorId, type }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  if (
    !type.includes(VendorType.ContainerVendor) &&
    !type.includes(VendorType.Modifications) &&
    !type.includes(VendorType.Parts)
  )
    return null

  const filteredUploadTypeOptions = uploadTypeOptions.filter(({ value }) => type.includes(value))
  const tabItems = getTabItems({ vendorId, handleCloseModal, uploadTypeOptions: filteredUploadTypeOptions })

  return (
    <>
      <Modal
        open={modalOpen}
        width={460}
        onCancel={handleCloseModal}
        destroyOnClose
        title="Upload"
        footer={null}
        isTabsBody
      >
        <Tabs items={tabItems} destroyInactiveTabPane />
      </Modal>
      <Button icon="upload" size="small" type="link" onClick={handleOpenModal}>
        Upload
      </Button>
    </>
  )
}
