import { useQuery } from '@tanstack/react-query'

import { getWarehouses } from 'api'
import { QueryKey } from 'enums'

export const useGetWarehouses = () => {
  const { data, ...rest } = useQuery([QueryKey.GetWarehouses], () => getWarehouses(), {
    keepPreviousData: true,
  })

  return {
    data,
    ...rest,
  }
}
