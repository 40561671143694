import { LocationCode } from 'api'

import { Tab } from 'designSystem'

import { LocationCodeDetails } from '../elements/LocationCodeDetails'
import { LocationCodeIntegrations } from '../elements/LocationCodeIntegrations'

export const getLocationCodeViewTabs = (data: LocationCode): Tab[] => [
  {
    label: 'Details',
    children: <LocationCodeDetails data={data} />,
  },
  {
    label: 'Integrations',
    children: <LocationCodeIntegrations zohoId={data.zohoId} />,
  },
]
