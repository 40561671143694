import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getDepotByVendorId } from '../queryApi'

export const useGetVendorDepotSettings = (vendorId?: EntityId) => {
  const { data, ...rest } = useQuery([QueryKey.GetDepotByVendorId, vendorId], () => getDepotByVendorId(vendorId), {
    enabled: !!vendorId,
  })

  return {
    data,
    ...rest,
  }
}
