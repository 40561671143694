import { useQuery } from '@tanstack/react-query'

import { getItemsCategories } from 'api'
import { QueryKey } from 'enums'

export const useGetItemCategories = () => {
  const { data, ...rest } = useQuery([QueryKey.GetItemCategories], () => getItemsCategories(), {
    cacheTime: 0,
    staleTime: 0,
    keepPreviousData: false,
  })

  return {
    data,
    ...rest,
  }
}
