import { useQuery } from '@tanstack/react-query'

import { getVendors, GetVendorsQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetVendors = (queryParams: GetVendorsQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetVendors, queryParams], () => getVendors(queryParams), {
    keepPreviousData: true,
  })

  const { total, data: vendors } = data || {}

  return {
    data: vendors,
    total,
    ...rest,
  }
}
