import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getTruckCodeById } from '../queryApi'

export const useGetTruckCodeById = (id?: EntityId) => {
  const navigate = useNavigate()

  const { data, ...rest } = useQuery([QueryKey.GetTruckCodeById, id], () => getTruckCodeById(id), {
    enabled: !!id,
    onError: () => {
      navigate(route.truckCodes)
    },
  })

  return {
    data,
    ...rest,
  }
}
