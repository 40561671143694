import { FC, useCallback } from 'react'

import { Warehouse } from 'api'
import { useForm } from 'hooks'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, Spinner } from 'components'

import { useDeleteWarehouse, useGetWarehouses, usePatchWarehouse, usePostWarehouse } from '../../hooks'
import { validationSchemaWarehouses } from '../../utils/validationSchema'
import { WarehousesFormData } from '../../types/warehouses'
import { FormItemEditWarehouses } from './elements/FormItemEditWarehouses'

export const WarehouseForm: FC = () => {
  const { data: warehouses, isLoading } = useGetWarehouses()
  const { post, isError: isErrorPost, isLoading: isLoadingPost } = usePostWarehouse()
  const { patch, isError: isErrorPatch, isLoading: isLoadingPatch } = usePatchWarehouse()
  const { deleteWarehouseEntity, isError: isErrorDelete } = useDeleteWarehouse()

  const { Form, watch } = useForm<WarehousesFormData>({
    validationSchema: validationSchemaWarehouses,
    mode: 'onChange',
    isSubmitError: isErrorPost || isErrorDelete || isErrorPatch,
    defaultValues: { warehouses },
  })

  const warehousesFormData: Warehouse[] = watch('warehouses') as Warehouse[]

  const onSubmitPost = useCallback(
    (index: number) => {
      post(warehousesFormData[index])
    },
    [post, warehousesFormData],
  )

  const onSubmitPatch = useCallback(
    (index: number) => {
      patch({ id: warehousesFormData[index].id, data: warehousesFormData[index] })
    },
    [patch, warehousesFormData],
  )

  const onDelete = useCallback(
    (index: number) => {
      const warehouseIdToDelete = warehousesFormData[index]?.id
      if (warehouseIdToDelete !== undefined) {
        deleteWarehouseEntity(warehouseIdToDelete)
      }
    },
    [deleteWarehouseEntity, warehousesFormData],
  )

  return (
    <Container fd="column">
      <Card maxWidth={390}>
        <Form>
          <Typography size="m" fontWeight="xl">
            Warehouses
          </Typography>
          <Spacer mb={4} />
          {isLoading && (
            <Container ai="center" jc="center" minHeight={200}>
              <Spinner size="large" />
            </Container>
          )}
          <FormItemEditWarehouses
            isLoading={isLoadingPatch || isLoadingPost}
            name="warehouses"
            onSubmitPost={onSubmitPost}
            onSubmitPatch={onSubmitPatch}
            onDelete={onDelete}
          />
        </Form>
        <Spacer mb={3} />
      </Card>
    </Container>
  )
}
