import { FC, useCallback, useState } from 'react'
import { FieldValues, UseFormWatch } from 'react-hook-form'

import { PurchaseOrderItemsSubLine, PurchaseOrderReferenceType, PurchaseOrderType } from 'api'
import { route } from 'constant'
import { PurchaseOrderSubLineAccessor } from 'types'
import { useGetReferenceNumberOptions } from 'store'

import { Container, EditFormItem } from 'designSystem'
import { FormItemEditInput, FormItemEditSelectSingle, ReferenceStatusTag } from 'components'
import { PurchaseOrderSubLineRow } from 'modules/purchaseOrders/types'
import { getTableExpandableFieldName } from 'modules/purchaseOrders/utils/getTableExpandableFieldName'

import { useCreateReferenceNumber } from './hooks/useCreateReferenceNumber'
import { getReferenceNumberData } from './utils/getReferenceNumberData'
import { CreateNewReferenceNumberModal } from './elements/CreateNewReferenceNumberModal'
import { ReferenceNumberConfirmationModal } from './elements/ReferenceNumberConfirmationModal'

interface ReferenceNumberProps {
  record: PurchaseOrderSubLineRow | Partial<PurchaseOrderItemsSubLine>
  index: number
  isEditMode?: boolean
  rowSubLinesFieldName: string
  isAddNew?: boolean
  watch?: UseFormWatch<FieldValues>
  poType?: PurchaseOrderType
  itemId?: number
}

export const ReferenceNumber: FC<ReferenceNumberProps> = ({
  isEditMode,
  record,
  rowSubLinesFieldName,
  index,
  watch,
  poType,
  itemId,
  isAddNew,
}) => {
  const subLineFieldName = `${rowSubLinesFieldName}.${index}`
  const recordType: PurchaseOrderReferenceType = watch?.(
    `${subLineFieldName}.asset.${PurchaseOrderSubLineAccessor.ReferenceType}`,
  )

  const referenceRecordType = recordType || poType

  const isFieldDisabled = (isEditMode && !!record.asset?.isReceived) || recordType === null

  const [desiredReferenceNumber, setDesiredReferenceNumber] = useState<string | number | null | undefined>(undefined)
  const [isOpenCreateReferenceNumberModal, setIsOpenCreateReferenceNumberModal] = useState(false)

  const referenceNumberOptions = useGetReferenceNumberOptions()

  const { createFieldName, editFieldName, options, status, referenceFiled } = getReferenceNumberData({
    referenceRecordType,
    referenceNumberOptions,
    record,
  })

  const handleChange = useCallback(
    (value: string | number | null | undefined) => {
      if (!isAddNew) {
        setDesiredReferenceNumber(value)
      }
    },
    [setDesiredReferenceNumber, isAddNew],
  )

  const toggleCreateReferenceNumberModal = useCallback(() => {
    setIsOpenCreateReferenceNumberModal((prev) => !prev)
  }, [])

  const { createReferenceNumber, isLoading } = useCreateReferenceNumber({
    itemId,
    subLineId: record.id,
    toggleCreateReferenceNumberModal,
    createFieldName,
  })

  const defaultOption =
    record.asset && editFieldName && referenceFiled
      ? {
          value: record.asset[referenceFiled]?.id || '',
          label: record.asset[referenceFiled]?.number || '',
        }
      : undefined

  if ((isEditMode || isAddNew) && editFieldName) {
    return (
      <>
        <ReferenceNumberConfirmationModal
          subLineFieldName={subLineFieldName}
          editFieldName={editFieldName}
          desiredReferenceNumber={desiredReferenceNumber}
          setDesiredReferenceNumber={setDesiredReferenceNumber}
        />
        <CreateNewReferenceNumberModal
          onConfirm={createReferenceNumber}
          type={referenceRecordType}
          open={isOpenCreateReferenceNumberModal}
          toggleCreateReferenceNumberModal={toggleCreateReferenceNumberModal}
          isLoading={isLoading}
        />
        <Container ai="center">
          <Container>{status && <ReferenceStatusTag type="tooltip" status={status} />}</Container>
          <Container maxWidth={180} jc="flex-start">
            <EditFormItem
              name={getTableExpandableFieldName({
                isEditMode: !isAddNew,
                rowSubLinesFieldName,
                index,
                fieldName: editFieldName,
              })}
              render={({ field }) => (
                <FormItemEditSelectSingle
                  {...field}
                  disableOnChange={!isAddNew}
                  returnDefaultValueOnBlur={false}
                  size="small"
                  options={referenceRecordType ? options : []}
                  onSubmit={handleChange}
                  disabled={isFieldDisabled}
                  defaultOption={defaultOption}
                  href={route.reference}
                  withCreateNew={!isAddNew}
                  onClickCreateNew={toggleCreateReferenceNumberModal}
                />
              )}
            />
          </Container>
        </Container>
      </>
    )
  }
  return (
    <EditFormItem
      name={`${rowSubLinesFieldName}.${index}.${createFieldName}`}
      render={({ field }) => (
        <FormItemEditInput width={160} {...field} align="left" size="small" placeholder="Reference number" />
      )}
    />
  )
}
