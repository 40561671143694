import { FC, useCallback, useEffect, useState } from 'react'

import { PurchaseOrderEmail } from 'api'
import { useFirstRender } from 'hooks'
import { SendEmailAction } from 'types'
import { usePurchaseOrderData, useSetPurchaseOrderRequestId } from 'store'

import { getPurchaseOrderEmailTypes } from './utils/getPurchaseOrderEmailTypes'
import { PurchaseOrderSendEmail } from './elements/PurchaseOrderSendEmail'

interface PurchaseOrderEmailsProps {
  action: SendEmailAction
  removeAction: () => void
}

export const PurchaseOrderModals: FC<PurchaseOrderEmailsProps> = ({ action, removeAction }) => {
  const purchaseOrderData = usePurchaseOrderData()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()
  const [emailTypes, setEmailTypes] = useState<PurchaseOrderEmail[]>([])
  const isFirstRender = useFirstRender()

  useEffect(() => {
    setEmailTypes(getPurchaseOrderEmailTypes(action, purchaseOrderData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = useCallback(
    (type: PurchaseOrderEmail) => () => {
      setTimeout(() => {
        setEmailTypes((prev) => prev.filter((prevType) => prevType !== type))
      }, 300)
    },
    [],
  )

  useEffect(() => {
    if (!isFirstRender && !emailTypes.length) {
      removeAction()
      setPurchaseOrderRequestId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTypes])

  return emailTypes.map((emailType) => (
    <PurchaseOrderSendEmail
      key={emailType}
      modalType={emailType}
      onClose={handleClose(emailType)}
      purchaseOrder={purchaseOrderData}
    />
  ))
}
