import { ReferenceNumberBookingAsset, ReferenceNumberBookingType } from 'api'
import { useAssets } from 'hooks'
import { bookingAssetStatusOptions, route } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'
import { getTableTitle } from 'utils'

import { Checkbox, Container, EditFormItem, FormItem, Icon, IconDelete } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  ReferenceNumberAssetStatusTag,
  TableColumnKey,
  TableColumnsType,
} from 'components'
import { getItemsAssetsListProps } from 'modules/references/utils/getItemsAssetsListProps'

import { getSubLineAccessorTitle } from './getSubLineAccessorTitle'
import type { TableColumnsProps } from './getTableColumns'

export const getBookingTableColumns = ({
  skuIndex,
  fieldName,
  onDelete,
  itemId,
  handleAssetChange,
  assetsIds,
  data,
  triggerSubmit,
  isFormViewOnly,
  referenceData,
}: TableColumnsProps): TableColumnsType<ReferenceNumberBookingAsset> => {
  const assetsIdsArray = Object.values(assetsIds || {})
  const skuSubLinesFromData = data?.[skuIndex]?.subLines || []
  const { vendorReleaseId, depotSettingId, bookingType } = referenceData || {}
  const isBookingTypeVendorRelease = bookingType === ReferenceNumberBookingType.VendorRelease

  return [
    {
      title: getTableTitle(TableColumnKey.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => index + 1,
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Asset),
      key: ReferenceSubLineFormAccessor.Asset,
      dataIndex: ReferenceSubLineFormAccessor.Asset,
      width: isBookingTypeVendorRelease ? '60%' : '45%',
      ellipsis: true,
      render: (value, record, index) => {
        const asset = skuSubLinesFromData[index]?.asset

        return (
          <FormItemEditSelectSingleAsync
            name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.AssetId}`}
            isFieldViewOnly={isFormViewOnly}
            fullwidth
            defaultOption={asset ? { value: asset.id, label: asset.assetNumber } : undefined}
            href={route.assets}
            disabledValues={assetsIdsArray}
            isCreateMode={!asset}
            disabled={!itemId}
            size="small"
            getItems={useAssets}
            width={160}
            placeholder="Select Asset"
            onChange={(value) => handleAssetChange(index, value)}
            onSubmit={triggerSubmit}
            {...getItemsAssetsListProps(itemId, vendorReleaseId, depotSettingId)}
          />
        )
      },
    },
    ...(!isBookingTypeVendorRelease
      ? [
          {
            title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.IsModificationRequired),
            key: ReferenceSubLineFormAccessor.IsModificationRequired,
            dataIndex: ReferenceSubLineFormAccessor.IsModificationRequired,
            width: '15%',
            ellipsis: true,
            render: (value: boolean, record: ReferenceNumberBookingAsset, index: number) => (
              <FormItem
                name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.IsModificationRequired}`}
                isViewOnly={isFormViewOnly}
              >
                <Checkbox onChange={triggerSubmit} />
              </FormItem>
            ),
          },
        ]
      : []),
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.IsRepairRequired),
      key: ReferenceSubLineFormAccessor.IsRepairRequired,
      dataIndex: ReferenceSubLineFormAccessor.IsRepairRequired,
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => (
        <FormItem
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.IsRepairRequired}`}
          isViewOnly={isFormViewOnly}
        >
          <Checkbox onChange={triggerSubmit} />
        </FormItem>
      ),
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.GateOutDate),
      key: ReferenceSubLineFormAccessor.GateOutDate,
      dataIndex: ReferenceSubLineFormAccessor.GateOutDate,
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => (
        <EditFormItem
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.GateOutDate}`}
          isViewOnly={isFormViewOnly}
          size="small"
          render={({ field }) => <FormItemEditDatePicker {...field} align="left" size="small" onBlur={triggerSubmit} />}
        />
      ),
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Status),
      key: ReferenceSubLineFormAccessor.Status,
      dataIndex: ReferenceSubLineFormAccessor.Status,
      width: '15%',
      ellipsis: true,
      align: 'right',
      render: (value, record, index) => (
        <EditFormItem
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.Status}`}
          isViewOnly={isFormViewOnly}
          render={({ field }) => (
            <FormItemEditSelectSingle
              {...field}
              fullwidth
              size="small"
              customPreview={
                <ReferenceNumberAssetStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
              }
              placeholder="Status"
              options={bookingAssetStatusOptions}
              onSubmit={triggerSubmit}
            />
          )}
        />
      ),
    },
    ...(isFormViewOnly
      ? []
      : [
          {
            key: TableColumnKey.HoverActions,
            dataIndex: TableColumnKey.HoverActions,
            ellipsis: true,
            render: (value: undefined, record: ReferenceNumberBookingAsset, index: number) => {
              const isLastSubLine = skuSubLinesFromData.length === 1
              return isLastSubLine ? null : (
                <Container jc="flex-end">
                  <IconDelete onClick={() => onDelete?.(index)} />
                </Container>
              )
            },
          },
        ]),
  ]
}
