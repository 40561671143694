import { css, styled } from 'styled-components'

export const Wrapper = styled.div<{
  isEditMode: boolean
}>`
  min-width: 0;
  display: flex;
  form {
    min-width: 0;
  }
  ${({ isEditMode }) =>
    isEditMode &&
    css`
      width: 100%;
      form {
        flex: 1;
      }
    `}
`
