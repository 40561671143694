import * as Yup from 'yup'

import {
  AssetCodeAdditionalAttribute,
  AssetCodeGrade,
  AssetCodeHeight,
  AssetCodeLength,
  AssetCodeType,
  AssetCodeWidth,
} from 'api'

export const validationSchema = Yup.object({
  code: Yup.string().max(50, 'Max length of 50 characters').required('Asset Code is required'),
  type: Yup.mixed<AssetCodeType>().oneOf(Object.values(AssetCodeType)).required('Type is required'),
  length: Yup.mixed<AssetCodeLength>()
    .oneOf(Object.keys(AssetCodeLength).map((key) => AssetCodeLength[key as keyof typeof AssetCodeLength]))
    .required('Length is required'),
  width: Yup.mixed<AssetCodeWidth>()
    .oneOf(Object.keys(AssetCodeWidth).map((key) => AssetCodeWidth[key as keyof typeof AssetCodeWidth]))
    .required('Width is required'),
  height: Yup.mixed<AssetCodeHeight>()
    .oneOf(Object.keys(AssetCodeHeight).map((key) => AssetCodeHeight[key as keyof typeof AssetCodeHeight]))
    .required('Height is required'),
  grade: Yup.mixed<AssetCodeGrade>().oneOf(Object.values(AssetCodeGrade)).required('Grade is required'),
  showInCalculator: Yup.boolean().nullable().default(false),
  showInGlobalInventory: Yup.boolean().nullable().default(false),
  additionalAttributes: Yup.array(Yup.mixed<AssetCodeAdditionalAttribute>().required()).required(),
  items: Yup.array(),
})
