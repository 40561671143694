import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { submitLogin, SubmitLoginPayload, SubmitLoginResponse } from '../queryApi'

interface useSubmitLoginProps {
  onSuccess: (data: SubmitLoginResponse) => void
}

export const useSubmitLogin = ({ onSuccess }: useSubmitLoginProps) => {
  const { mutate, ...rest } = useMutation(submitLogin, {
    onSuccess,
  })

  const login = useCallback(
    (payload: SubmitLoginPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    login,
    ...rest,
  }
}
