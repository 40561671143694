import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { patchVendorIsFavorite } from '../queryApi'

export const useUpdateVendorIsFavorite = (id: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(patchVendorIsFavorite, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.VendorUpdate)
      queryClient.setQueryData([QueryKey.GetVendorById, data.id], (prev) => {
        return prev ? { ...prev, isFavorite: data.isFavorite } : undefined
      })
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const update = useCallback(
    (isFavorite: boolean) => {
      mutate({ id, isFavorite })
    },
    [id, mutate],
  )

  return {
    update,
    ...rest,
  }
}
