import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, Spinner } from 'components'

import { useGetVendorDepotSettings } from './hooks/useGetVendorDepotSettings'
import { DepotForm } from './elements/DepotForm'

export const Depot: FC = () => {
  const { id } = useParams()
  const { data, isFetching } = useGetVendorDepotSettings(Number(id))

  if (isFetching) {
    return <Spinner />
  }

  if (!data) {
    return null
  }

  return (
    <Container display="flex" fd="column" ma={4}>
      <Card maxWidth={390}>
        <Typography size="l" fontWeight="xl">
          Depot Info
        </Typography>
        <Spacer mb={4} />
        <DepotForm vendorId={Number(id)} data={data} />
      </Card>
    </Container>
  )
}
