import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrderItem } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { PurchaseOrderItemsPayload } from '../types/purchaseOrderItemsForm'

import { postPurchaseOrderItem } from '../queryApi'

export const usePostPurchaseOrderItem = (purchaseOrderId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()

  const { mutate, ...rest } = useMutation(postPurchaseOrderItem, {
    onSuccess: ({ data, headers }, { purchaseOrderId }) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      dispatchAppEvent(AppEvent.PurchaseOrderItemCreated)
      queryClient.setQueryData(
        [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
        (prev: PurchaseOrderItem[] | undefined) => {
          return prev ? [data, ...prev] : undefined
        },
      )
      showNotification({ title: 'Item created', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: PurchaseOrderItemsPayload) => {
      mutate({ purchaseOrderId, data: payload })
    },
    [mutate, purchaseOrderId],
  )

  return {
    post,
    ...rest,
  }
}
