import { itemsRoute } from 'constant'

import { ItemDetails } from '../routes/ItemDetails'
import { ItemIntegrations } from '../routes/ItemIntegrations'

export const itemRoutes = [
  {
    path: itemsRoute.details,
    element: <ItemDetails />,
  },
  {
    path: itemsRoute.integrations,
    element: <ItemIntegrations />,
  },
]
