import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorCarrierSettingsEquipment } from 'api'
import { useForm } from 'hooks'
import { AppModule } from 'types'

import { Button, Container } from 'designSystem'
import { Table, TableFilterOptions, TableSearchSingle, TableTitle, useTableColumns } from 'components'

import { useEquipment } from './hooks/useEquipment'
import { useEquipmentRequests } from './hooks/useEquipmentRequests'
import { getTableColumns } from './utils/getTableColumns'
import { equipmentVisibleColumnsOptions } from './constants/equipmentVisibleColumns'
import { validationSchema } from './constants/validationSchema'
import { EquipmentForm } from './types/equipmentCreateForm'

export const CarrierEquipment: FC = () => {
  const { id } = useParams()

  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    offsetCount,
    TableContextProvider,
    // editable table
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = useEquipment(Number(id))

  const { onSubmitPost, onSubmitPatch, onDelete, isSubmitError, isEditLoading } = useEquipmentRequests(
    setIsAddNew,
    data,
  )

  const { Form, handleSubmit, triggerSubmit } = useForm<EquipmentForm>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data,
    },
  })

  const allColumns = getTableColumns({ offsetCount, isAddNew, onCancelNew, onDelete, triggerSubmit })
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Partial<VendorCarrierSettingsEquipment>>({
    module: AppModule.VendorCarrierEquipment,
    allColumns,
    withActions: true,
  })

  return (
    <Container ma={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          title="Equipment"
          total={pagination?.total}
          search={<TableSearchSingle searchFieldName="title" />}
          withoutDefaultPadding
          columns={
            <TableFilterOptions
              title="Columns"
              icon="columns"
              options={equipmentVisibleColumnsOptions}
              value={visibleColumns}
              onChange={setVisibleColumns}
            />
          }
        >
          <Button icon="plus" type="primary" size="small" onClick={onAddNew} disabled={isAddNew}>
            Add
          </Button>
        </TableTitle>

        <Form onSubmit={handleSubmit(onSubmitPost)}>
          <Table<Partial<VendorCarrierSettingsEquipment>>
            scroll={{ x: 800 }}
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
