import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { VendorDataMapping } from 'api'
import { useForm } from 'hooks'

import { Button, Container } from 'designSystem'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { useDataMapping } from './hooks/useDataMapping'
import { useDataMappingRequests } from './hooks/useDataMappingRequests'
import { getTableColumns } from './utils/getTableColumns'
import { validationSchema } from './constants/validationSchema'
import { DataMappingForm } from './types/dataMappingForm'

export const DataMapping: FC = () => {
  const { id } = useParams()

  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    offsetCount,
    TableContextProvider,
    // editable table
    dataSource,
    isAddNew,
    setIsAddNew,
    onAddNew,
    onCancelNew,
  } = useDataMapping(Number(id))

  const { onSubmitPost, onSubmitPatch, onDelete, isSubmitError, isEditLoading } = useDataMappingRequests(
    setIsAddNew,
    data,
  )

  const { Form, handleSubmit, triggerSubmit } = useForm<DataMappingForm>({
    validationSchema: validationSchema(isAddNew),
    mode: 'onChange',
    isTableEditForm: true,
    isLoading,
    onSubmit: onSubmitPatch,
    isSubmitError,
    defaultValues: {
      list: data,
    },
    shouldFocusError: false,
  })

  const columns = getTableColumns({
    offsetCount,
    isAddNew,
    onCancelNew,
    onDelete,
    triggerSubmit,
    vendorId: Number(id),
  })

  return (
    <Container pa={4} fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Data mapping"
          total={pagination.total}
          search={<TableSearchSingle searchFieldName={['vendorSKU', 'itemSKU']} />}
        >
          <Button icon="plus" type="primary" size="small" onClick={onAddNew} disabled={isAddNew}>
            Add
          </Button>
        </TableTitle>

        <Form onSubmit={handleSubmit(onSubmitPost)}>
          <Table<Partial<VendorDataMapping>>
            dataSource={dataSource}
            columns={columns}
            viewOnlyExceptFirstRow={isAddNew}
            loading={isLoading || isEditLoading}
            pagination={pagination}
            onChange={onTableChange}
            isEditLoading={isEditLoading}
            scroll={{ x: 600 }}
          />
        </Form>
      </TableContextProvider>
    </Container>
  )
}
