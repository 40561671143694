import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Tabs } from 'designSystem'
import { Breadcrumbs, Spinner } from 'components'

import { useGetLocationCodeById } from './hooks/useGetLocationCodeById'
import { getBreadcrumbsData } from './utils/getBreadcrumbsData'
import { getLocationCodeViewTabs } from './utils/getLocationCodeViewTabs'
import { ActionButtons } from './elements/ActionButtons'

export const LocationCodeView: FC = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetLocationCodeById(Number(id))

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {!!data && (
        <>
          <Breadcrumbs breadcrumbItems={getBreadcrumbsData({ title: data.code })} actions={<ActionButtons />} />
          <Tabs isTransparentView items={getLocationCodeViewTabs(data)} />
        </>
      )}
    </>
  )
}
