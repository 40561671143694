import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { DepotPayload } from '../types/depotPayload'

import { patchVendorDepot } from '../queryApi'

export const useUpdateVendorDepot = (vendorId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(patchVendorDepot, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.VendorDepotUpdate)

      queryClient.setQueryData([QueryKey.GetDepotByVendorId, vendorId], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const update = useCallback(
    (payload: DepotPayload) => {
      const data = { ...payload }
      if (payload.accountNumber === '') {
        data.accountNumber = null
      }

      mutate({ vendorId, data })
    },
    [mutate, vendorId],
  )

  return {
    update,
    ...rest,
  }
}
