import { useCallback, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'

import { VendorCarrierSettingsLocation } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteCarrierLocation, GetCarrierLocationsResponse } from '../queryApi'

export const useDeleteCarrierLocation = (id: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const idToDelete = useRef<number>()

  const { mutate, ...rest } = useMutation(deleteCarrierLocation, {
    onSuccess: () => {
      dispatchAppEvent(AppEvent.VendorCarrierLocationDelete)

      queryClient.setQueryData(
        [QueryKey.GetCarrierLocationsByVendorId, id],
        (old: GetCarrierLocationsResponse | undefined) =>
          ({
            ...old,
            data: old?.data.filter((location: VendorCarrierSettingsLocation) => location.id !== idToDelete.current),
          }) as GetCarrierLocationsResponse,
      )
      idToDelete.current = undefined
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const remove = useCallback(
    (locationId: number) => {
      idToDelete.current = locationId
      mutate({ vendorId: id, locationId })
    },
    [mutate, id],
  )

  return {
    remove,
    ...rest,
  }
}
