import { useQuery } from '@tanstack/react-query'

import { EntityId, getVendorContacts } from 'api'
import { QueryKey } from 'enums'

export const useGetVendorContacts = (id?: EntityId, enabled = true) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetVendorContacts, id],
    () => getVendorContacts(id),
    {
      enabled: enabled && !!id,
    },
  )

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
