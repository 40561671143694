import { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useForm } from 'hooks'
import { route } from 'constant'
import { useItemActiveCategoryId, useItemRemoveActiveCategoryId } from 'store'

import { Button, Container } from 'designSystem'
import { Breadcrumbs } from 'components'

import { useCreateItem } from './hooks/useCreateItem'
import { validationSchema } from '../../utils/validationSchema'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { ItemCreateForm } from '../../types/itemForm'
import { ItemCreateFormData } from '../../types/itemPayload'
import { Categories } from './elements/Categories'
import { GroupSKUs } from './elements/GroupSKUs'
import { ItemFields } from './elements/ItemFields'

export const ItemCreate: FC = () => {
  const {
    Form,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ItemCreateForm>({
    validationSchema,
  })
  const { create, isLoading } = useCreateItem()
  const activeCategoryId = useItemActiveCategoryId()
  const removeActiveCategoryId = useItemRemoveActiveCategoryId()

  const { categoryId: categoryIdError } = errors

  const onSubmit = useCallback(
    (values: ItemCreateFormData) => {
      create(values)
    },
    [create],
  )

  useEffect(() => {
    if (activeCategoryId) {
      setValue('categoryId', activeCategoryId)
    }
  }, [activeCategoryId, setValue])

  useEffect(() => {
    return () => {
      removeActiveCategoryId()
    }
  }, [removeActiveCategoryId])

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container fd="column" ma={7}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container display="flex" jc="center" fd="column" ai="center">
            <Container fd="column" gap={8} maxWidth={500}>
              <ItemFields />
              <GroupSKUs />
            </Container>
          </Container>
        </Form>
        <Container fd="column" ai="center" mt={3}>
          <Container maxWidth={500}>
            <Categories categoryError={String((!activeCategoryId && categoryIdError?.message) || '')} />
          </Container>
          <Container jc="space-between" mt={4} maxWidth={500}>
            <Link to={route.items}>
              <Button type="default">Cancel</Button>
            </Link>
            <Button type="primary" onClick={handleSubmit(onSubmit)} loading={isLoading}>
              Create
            </Button>
          </Container>
        </Container>
      </Container>
    </>
  )
}
