export enum Accessor {
  Index = 'index',
  VendorName = 'vendorName',
  Status = 'status',
  ExpectedDate = 'expectedDate',
  Department = 'department',
  Number = 'number',
  Type = 'type',
  LocationDepot = 'locationDepot',
  PaymentTerms = 'paymentTerms',
  ExpirationDate = 'expirationDate',
  Qty = 'allQty',
  ItemsTotal = 'totalAmount',
  Notes = 'notes',
  ExternalPoNotes = 'externalPoNotes',
}
