import { FC } from 'react'

import { useFormContext, useItems } from 'hooks'

import { Spacer, Typography } from 'designSystem'
import { Card, FormItemEditSelectSingleAsyncGroup } from 'components'

import * as Styled from '../../styles'

export const EditGroupSKUs: FC = () => {
  const { watch, triggerSubmit } = useFormContext()
  const mainItemId = watch('id')

  return (
    <>
      <Styled.Divider />
      <Card bordered={false} pa={0}>
        <Typography fontWeight="xl" size="m">
          Grouped SKU
        </Typography>
        <Spacer mt={3} />
        <FormItemEditSelectSingleAsyncGroup
          name="groupedSKUs"
          label="Item SKU"
          disabledValues={[mainItemId]}
          onSubmit={triggerSubmit}
          getItems={useItems}
        />
      </Card>
    </>
  )
}
