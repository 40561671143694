import { FC, useCallback } from 'react'

import { ReferenceNumber } from 'api'
import { useGetCustomerContacts } from 'hooks'

import { AddContactsActionButton, AddContactsFormData } from 'components'
import { maxContactsCount } from 'modules/customers'

import { usePickupContactsRequests } from '../../../../hooks/usePickupContactsRequests'

type AddPickupContactsProps = Pick<ReferenceNumber, 'id' | 'customerId' | 'contacts'>

export const AddPickupContacts: FC<AddPickupContactsProps> = ({ id, customerId, contacts }) => {
  const { data: customerContacts = [], isLoading: isGetLoading } = useGetCustomerContacts(customerId)
  const { postCustomerContacts, postNewContact, isLoading, isSuccess } = usePickupContactsRequests(id, customerId)

  const handleSubmit = useCallback(
    (payload: AddContactsFormData) => {
      const { contacts = [], entityContacts = [] } = payload

      if (contacts.length) {
        postNewContact(contacts[0])
      }

      if (entityContacts.length) {
        postCustomerContacts({ customerContactsIds: entityContacts })
      }
    },
    [postNewContact, postCustomerContacts],
  )

  const showAdd = !isGetLoading && (contacts?.length || 0) < maxContactsCount

  return (
    showAdd && (
      <AddContactsActionButton
        contacts={contacts}
        entityContacts={customerContacts}
        entityContactsCount={customerContacts.length || 0}
        maxContactsCount={maxContactsCount}
        onConfirm={handleSubmit}
        isConfirmSuccess={isSuccess}
        confirmLoading={isLoading}
      />
    )
  )
}
