import { ReferenceNumberAssetStatus } from 'api/types/referenceNumber/referenceNumberAssetStatus'

export const referenceNumberAssetStatusLabel = {
  [ReferenceNumberAssetStatus.Open]: 'Open',
  [ReferenceNumberAssetStatus.Pending]: 'Pending',
  [ReferenceNumberAssetStatus.PendingRedelivery]: 'Pending Redelivery',
  [ReferenceNumberAssetStatus.Received]: 'Received',
  [ReferenceNumberAssetStatus.Closed]: 'Closed',
  [ReferenceNumberAssetStatus.Voided]: 'Voided',
  [ReferenceNumberAssetStatus.Rejected]: 'Rejected',
}

export const bookingAssetStatusOptions = [
  { value: ReferenceNumberAssetStatus.Open, label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Open] },
  {
    value: ReferenceNumberAssetStatus.Closed,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Closed],
  },
  {
    value: ReferenceNumberAssetStatus.Voided,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Voided],
  },
  {
    value: ReferenceNumberAssetStatus.Rejected,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Rejected],
  },
]

export const redeliveryAssetStatusOptions = [
  {
    value: ReferenceNumberAssetStatus.PendingRedelivery,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.PendingRedelivery],
  },
  {
    value: ReferenceNumberAssetStatus.Received,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Received],
  },
  {
    value: ReferenceNumberAssetStatus.Voided,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Voided],
  },
]

export const vendorReleaseAssetStatusOptions = [
  { value: ReferenceNumberAssetStatus.Open, label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Open] },
  {
    value: ReferenceNumberAssetStatus.Pending,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Pending],
  },
  {
    value: ReferenceNumberAssetStatus.Closed,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Closed],
  },
  {
    value: ReferenceNumberAssetStatus.Voided,
    label: referenceNumberAssetStatusLabel[ReferenceNumberAssetStatus.Voided],
  },
]

export const pickupAssetStatusOptions = vendorReleaseAssetStatusOptions
