import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { assetCodesRoutes } from './constants/assetCodesRoutes'

export const AssetCodes: FC = () => {
  return (
    <Routes>
      {assetCodesRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  )
}
