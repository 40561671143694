export enum QueryKey {
  UserMe = 'userMe',
  UserMeSettings = 'userMeSettings',
  GetUserById = 'getUserById',
  GetUsers = 'getUsers',
  GetRoles = 'getRoles',
  //Locations
  GetLocationCodes = 'getLocationCodes',
  GetLocationCodeById = 'getLocationCodeById',
  GetLocationsByVendorId = 'getLocationsByVendorId',
  GetTruckCodes = 'getTruckCodes',
  GetTruckCodeById = 'getTruckCodeById',
  GetAssetCodes = 'getAssetCodes',
  GetAssetCodeById = 'getAssetCodeById',
  GetAssetBookingNumbers = 'getAssetBookingNumbers',
  GetAssetRedeliveryNumbers = 'getAssetRedeliveryNumbers',
  GetMyCompany = 'getMyCompany',
  GetAssets = 'getAssets',
  GetAssetById = 'getAssetById',
  GetItems = 'getItems',
  GetItemById = 'getItemById',
  // Common for get lists for select
  GetAssetsList = 'getAssetsList',
  GetAssetCodesList = 'getAssetCodesList',
  GetLocationCodesList = 'getLocationCodesList',
  GetItemsList = 'getItemsList',
  GetTruckCodesList = 'getTruckCodesList',
  GetVendorsList = 'getVendorsList',
  GetVendorInventoryList = 'getVendorInventoryList',
  GetVendorSKUsList = 'getVendorSKUs',
  GetDepotCodesList = 'getDepotCodesList',
  GetCustomersCitiesList = 'getCustomersCitiesList',
  GetCustomersStatesList = 'getCustomersStatesList',
  GetCustomersList = 'getCustomersList',
  GetUsersList = 'getUsersList',
  GetReferencesList = 'getReferencesList',
  GetSalesOrderList = 'getSalesOrderList',
  GetPurchaseOrderList = 'getPurchaseOrderList',
  //Item Categories
  GetItemCategories = 'getItemCategories',
  //Vendor
  GetVendorPurchaseOrders = 'getVendorPurchaseOrders',
  GetVendorById = 'getVendorById',
  GetVendors = 'getVendors',
  GetVendorContacts = 'getVendorContacts',
  GetCarrierByVendorId = 'getCarrierByVendorId',
  GetContainerVendorByVendorId = 'getContainerVendorByVendorId',
  GetModificationFeedByVendorId = 'getModificationFeedByVendorId',
  GetModificationsByVendorId = 'getModificationsByVendorId',
  GetDepotByVendorId = 'getDepotByVendorId',
  GetPartsByVendorId = 'getPartsByVendorId',
  GetCarrierLocationsByVendorId = 'getCarrierLocationsByVendorId',
  GetEquipment = 'getEquipment',
  GetResaleRules = 'getResaleRules',
  GetDataMapping = 'getDataMapping',
  GetModifications = 'getModifications',
  GetVendorInventoryContainerVendor = 'getVendorInventoryContainerVendor',
  GetVendorInventoryParts = 'getVendorInventoryParts',
  GetGlobalInventoryMatrix = 'getGlobalInventoryMatrix',
  GetConexWestAssets = 'getConexWestAssets',
  GetVendorItems = 'getVendorItems',
  GetGlobalInventoryVendor = 'getGlobalInventoryVendor',
  //Warehouses
  GetWarehouses = 'getWarehouses',
  GetVendorCarrierPriceList = 'getVendorCarrierPriceList',
  GetCustomers = 'getCustomers',
  GetCustomerById = 'getCustomerById',
  GetCustomerContacts = 'getCustomerContacts',
  GetCustomerBillingAddresses = 'getCustomerBillingAddresses',
  GetCustomerShippingAddresses = 'getCustomerShippingAddresses',
  GetCustomerUccAddresses = 'getCustomerUccAddresses',
  //PO
  GetPurchaseOrders = 'getPurchaseOrders',
  GetPurchaseOrderById = 'getPurchaseOrderById',
  GetPurchaseOrderItems = 'getPurchaseOrderItems',
  // References
  GetReferenceNumbers = 'getReferenceNumbers',
  GetReferenceNumberById = 'getReferenceNumberById',
  GetReferenceItems = 'getReferenceItems',
  //SO
  GetSalesOrders = 'getSalesOrders',
  GetSalesOrderById = 'getSalesOrderById',
  // Email
  GetEmailTemplateByType = 'getEmailTemplateByType',
  // Activity Streamline
  GetActivityStreamline = 'getActivityStreamline',
}
