import type { Item } from 'api'

export const itemLabels: Record<keyof Item, string> = {
  id: '',
  zohoId: 'Zoho ID',
  name: 'Item Name',
  description: 'Description',
  sku: 'SKU',
  type: 'Type',
  locationCode: '',
  locationCodeId: 'Related Location',
  categoryId: 'Categories',
  price: 'Selling Price',
  groupedSKUs: 'Grouped SKU',
  category: '',
}
