import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { useForm } from 'hooks'
import { currency, customerAccountValueOptions, customerBuyOptions, customerIndustryOptions, route } from 'constant'
import { isDateBeforeCurrentDateEndOfDay } from 'utils'

import {
  Button,
  Container,
  DatePicker,
  FormItem,
  Input,
  InputNumber,
  SelectSingle,
  Spacer,
  TextArea,
  Typography,
} from 'designSystem'
import { Breadcrumbs, Card, Contacts, FormItemAddressGroup } from 'components'

import { useCreateCustomer } from './hooks/useCreateCustomer'
import { maxBillingAddressesCount, maxContactsCount, maxShippingAddressesCount } from '../../constants/maxRecordsCount'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { validationSchema } from './constants/validationSchema'
import { CustomerPayload } from './types/customerPayload'
import { UccFields } from './elements/UccFields'
import * as Styled from './styles'

export const CustomerCreate: FC = () => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { create, isLoading } = useCreateCustomer()

  const { Form, handleSubmit } = useForm<CustomerPayload>({
    validationSchema,
  })

  const onClickCancel = () => navigate(route.customers)

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container ai="center" jc="center" my={7} mx={10}>
        <Form onSubmit={handleSubmit(create)} style={{ width: '100%' }}>
          <Row gutter={8}>
            <Col span={24} xl={{ span: 7 }} />
            <Col span={24} xl={{ span: 10 }}>
              <Styled.FieldsWrapper fd="column" jc="center" fg={1} maxWidth={!screens.xl ? 500 : undefined}>
                <Card mb={3}>
                  <Typography fontWeight="xl" size="l">
                    Create Customer
                  </Typography>
                  <Spacer mb={4} />
                  <Container fd="column" gap={8}>
                    <Row gutter={8}>
                      <Col span={12}>
                        <FormItem name="companyName" label="Customer Company Name">
                          <Input placeholder="Enter Company Name" />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem name="industry" label="Industry">
                          <SelectSingle options={customerIndustryOptions} />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={12}>
                        <FormItem name="accountValue" label="Account Value">
                          <SelectSingle options={customerAccountValueOptions} />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem name="accountNumber" label="Account Number">
                          <Input placeholder="Enter Account Number" />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <FormItem name="website" label="Company Website">
                          <Input placeholder="https//:" />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={12}>
                        <FormItem name="buys" label="Buys">
                          <SelectSingle options={customerBuyOptions} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Container>
                </Card>
                <Card mb={3}>
                  <Typography fontWeight="xl" size="l">
                    Financial Information
                  </Typography>
                  <Spacer mb={4} />
                  <Row gutter={8}>
                    <Col span={12}>
                      <FormItem name="creditLimit" label="Credit limit">
                        <InputNumber placeholder="00.00" precision={2} prefix={currency} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem name="creditLimitExpirationDate" label="Exp. Date">
                        <DatePicker disabledDate={isDateBeforeCurrentDateEndOfDay} />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
                <UccFields />
                <Spacer mb={3} />
                <FormItemAddressGroup
                  name="billingAddresses"
                  label="Billing Address"
                  title="Billing Addresses"
                  maxCount={maxBillingAddressesCount}
                />
                <Spacer mb={3} />
                <FormItemAddressGroup
                  name="shippingAddresses"
                  label="Shipping Address"
                  title="Shipping Addresses"
                  maxCount={maxShippingAddressesCount}
                />
                {!screens.xl && (
                  <Container fd="column" mt={3} gap={8}>
                    <Contacts maxContactsCount={maxContactsCount} />
                    <Card>
                      <FormItem name="notes" label="Notes">
                        <TextArea size="small" placeholder="Enter Notes" autoSize={{ minRows: 10 }} />
                      </FormItem>
                    </Card>
                  </Container>
                )}
                <Container jc="space-between" mt={4}>
                  <Button onClick={onClickCancel}>Cancel</Button>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Create
                  </Button>
                </Container>
              </Styled.FieldsWrapper>
            </Col>

            {screens.xl && (
              <Col span={7}>
                <Container fd="column" gap={8}>
                  <Contacts maxContactsCount={maxContactsCount} />
                  <Card>
                    <FormItem name="notes" label="Notes">
                      <TextArea size="small" placeholder="Enter Notes" autoSize={{ minRows: 10 }} />
                    </FormItem>
                  </Card>
                </Container>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    </>
  )
}
