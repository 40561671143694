import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId, getReferenceNumberById } from 'api'
import { QueryKey } from 'enums'

import { referenceNumberByIdJoinParams } from 'modules/references/constants/referenceNumberByIdJoinParams'

export const useGetReferenceNumberById = (id?: EntityId) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { data, ...rest } = useQuery(
    [QueryKey.GetReferenceNumberById, id],
    () => getReferenceNumberById(id, referenceNumberByIdJoinParams),
    {
      onError: () => {
        navigate(pathname, { replace: true })
      },
      enabled: !!id,
    },
  )

  return {
    data: data?.reference,
    ...rest,
  }
}
