import { FC, MouseEvent, useCallback, useState } from 'react'
import { Popover } from 'antd'
import type { PopoverProps } from 'antd/lib/popover'

import { confirmationText } from 'constant/confirmationText'
import { typedMemo } from 'types'

import { Button, ConfirmationButtons, Container, Spacer, Typography } from 'designSystem'

interface ConfirmationRequiredPopoverProps extends Pick<PopoverProps, 'placement'> {
  onConfirm?: () => void
  onCancel?: () => void
  disabled?: boolean
  withPopover?: boolean
}

export const ConfirmationRequiredPopoverBase: FC<ConfirmationRequiredPopoverProps> = ({
  withPopover,
  onConfirm,
  onCancel,
  disabled,
  placement = 'bottomRight',
}) => {
  const [open, setOpen] = useState(false)

  const handleConfirm = useCallback(() => {
    if (withPopover) {
      setOpen(true)
    } else {
      onConfirm?.()
    }
  }, [withPopover, onConfirm])

  const handleCancel = useCallback(() => {
    if (withPopover) {
      setOpen(false)
    }
    onCancel?.()
  }, [withPopover, onCancel])

  const handlePopoverConfirm = useCallback(() => {
    onConfirm?.()
    setOpen(false)
  }, [onConfirm])

  const onClickContainer = useCallback((event: MouseEvent) => {
    event.preventDefault()
  }, [])

  return (
    <>
      <Popover
        open={open}
        placement={placement}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        content={
          <Container pa={4} display="block" onMouseDown={onClickContainer}>
            <Typography fontWeight="xl">Confirmation Required</Typography>
            <Spacer mb={2} />
            <Typography size="xs" color="secondary">
              {confirmationText.confirmationRequired}
            </Typography>

            <Container mt={4} display="flex" ai="center" jc="space-between">
              <Button size="small" onMouseDown={handleCancel}>
                Cancel
              </Button>
              <Button size="small" type="primary" onMouseDown={handlePopoverConfirm} disabled={disabled}>
                Yes, Save
              </Button>
            </Container>
          </Container>
        }
        trigger="click"
        arrow={false}
      />
      <ConfirmationButtons onConfirm={handleConfirm} onCancel={handleCancel} disabled={disabled} />
    </>
  )
}

export const ConfirmationRequiredPopover = typedMemo(ConfirmationRequiredPopoverBase)
