import { FC, useCallback } from 'react'

import { useFormContext } from 'hooks'
import { confirmationText } from 'constant'
import { PurchaseOrderFormAccessor, PurchaseOrderSubLineAccessor } from 'types'

import { ConfirmationModal } from 'components'

interface ReferenceNumberConfirmationModalProps {
  subLineFieldName: string
  editFieldName?: PurchaseOrderFormAccessor
  desiredReferenceNumber?: string | number | null
  setDesiredReferenceNumber: (value?: string | number | null) => void
}

export const ReferenceNumberConfirmationModal: FC<ReferenceNumberConfirmationModalProps> = ({
  desiredReferenceNumber,
  setDesiredReferenceNumber,
  subLineFieldName,
  editFieldName,
}) => {
  const { triggerSubmit, setValue } = useFormContext()

  const handleCloseModal = useCallback(() => {
    setDesiredReferenceNumber(undefined)
  }, [setDesiredReferenceNumber])

  const handleConfirmFromModal = useCallback(() => {
    if (!desiredReferenceNumber) {
      setValue(`${subLineFieldName}.${PurchaseOrderSubLineAccessor.ReferenceType}`, null)
    }

    setValue(`${subLineFieldName}.asset.${editFieldName}`, desiredReferenceNumber)

    triggerSubmit?.()
    handleCloseModal()
  }, [setValue, desiredReferenceNumber, triggerSubmit, subLineFieldName, handleCloseModal, editFieldName])

  return (
    <ConfirmationModal
      destroyOnClose
      open={desiredReferenceNumber !== undefined}
      onCancel={handleCloseModal}
      onOk={handleConfirmFromModal}
      title="Confirmation Required"
      text={confirmationText.confirmationRequired}
    />
  )
}
