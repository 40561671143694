import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { VendorCarrierSettingsEquipment } from 'api'

import { getPatchPayload } from '../utils/getPatchPayload'
import { EquipmentFormPayload } from '../types/equipmentCreateForm'

import { useDeleteEquipment } from './useDeleteEquipment'
import { usePatchEquipment } from './usePatchEquipment'
import { usePostEquipment } from './usePostEquipment'

export const useEquipmentRequests = (
  setIsAddNew: (isAddNew: boolean) => void,
  data: VendorCarrierSettingsEquipment[],
) => {
  const { id: vendorId } = useParams()

  const {
    post,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isLoading: isPostLoading,
  } = usePostEquipment(Number(vendorId))

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isLoading: isPatchLoading,
  } = usePatchEquipment(Number(vendorId))

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeleteEquipment(Number(vendorId))

  const onSubmitPost = useCallback(
    (payload: EquipmentFormPayload) => {
      if (payload.item) {
        post(payload.item)
        setIsAddNew(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: EquipmentFormPayload) => {
      const patchData = getPatchPayload(payload, data)
      patchData?.forEach(({ id, payload }) => {
        patch(id as number, payload)
      })
    },
    [patch, data],
  )

  const onDelete = useCallback(
    (equipmentId?: number) => {
      if (equipmentId) {
        remove(equipmentId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPostSuccess || isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteError,
    isEditLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
  }
}
