import { FC, useCallback, useState } from 'react'

import { purchaseOrderReferenceTypeOptions } from 'constant'
import { PurchaseOrderSubLineAccessor } from 'types'

import { Container, EditFormItem } from 'designSystem'
import { FormItemEditSelectSingle } from 'components'

import { ReferenceTypeConfirmationModal } from './elements/ReferenceTypeConfirmationModal'

interface ReferenceTypeProps {
  rowSubLineFieldIndexName: string
  isFieldDisabled?: boolean
}

export const ReferenceType: FC<ReferenceTypeProps> = ({ rowSubLineFieldIndexName, isFieldDisabled }) => {
  const [desiredReferenceType, setDesiredReferenceType] = useState<string | number | null | undefined>(undefined)

  const handleChangeType = useCallback((value: string | number | null | undefined) => {
    setDesiredReferenceType(value)
  }, [])
  return (
    <Container maxWidth={180} jc="flex-start">
      <ReferenceTypeConfirmationModal
        rowSubLineFieldIndexName={rowSubLineFieldIndexName}
        desiredReferenceType={desiredReferenceType}
        setDesiredReferenceType={setDesiredReferenceType}
      />
      <EditFormItem
        name={`${rowSubLineFieldIndexName}.asset.${PurchaseOrderSubLineAccessor.ReferenceType}`}
        render={({ field }) => (
          <FormItemEditSelectSingle
            {...field}
            size="small"
            disableOnChange
            options={purchaseOrderReferenceTypeOptions}
            onSubmit={handleChangeType}
            disabled={isFieldDisabled}
          />
        )}
      />
    </Container>
  )
}
