import { FC, useCallback } from 'react'

import { VendorDepotSettings } from 'api'
import { useForm, useLocationCodes } from 'hooks'
import { currency, flatFeeOptions, route } from 'constant'

import { Checkbox, Container, Divider, EditFormItem, FormItem, Spacer, Typography } from 'designSystem'
import {
  FormItemEditAddress,
  FormItemEditEmailGroup,
  FormItemEditInput,
  FormItemEditInputNumber,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  StripLoader,
} from 'components'

import { useUpdateVendorDepot } from './hooks/useUpdateVendorDepot'
import { depotMaxEmailCount } from './constants/depotMaxEmailCount'
import { validationSchema } from './constants/validationSchema'
import { DepotFormSchema } from './types/depotForm'
import { DepotPayload } from './types/depotPayload'
import { StorageFeeTitle } from 'modules/vendors/elements/StorageFeeTitle'

interface DepotFormProps {
  vendorId: number
  data: VendorDepotSettings
}

export const DepotForm: FC<DepotFormProps> = ({ vendorId, data }) => {
  const { update, isLoading, isError } = useUpdateVendorDepot(vendorId)
  const { locationCode, ...restData } = data

  const onSubmit = useCallback(
    (payload: DepotPayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit } = useForm<DepotFormSchema>({
    onSubmit,
    mode: 'onChange',
    validationSchema,
    defaultValues: restData,
    isSubmitError: isError,
    viewOnlyFields: ['locationCodeId'],
  })

  return (
    <Form>
      <StripLoader isLoading={isLoading} />
      <Container gap={4} fd="column">
        <FormItemEditSelectSingleAsync
          name="locationCodeId"
          label="Related Location Code"
          onSubmit={triggerSubmit}
          defaultOption={
            locationCode && {
              value: locationCode.id,
              label: locationCode.code,
            }
          }
          getItems={useLocationCodes}
          href={route.locationCodes}
        />
        <EditFormItem
          name="code"
          label="Depot Code"
          render={({ field }) => <FormItemEditInput placeholder="Depot Code" {...field} onBlur={triggerSubmit} />}
        />
        <EditFormItem
          name="accountNumber"
          label="Account Number"
          render={({ field }) => (
            <FormItemEditInput type="digits" placeholder="Account Number" {...field} onBlur={triggerSubmit} />
          )}
        />
        <EditFormItem
          name="phoneNumber"
          label="Depot Phone Number"
          render={({ field }) => (
            <FormItemEditInput placeholder="Type phone number" {...field} onBlur={triggerSubmit} type="phone" />
          )}
        />
        <FormItemEditAddress onConfirm={triggerSubmit} label="Depot Address" />
        <Divider my={3} />
        <Container jc="space-between">
          <FormItem name="acceptTransload">
            <Checkbox onChange={triggerSubmit}>
              <Typography size="s">Accept Transload</Typography>
            </Checkbox>
          </FormItem>
          <FormItem name="availableInCalculator">
            <Checkbox onChange={triggerSubmit}>
              <Typography size="s">Available in Calculator</Typography>
            </Checkbox>
          </FormItem>
        </Container>
        <Divider my={3} />
        <Container fd="column">
          <StorageFeeTitle />
        </Container>
        <Spacer mb={3} />
        <EditFormItem
          name="gateInFee"
          label="Gate In Fee"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Gate In Fee"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <EditFormItem
          name="liftFee"
          label="Lift Fee"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Lift Fee"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <EditFormItem
          name="flatFee"
          label="Flat Fee"
          render={({ field }) => (
            <FormItemEditSelectSingle {...field} allowClear options={flatFeeOptions} onSubmit={triggerSubmit} />
          )}
        />
        <EditFormItem
          name="dailyRatePerTEU"
          label="Daily Rate per TEU"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Daily Rate per TEU"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <EditFormItem
          name="dailyRatePerX2TEU"
          label="Daily Rate per x2 TEU"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Daily Rate per x2 TEU"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <EditFormItem
          name="laborRatePerHour"
          label="Labor Rate per Hour"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Labor Rate per Hour"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <Divider my={3} />
        <Typography fontWeight="xl" size="m">
          Calculator Fee
        </Typography>
        <EditFormItem
          name="tiltBetPerMile"
          label="Tilt bet per mile"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Tilt bet per mile"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <EditFormItem
          name="flatBetPerMile"
          label="Flat bet per mile"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Flat bet per mile"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <EditFormItem
          name="minimumDeliveryFee"
          label="Minimum delivery fee"
          render={({ field }) => (
            <FormItemEditInputNumber
              placeholder="Minimum delivery fee"
              {...field}
              onBlur={triggerSubmit}
              precision={2}
              suffix={currency}
            />
          )}
        />
        <Divider my={3} />
        <FormItemEditEmailGroup title="Primary" name="primaryEmails" maxFields={depotMaxEmailCount} />
        <Divider my={3} />
        <FormItemEditEmailGroup title="Redelivery emails" name="redeliveryEmails" maxFields={depotMaxEmailCount} />
        <Divider my={3} />
        <FormItemEditEmailGroup title="Booking emails" name="bookingEmails" maxFields={depotMaxEmailCount} />
        <Divider my={3} />
        <FormItemEditEmailGroup title="Secondary emails" name="secondaryEmails" maxFields={depotMaxEmailCount} />
      </Container>
    </Form>
  )
}
