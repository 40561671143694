import type { UserTableSettings } from 'types'
import { AppModule } from 'types'

import { assetBookingReferencesDefaultColumns } from 'modules/assets/routes/Asset/elements/AssetReferenceNumbers/constants/assetBookingReferencesVisibleColumns'
import { assetRedeliveryReferencesDefaultColumns } from 'modules/assets/routes/Asset/elements/AssetReferenceNumbers/constants/assetRedeliveryReferencesVisibleColumns'
import { assetsDefaultColumns } from 'modules/assets/routes/AssetsList/constants/assetsVisibleColumns'
import { customersDefaultColumns } from 'modules/customers/routes/CustomersList/constants/customersVisibleColumns'
import { globalInventoryVendorDefaultColumns } from 'modules/globalInventory/routes/GlobalInventoryVendor/constants/globalInventoryVendorVisibleColumns'
import { itemsDefaultColumns } from 'modules/items/routes/ItemsList/constants/itemsVisibleColumns'
import { purchaseOrderItemsDefaultColumns } from 'modules/purchaseOrders/routes/PurchaseOrder/elements/PurchaseOrderTabs/elements/PurchaseOrderDetails/elements/PurchaseOrderDetailsItems/constants/purchaseOrderItemsVisibleColumns'
import { purchaseOrderSubItemsDefaultColumns } from 'modules/purchaseOrders/routes/PurchaseOrder/elements/PurchaseOrderTabs/elements/PurchaseOrderDetails/elements/PurchaseOrderDetailsItems/constants/purchaseOrderSubItemsVisibleColumns'
import { purchaseOrdersDefaultColumns } from 'modules/purchaseOrders/routes/PurchaseOrdersList/constants/purchaseOrdersVisibleColumns'
import { referencesDefaultColumns } from 'modules/references/routes/ReferencesList/constants/referenceVisibleColumns'
import { inventoryDefaultColumns } from 'modules/vendors/routes/Vendor/elements/Inventory/constants/inventoryVisibleColumns'
import { equipmentDefaultColumns } from 'modules/vendors/routes/Vendor/routes/Carrier/routes/CarrierEquipment/constants/equipmentVisibleColumns'
import { modificationsDefaultColumns } from 'modules/vendors/routes/Vendor/routes/Modifications/elements/ModificationTable/constants/modificationsVisibleColumns'
import { vendorPurchaseOrdersDefaultColumns } from 'modules/vendors/routes/Vendor/routes/VendorPurchaseOrders/constants/vendorPurchaseOrdersVisibleColumns'
import { vendorsDefaultColumns } from 'modules/vendors/routes/VendorsList/constants/vendorsVisibleColumns'

export const defaultUserTablesSettings: UserTableSettings[] = [
  {
    module: AppModule.Vendors,
    filters: {},
    selectedFilters: [],
    columns: vendorsDefaultColumns,
  },
  {
    module: AppModule.VendorModifications,
    filters: {},
    selectedFilters: [],
    columns: modificationsDefaultColumns,
  },
  {
    module: AppModule.VendorContainerInventory,
    filters: {},
    selectedFilters: [],
    columns: inventoryDefaultColumns,
  },
  {
    module: AppModule.VendorPartsInventory,
    filters: {},
    selectedFilters: [],
    columns: inventoryDefaultColumns,
  },
  {
    module: AppModule.VendorCarrierEquipment,
    filters: {},
    selectedFilters: [],
    columns: equipmentDefaultColumns,
  },
  {
    module: AppModule.Assets,
    filters: {},
    selectedFilters: [],
    columns: assetsDefaultColumns,
  },
  {
    module: AppModule.GlobalInventoryMatrix,
    filters: {},
    selectedFilters: [],
    columns: [],
  },
  {
    module: AppModule.GlobalInventoryVendors,
    filters: {},
    selectedFilters: [],
    columns: globalInventoryVendorDefaultColumns,
  },
  {
    module: AppModule.Items,
    filters: {},
    selectedFilters: [],
    columns: itemsDefaultColumns,
  },
  {
    module: AppModule.PurchaseOrders,
    filters: {},
    selectedFilters: [],
    columns: purchaseOrdersDefaultColumns,
  },
  {
    module: AppModule.Customers,
    filters: {},
    selectedFilters: [],
    columns: customersDefaultColumns,
  },
  {
    module: AppModule.References,
    filters: {},
    selectedFilters: [],
    columns: referencesDefaultColumns,
  },
  {
    module: AppModule.PurchaseOrderItems,
    filters: {},
    selectedFilters: [],
    columns: purchaseOrderItemsDefaultColumns,
  },
  {
    module: AppModule.PurchaseOrderRedeliverySubLines,
    filters: {},
    selectedFilters: [],
    columns: purchaseOrderSubItemsDefaultColumns,
  },
  {
    module: AppModule.PurchaseOrderVendorReleaseSubLines,
    filters: {},
    selectedFilters: [],
    columns: purchaseOrderSubItemsDefaultColumns,
  },
  {
    module: AppModule.VendorPurchaseOrders,
    filters: {},
    selectedFilters: [],
    columns: vendorPurchaseOrdersDefaultColumns,
  },
  {
    module: AppModule.AssetCodes,
    filters: {},
    selectedFilters: [],
    columns: [],
  },
  {
    module: AppModule.AccessManagement,
    filters: {},
    selectedFilters: [],
    columns: [],
  },
  {
    module: AppModule.AssetBookingNumbers,
    filters: {},
    selectedFilters: [],
    columns: assetBookingReferencesDefaultColumns,
  },
  {
    module: AppModule.AssetRedeliveryNumbers,
    filters: {},
    selectedFilters: [],
    columns: assetRedeliveryReferencesDefaultColumns,
  },
]
