import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveAssetCode } from '../../hooks/useRemoveAssetCode'

export const ActionButtons: FC = () => {
  const { id } = useParams()
  const { remove, isLoading } = useRemoveAssetCode(Number(id))

  const handleRemoveAssetCode = useCallback(() => {
    remove()
  }, [remove])

  return (
    <ConfirmationPopover
      text="Are you sure you want to remove asset code?"
      onOk={handleRemoveAssetCode}
      isLoading={isLoading}
    >
      <Button icon="delete" danger size="small" />
    </ConfirmationPopover>
  )
}
