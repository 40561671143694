import { ActivityStreamlineType } from 'api'

export const getActivityStreamLineEntity = (type: ActivityStreamlineType) => {
  return {
    [ActivityStreamlineType.PurchaseOrderEntity]: 'PO',
    [ActivityStreamlineType.PurchaseOrderItemEntity]: 'PO Item line',
    [ActivityStreamlineType.PurchaseOrderSubLineEntity]: 'PO Asset line',
    [ActivityStreamlineType.PurchaseOrderContactEntity]: 'PO Contact',
  }[type]
}
