import { Link } from 'react-router-dom'

import { Asset, AssetNumberType, Item, LocationCode } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { assetNumberTypeLabel, getAssetConditionLabel, getAssetHolderLabel, route } from 'constant'

import { Typography } from 'designSystem'
import { AssetStatusTag, RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<Asset> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAccessorTitle(Accessor.AssetNumber),
    key: Accessor.AssetNumber,
    dataIndex: Accessor.AssetNumber,
    width: 400,
    sorter: true,
    sortOrder: getFieldSorter('assetNumber'),
    ellipsis: true,
    render: (assetNumber: string, record) => {
      return (
        <Link to={`${route.assets}/${record.id}`}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {assetNumber}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Status),
    key: Accessor.Status,
    dataIndex: Accessor.Status,
    width: 200,
    ellipsis: true,
    render: (status) => (status ? <AssetStatusTag status={status} /> : '-'),
  },
  {
    title: getAccessorTitle(Accessor.LocationCode),
    key: Accessor.LocationCode,
    dataIndex: Accessor.LocationCode,
    width: 150,
    ellipsis: true,
    render: (locationCode: LocationCode) => (
      <RelatedLinksList entity="locationCodes" data={{ id: locationCode?.id, title: locationCode?.code }} />
    ),
  },
  {
    title: getAccessorTitle(Accessor.AssetHolder),
    key: Accessor.AssetHolder,
    dataIndex: Accessor.AssetHolder,
    width: 150,
    ellipsis: true,
    render: (assetHolder) => getAssetHolderLabel(assetHolder),
  },
  {
    title: getAccessorTitle(Accessor.AssetNumberType),
    key: Accessor.AssetNumberType,
    dataIndex: Accessor.AssetNumberType,
    width: 150,
    ellipsis: true,
    render: (assetNumberType: AssetNumberType) => assetNumberTypeLabel[assetNumberType],
  },
  {
    title: getAccessorTitle(Accessor.BirthmarkNumber),
    key: Accessor.BirthmarkNumber,
    dataIndex: Accessor.BirthmarkNumber,
    width: 250,
    ellipsis: true,
  },
  {
    title: getAccessorTitle(Accessor.BirthmarkSize),
    key: Accessor.BirthmarkSize,
    dataIndex: Accessor.BirthmarkSize,
    width: 250,
    ellipsis: true,
  },
  {
    title: getAccessorTitle(Accessor.Condition),
    key: Accessor.Condition,
    dataIndex: Accessor.Condition,
    width: 120,
    ellipsis: true,
    render: (condition) => getAssetConditionLabel(condition),
  },
  {
    title: getAccessorTitle(Accessor.ItemSku),
    key: Accessor.ItemSku,
    dataIndex: Accessor.ItemSku,
    width: 180,
    ellipsis: true,
    render: (items: Item[]) => {
      const data = items.map(({ id, sku }) => ({
        id,
        title: sku,
      }))
      return <RelatedLinksList entity="items" data={data} />
    },
  },
]
