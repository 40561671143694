import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { PurchaseOrderDepartment } from 'api'
import { useForm } from 'hooks'
import { purchaseOrderTypeOptions, route } from 'constant'
import { useClearPurchaseOrderStore } from 'store'

import { Button, Container, Spacer, Typography } from 'designSystem'
import { Breadcrumbs, Card, NewContact } from 'components'

import { useCreatePurchaseOrder } from './hooks/useCreatePurchaseOrder'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { validationSchema } from './constants/validationSchema'
import { PurchaseOrderCreateForm, PurchaseOrderFormData } from './types/purchaseOrderCreateForm'
import { PurchaseOrderAddContact } from './elements/PurchaseOrderAddContact'
import { PurchaseOrderForm } from './elements/PurchaseOrderForm'
import { PurchaseOrderItems } from './elements/PurchaseOrderItems'

export const PurchaseOrderCreate: FC = () => {
  const [contacts, setContacts] = useState<NewContact[]>([])
  const clearPurchaseOrderStore = useClearPurchaseOrderStore()

  const { Form, handleSubmit } = useForm<PurchaseOrderCreateForm>({
    validationSchema,
    mode: 'onChange',
    defaultValues: {
      isEmailRequired: false,
      department: PurchaseOrderDepartment.Procurement,
      type: purchaseOrderTypeOptions[0].value,
    },
  })

  const { create, isLoading } = useCreatePurchaseOrder()

  const onSubmit = useCallback(
    (data: PurchaseOrderFormData) => {
      create({ ...data, contacts })
    },
    [create, contacts],
  )

  useEffect(() => {
    return () => {
      clearPurchaseOrderStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container ml={4} mr={4} mt={6} ai="center" jc="space-between" minHeight={32}>
        <Typography fontWeight="xl" size="l">
          Create PO
        </Typography>
        <PurchaseOrderAddContact contacts={contacts} setContacts={setContacts} />
      </Container>

      <Container fd="column" ai="center" jc="center" my={4}>
        <Card fullwidth>
          <PurchaseOrderForm contacts={contacts} setContacts={setContacts} />
          <Spacer mb={11} />
          <PurchaseOrderItems />
        </Card>
        <Container mt={5} jc="space-between" width="100%" px={4}>
          <Link to={route.pos}>
            <Button px={13} type="default">
              Cancel
            </Button>
          </Link>
          <Button px={13} type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Container>
      </Container>
    </Form>
  )
}
