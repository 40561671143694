import { useQuery } from '@tanstack/react-query'

import { EntityId, getVendorById } from 'api'
import { QueryKey } from 'enums'
interface UseGetVendorByIdProps {
  id?: EntityId
  onError?: () => void
}

export const useGetVendorById = ({ id, onError }: UseGetVendorByIdProps) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetVendorById, id],
    () => getVendorById(id),
    {
      enabled: !!id,
      onError,
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
