import { useQuery } from '@tanstack/react-query'

import type { ActivityStreamline } from 'api'
import { getActivityStreamline } from 'api'
import { QueryKey } from 'enums'

import { useTable } from './useTable'

export const useActivityStreamline = (filters?: string) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...props } =
    useTable<ActivityStreamline>({})
  const params = {
    join: ['performedBy'],
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: (searchQueryParams || '') + (filters || ''),
  }

  const { data, isLoading, isFetching } = useQuery(
    [QueryKey.GetActivityStreamline, params],
    () => getActivityStreamline(params),
    {
      keepPreviousData: true,
    },
  )

  const { data: activityStreamlineData, total } = data || {}

  return {
    data: activityStreamlineData || [],
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...props,
  }
}
