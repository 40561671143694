export enum ActivityStreamlineType {
  PurchaseOrderContactEntity = 'PURCHASE_ORDER_CONTACT_ENTITY',
  PurchaseOrderSubLineEntity = 'PURCHASE_ORDER_SUB_LINE_ENTITY',
  PurchaseOrderItemEntity = 'PURCHASE_ORDER_ITEM_ENTITY',
  PurchaseOrderEntity = 'PURCHASE_ORDER_ENTITY',
}
// RoleEntity = 'ROLE_ENTITY',
// UserEntity = 'USER_ENTITY',
// CompanyEntity = 'COMPANY_ENTITY',
// ItemCategoryEntity = 'ITEM_CATEGORY_ENTITY',
// CustomerAddressEntity = 'CUSTOMER_ADDRESS_ENTITY',
// BillingAddressEntity = 'BILLING_ADDRESS_ENTITY',
// CustomerContactEntity = 'CUSTOMER_CONTACT_ENTITY',
// ShippingAddressEntity = 'SHIPPING_ADDRESS_ENTITY',
// UccAddressEntity = 'UCC_ADDRESS_ENTITY',
// CustomerEntity = 'CUSTOMER_ENTITY',
// SessionEntity = 'SESSION_ENTITY',
// VendorCarrierPriceListEntity = 'VENDOR_CARRIER_PRICE_LIST_ENTITY',
// VendorCarrierSettingsLocationEntity = 'VENDOR_CARRIER_SETTINGS_LOCATION_ENTITY',
// VendorContactEntity = 'VENDOR_CONTACT_ENTITY',
// VendorResalePriceOverwriteRuleEntity = 'VENDOR_RESALE_PRICE_OVERWRITE_RULE_ENTITY',
// VendorContainerSupplierSettingsEntity = 'VENDOR_CONTAINER_SUPPLIER_SETTINGS_ENTITY',
// VendorModificationsSettingsEntity = 'VENDOR_MODIFICATIONS_SETTINGS_ENTITY',
// VendorModificationsEntity = 'VENDOR_MODIFICATION_ENTITY',
// VendorPartsResalePriceOverwriteRuleEntity = 'VENDOR_PARTS_RESALE_PRICE_OVERWRITE_RULE_ENTITY',
// VendorPartsEntity = 'VENDOR_PARTS_ENTITY',
// VendorPartsInventoryEntity = 'VENDOR_PARTS_INVENTORY_ENTITY',
// VendorSkuMappingEntity = 'VENDOR_SKU_MAPPING_ENTITY',
// VendorContainerSupplierInventoryEntity = 'VENDOR_CONTAINER_SUPPLIER_INVENTORY_ENTITY',
// VendorDepotSettingsEntity = 'VENDOR_DEPOT_SETTINGS_ENTITY',
// TruckCodeEntity = 'TRUCK_CODE_ENTITY',
// VendorCarrierEquipmentEntity = 'VENDOR_CARRIER_EQUIPMENT_ENTITY',
// VendorCarrierSettingsEntity = 'VENDOR_CARRIER_SETTINGS_ENTITY',
// VendorEntity = 'VENDOR_ENTITY',
// UserSettingsEntity = 'USER_SETTINGS_ENTITY',
// ReferenceContactEntity = 'REFERENCE_CONTACT_ENTITY',
// ReferenceItemEntity = 'REFERENCE_ITEM_ENTITY',
// ReferenceItemSubLineEntity = 'REFERENCE_ITEM_SUB_LINE_ENTITY',
// ReferenceEntity = 'REFERENCE_ENTITY',
// WarehouseEntity = 'WAREHOUSE_ENTITY',
// LocationCodeEntity = 'LOCATION_CODE_ENTITY',
// ItemEntity = 'ITEM_ENTITY',
// AssetEntity = 'ASSET_ENTITY',
// AssetCodeEntity = 'ASSET_CODE_ENTITY',
// ActivityStreamlineEntity = 'ACTIVITY_STREAMLINE_ENTITY',
// TemplatesMapperEntity = 'TEMPLATES_MAPPER_ENTITY',
// TableFilterEntity = 'TABLE_FILTER_ENTITY',
// VendorsLocationCodes = 'VENDORS_LOCATION_CODES',
// UsersToCompanies = 'USERS_TO_COMPANIES',
// UsersToRoles = 'USERS_TO_ROLES',
// FavoriteVendors = 'FAVORITE_VENDORS',
// ReferencesToAssets = 'REFERENCES_TO_ASSETS',
// ItemsToItems = 'ITEMS_TO_ITEMS',
// ItemsToAssets = 'ITEMS_TO_ASSETS',
// ItemCategoriesClosure = 'ITEM_CATEGORIES_CLOSURE',
// MailService = 'MAIL_SERVICE',
