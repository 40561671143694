import { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { UserStatus } from 'api'
import { useForm } from 'hooks'
import { getRolesOptions } from 'utils'
import { useRoles } from 'store'

import { Container, Divider, EditFormItem, Typography } from 'designSystem'
import {
  Avatar,
  Breadcrumbs,
  FormItemEditInput,
  FormItemEditSelectMulti,
  ResendInvite,
  Spinner,
  StripLoader,
  UserStatusTag,
} from 'components'

import { useGetUserById } from './hooks/useGetUserById'
import { useUpdateUser } from './hooks/useUpdateUser'
import { getBreadcrumbsData } from './utils/getBreadcrumbsData'
import { validationSchema } from './utils/validationSchema'
import { UpdateUserForm } from './types/updateUserForm'
import { ActionButtons } from './elements/ActionButtons/ActionButtons'
import * as Styled from './styles'

import { UpdateUserDataPayload } from './queryApi'

export const UserView: FC = () => {
  const { userId } = useParams()
  const roles = useRoles()
  const { data: userData, isFetching } = useGetUserById(Number(userId))
  const { updateUser, isError, isLoading } = useUpdateUser(Number(userId))

  const onSubmit = useCallback(
    (data: UpdateUserDataPayload) => {
      updateUser(data)
    },
    [updateUser],
  )

  const { Form, triggerSubmit } = useForm<UpdateUserForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: useMemo(
      () => ({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        phoneNumber: userData?.phoneNumber,
        phoneExtension: userData?.phoneExtension,
        email: userData?.email,
        rolesIds: userData?.roles.map((role) => role.id),
      }),
      [userData],
    ),
  })

  const rolesOptions = getRolesOptions(roles)

  if (isFetching) {
    return <Spinner />
  }

  const breadcrumbItem = {
    title: `${userData?.firstName} ${userData?.lastName} details`,
  }

  return (
    <>
      {userData && (
        <>
          <Breadcrumbs breadcrumbItems={getBreadcrumbsData(breadcrumbItem)} actions={<ActionButtons />} />
          <Container pa={4}>
            <Styled.Wrapper>
              <StripLoader isLoading={isLoading} />
              <Styled.Header>
                <Typography fontWeight="l" color="secondary" size="xs">
                  ID: {userData.id}
                </Typography>
                <Styled.AvatarWrapper>
                  <Avatar user={userData} size={59} />
                </Styled.AvatarWrapper>
              </Styled.Header>
              <Styled.Content>
                <Form>
                  <Container fd="column" gap="4">
                    <EditFormItem
                      name="firstName"
                      label="First Name"
                      render={({ field }) => (
                        <FormItemEditInput placeholder="Type First Name" {...field} onBlur={triggerSubmit} />
                      )}
                    />

                    <EditFormItem
                      name="lastName"
                      label="Last Name"
                      render={({ field }) => (
                        <FormItemEditInput placeholder="Type Last Name" {...field} onBlur={triggerSubmit} />
                      )}
                    />

                    <EditFormItem
                      name="email"
                      label="Email"
                      render={({ field }) => (
                        <FormItemEditInput placeholder="Type email" {...field} onBlur={triggerSubmit} />
                      )}
                    />

                    <EditFormItem
                      name="phoneNumber"
                      label="Phone Number"
                      render={({ field }) => (
                        <FormItemEditInput
                          placeholder="Type phone number"
                          {...field}
                          onBlur={triggerSubmit}
                          type="phone"
                        />
                      )}
                    />

                    <EditFormItem
                      name="phoneExtension"
                      label="Extension"
                      render={({ field }) => (
                        <FormItemEditInput placeholder="Type extension" {...field} onBlur={triggerSubmit} />
                      )}
                    />
                  </Container>
                  <Divider my={4} />
                  <EditFormItem
                    name="rolesIds"
                    label="Role"
                    layout="horizontal"
                    render={({ field }) => (
                      <FormItemEditSelectMulti {...field} width={200} options={rolesOptions} onBlur={triggerSubmit} />
                    )}
                  />
                  <Divider my={4} />
                  <Container jc="space-between">
                    <Typography color="secondary" fontWeight="l">
                      Status
                    </Typography>
                    <Container ai="center" gap={4}>
                      <UserStatusTag userStatus={userData.status} />
                      {userData.status === UserStatus.Invited && <ResendInvite id={userData.id} />}
                    </Container>
                  </Container>
                </Form>
              </Styled.Content>
            </Styled.Wrapper>
          </Container>
        </>
      )}
    </>
  )
}
