import { assetCodeGradeOptions, assetCodeHeightOptions, assetCodeTypeOptions } from 'constant'

import { TableFilterSelectMulti } from 'components'

export const assetCodesFiltersOptions = [
  {
    value: 'grade',
    label: 'Grade',
    component: <TableFilterSelectMulti key="grade" name="grade" placeholder="Grade" options={assetCodeGradeOptions} />,
  },
  {
    value: 'type',
    label: 'Type',
    component: <TableFilterSelectMulti key="type" name="type" placeholder="Type" options={assetCodeTypeOptions} />,
  },
  {
    value: 'height',
    label: 'Height',
    component: (
      <TableFilterSelectMulti key="height" name="height" placeholder="Height" options={assetCodeHeightOptions} />
    ),
  },
]
