import { ReferenceNumber } from 'api'
import { QueryKey } from 'enums'
import { AppModule } from 'types'

import { TableFilter, useTable } from '../useTable'
import { useGetReferenceNumbers } from './useGetReferenceNumbers'

interface UseReferencesTableProps {
  queryKey: QueryKey
  module?: AppModule
  join?: string[]
  defaultFilters?: TableFilter
}

export const useReferencesTable = ({ queryKey, join, ...props }: UseReferencesTableProps) => {
  const { pagination, onChange, getFieldSorter, sortQueryParams, searchQueryParams, ...useTableProps } =
    useTable<ReferenceNumber>({
      ...props,
    })

  const { data, total, isLoading, isFetching } = useGetReferenceNumbers(queryKey, {
    limit: pagination.limit,
    page: pagination.page,
    sort: sortQueryParams,
    s: searchQueryParams,
    join: join || [
      'items',
      'items.item||id,sku',
      'items.subLines',
      'items.subLines.asset||id,assetNumber',
      'locationCode||id,code',
      'depotSetting||id,code,vendorId',
      'vendorRelease||id,number',
      'bookingNumber||id,number',
      'bookingNumbers||id,number',
      'redeliveryNumbers||id,number',
    ],
  })

  const list: ReferenceNumber[] = (data || []).map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return {
    list,
    pagination: {
      ...pagination,
      current: pagination.page,
      pageSize: pagination.limit,
      total,
    },
    getFieldSorter,
    onTableChange: onChange,
    isLoading: isLoading || isFetching,
    offsetCount: (pagination.page - 1) * pagination.limit,
    ...useTableProps,
  }
}
