import { FC, useCallback } from 'react'

import { ReferenceNumberType } from 'api'
import { useFormContext } from 'hooks'
import { confirmationText } from 'constant'
import { PurchaseOrderFormAccessor, PurchaseOrderSubLineAccessor } from 'types'

import { ConfirmationModal } from 'components'

interface ReferenceTypeConfirmationModalProps {
  rowSubLineFieldIndexName: string
  setDesiredReferenceType: (value: string | number | null | undefined) => void
  desiredReferenceType: string | number | null | undefined
}

export const ReferenceTypeConfirmationModal: FC<ReferenceTypeConfirmationModalProps> = ({
  setDesiredReferenceType,
  rowSubLineFieldIndexName,
  desiredReferenceType,
}) => {
  const { triggerSubmit, setValue } = useFormContext()

  const handleCloseModal = useCallback(() => {
    setDesiredReferenceType(undefined)
  }, [setDesiredReferenceType])

  const isVendorRelease = desiredReferenceType === ReferenceNumberType.VendorRelease

  const fieldName = `${rowSubLineFieldIndexName}.asset.${
    isVendorRelease ? PurchaseOrderFormAccessor.RedeliveryRefId : PurchaseOrderFormAccessor.VendorReleaseRefId
  }`
  const handleConfirmFromModal = useCallback(() => {
    setValue(fieldName, null)
    setValue(`${rowSubLineFieldIndexName}.${PurchaseOrderSubLineAccessor.ReferenceType}`, desiredReferenceType)
    triggerSubmit?.()
    handleCloseModal()
  }, [fieldName, rowSubLineFieldIndexName, setValue, triggerSubmit, handleCloseModal, desiredReferenceType])

  return (
    <ConfirmationModal
      destroyOnClose
      open={desiredReferenceType !== undefined}
      onCancel={handleCloseModal}
      onOk={handleConfirmFromModal}
      title="Confirmation Required"
      text={confirmationText.confirmationRequired}
    />
  )
}
