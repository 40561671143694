import { useQuery } from '@tanstack/react-query'

import { UserSettingsService } from 'services'
import { QueryKey } from 'enums'

import { getMySettings } from '../queryApi'

export const useGetMySettings = (enabled: boolean) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.UserMeSettings],
    () => getMySettings(),
    {
      enabled,
      onSuccess: (data) => {
        UserSettingsService.setInitialTableSettings(data)
      },
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
