import { FC, useCallback, useEffect, useState } from 'react'

import { VendorType } from 'api'
import { useFormContext } from 'hooks'
import { getVendorTypeOptionLabel, vendorTypeOptions } from 'constant'
import { getSimpleArrayDiff } from 'utils'

import { Button, CheckboxGroup, Container, FormItem, Modal, Typography } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { getCurrentTypeFieldName } from './utils/getCurrentTypeFieldName'
import { CarrierSettings } from '../../../../../../elements/CarrierSettings'
import { ContainerVendorSettings } from '../../../../../../elements/ContainerVendorSettings'
import { DepotSettings } from '../../../../../../elements/DepotSettings'
import { ModificationsSettings } from '../../../../../../elements/ModificationsSettings'
import { PartsSettings } from '../../../../../../elements/PartsSettings'

interface VendorTypeFormItemProps {
  onSubmit: () => void
}

export const VendorTypeFormItem: FC<VendorTypeFormItemProps> = ({ onSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [type, setType] = useState<VendorType | undefined>()
  const {
    setValue,
    trigger,
    formState: { defaultValues, errors },
  } = useFormContext()
  const defaultTypeValues: VendorType[] = defaultValues?.['type']

  const handleChange = useCallback(
    (values: (string | number | boolean)[]) => {
      const diffType = getSimpleArrayDiff(defaultTypeValues, values as string[])[0]
      setType(diffType as VendorType)

      if (diffType && defaultTypeValues.filter((v: string) => !values.includes(v)).length) {
        setConfirmOpen(true)
      } else {
        setModalOpen(true)
        setValue('type', values)
      }
    },
    [defaultTypeValues, setValue],
  )

  const handleClose = useCallback(() => {
    setModalOpen(false)
    setValue('type', defaultTypeValues)
  }, [defaultTypeValues, setValue])

  const handleSubmit = useCallback(() => {
    const currentTypeFieldName = getCurrentTypeFieldName(type)

    trigger(currentTypeFieldName).then(() => {
      const isError = currentTypeFieldName && errors?.[currentTypeFieldName]

      if (!isError && currentTypeFieldName) {
        onSubmit()
        setModalOpen(false)
      }
    })
  }, [onSubmit, trigger, type, errors])

  const handleConfirmClose = useCallback(() => {
    setType(undefined)
    setConfirmOpen(false)
  }, [])

  const handleConfirm = useCallback(() => {
    setValue(
      'type',
      defaultTypeValues.filter((v) => v !== type),
    )

    setType(undefined)
    setConfirmOpen(false)
    onSubmit()
  }, [onSubmit, setValue, defaultTypeValues, type])

  const handleConfirmOpenChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        setConfirmOpen(false)
      }
    },
    [setConfirmOpen],
  )

  useEffect(() => {
    if (!modalOpen) {
      const currentTypeFieldName = getCurrentTypeFieldName(type)

      if (currentTypeFieldName) {
        // Should fire when closed and submit request was called with data
        setTimeout(() => {
          setValue(currentTypeFieldName, undefined)
          setType(undefined)
        }, 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  return (
    <>
      <ConfirmationPopover
        shouldOpen={confirmOpen}
        open={confirmOpen}
        text={
          <>
            <Typography size="xs" as="p">
              Are you sure you want to remove
              <Typography size="xs" fontWeight="xl">
                {` ${getVendorTypeOptionLabel(type as VendorType)} Settings? `}
              </Typography>
            </Typography>
            <Typography size="xs" as="p">
              All associated data with this vendor type will be removed from the system.
            </Typography>
          </>
        }
        onCancel={handleConfirmClose}
        onOpenChange={handleConfirmOpenChange}
        onOk={handleConfirm}
      >
        <FormItem name="type" disableOnChange>
          <CheckboxGroup $gridColumns={2} $gridGap={16} options={vendorTypeOptions} onChange={handleChange} />
        </FormItem>
      </ConfirmationPopover>

      <Modal
        title={type && `${getVendorTypeOptionLabel(type)} Type`}
        open={modalOpen}
        onCancel={handleClose}
        destroyOnClose
        footer={null}
      >
        {type === VendorType.Carrier && <CarrierSettings isEdit />}
        {type === VendorType.Modifications && <ModificationsSettings isEdit />}
        {type === VendorType.ContainerVendor && <ContainerVendorSettings isEdit />}
        {type === VendorType.Depot && <DepotSettings isEdit />}
        {type === VendorType.Parts && <PartsSettings isEdit />}

        <Container mt={8} display="flex" jc="space-between">
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Container>
      </Modal>
    </>
  )
}
