import { FC, useCallback, useEffect } from 'react'

import { Address } from 'api'
import { useForm } from 'hooks'

import { Container, Divider } from 'designSystem'
import { FormItemEditAddressGroup, Spinner } from 'components'

import { useGetCustomerUccAddresses } from './hooks/useGetCustomerUccAddresses'
import { usePatchCustomerUccAddress } from './hooks/usePatchCustomerUccAddress'
import { usePostCustomerUccAddress } from './hooks/usePostCustomerUccAddress'
import { useRemoveCustomerUccAddress } from './hooks/useRemoveCustomerUccAddress'
import { validationSchema } from './constants/validationSchema'
import { maxUccAddressesCount } from 'modules/customers/constants/maxRecordsCount'
import { CustomerUccAddressesForm } from './types/customerUccAddressesForm'

interface CustomerUccAddressesProps {
  customerId: number
  isUcc?: boolean
}

export const CustomerUccAddresses: FC<CustomerUccAddressesProps> = ({ customerId, isUcc }) => {
  const { data: addresses, isLoading } = useGetCustomerUccAddresses(customerId, isUcc)
  const { post, isError: isPostError, isLoading: isPostLoading } = usePostCustomerUccAddress(customerId)
  const { patch, isError: isPatchError, isLoading: isPatchLoading } = usePatchCustomerUccAddress(customerId)
  const { remove, isError: isRemoveError, isLoading: isRemoveLoading } = useRemoveCustomerUccAddress(customerId)

  const { Form, unregister } = useForm<CustomerUccAddressesForm>({
    validationSchema,
    mode: 'onChange',
    isSubmitError: isRemoveError || isPostError || isPatchError,
    isLoading: isRemoveLoading || isPostLoading || isPatchLoading,
    defaultValues: { addresses },
  })

  const onSubmitPost = useCallback(
    (payload: Address) => {
      post(payload)
    },
    [post],
  )

  const onSubmitPatch = useCallback(
    (payload: Address) => {
      patch(payload)
    },
    [patch],
  )

  const onDelete = useCallback(
    (id: number) => {
      remove(id)
    },
    [remove],
  )

  useEffect(() => {
    if (!isUcc) {
      unregister('addresses')
    }
  }, [isUcc, unregister])

  return isUcc ? (
    <Form>
      <Divider my={4} />
      <FormItemEditAddressGroup
        title="UCC Addresses"
        name="addresses"
        maxCount={maxUccAddressesCount}
        onSubmitPost={onSubmitPost}
        onSubmitPatch={onSubmitPatch}
        onDelete={onDelete}
        isLoading={isPostLoading || isPatchLoading}
      />
      {isLoading && (
        <Container ai="center" jc="center" minHeight={200}>
          <Spinner size="large" />
        </Container>
      )}
    </Form>
  ) : null
}
