import { Link } from 'react-router-dom'

import { AssetCode, AssetCodeGrade, AssetCodeType } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { assetCodeGradeOptionLabel, assetCodeTypeOptionsLabel, route } from 'constant'
import { getUnitSuffixText } from 'utils'

import { Typography } from 'designSystem'
import { TableColumnKey, TableColumnsType } from 'components'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<AssetCode> => [
  {
    title: '#',
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: 'Asset Code',
    key: 'code',
    dataIndex: 'code',
    ellipsis: true,
    width: '50%',
    sorter: true,
    sortOrder: getFieldSorter('code'),
    render: (code: string, record) => {
      // do not use optimistic update because it's not fetching items for related sku and don't need it in the list
      return (
        <Link to={`${route.assetCodes}/${record.id}`}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {code}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: 'Grade',
    key: 'grade',
    dataIndex: 'grade',
    width: '10%',
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter('grade'),
    render: (grade: AssetCodeGrade) => assetCodeGradeOptionLabel[grade],
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    width: '10%',
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter('type'),
    render: (type: AssetCodeType) => assetCodeTypeOptionsLabel[type],
  },
  {
    title: getUnitSuffixText('Width'),
    key: 'width',
    dataIndex: 'width',
    width: '10%',
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter('width'),
  },
  {
    title: getUnitSuffixText('Length'),
    key: 'length',
    dataIndex: 'length',
    width: '10%',
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter('length'),
  },
  {
    title: getUnitSuffixText('Height'),
    key: 'height',
    dataIndex: 'height',
    width: '10%',
    ellipsis: true,
    sorter: true,
    sortOrder: getFieldSorter('height'),
  },
]
