import { getAccessorTitle } from '../utils/getAccessorTitle'
import { Accessor } from '../types/accessor'

export const purchaseOrdersRequiredColumns = [Accessor.Index, Accessor.VendorName, Accessor.Status, Accessor.Number]

export const purchaseOrdersDefaultColumns = [
  Accessor.Index,
  Accessor.VendorName,
  Accessor.Status,
  Accessor.ExpectedDate,
  Accessor.Department,
  Accessor.Number,
  Accessor.Type,
  Accessor.LocationDepot,
  Accessor.PaymentTerms,
  Accessor.ExpirationDate,
  Accessor.Qty,
  Accessor.ItemsTotal,
]

export const purchaseOrdersVisibleColumnsOptions = Object.values(Accessor).map((accessor) => ({
  value: accessor,
  label: getAccessorTitle(accessor),
  disabled: purchaseOrdersRequiredColumns.includes(accessor),
}))
