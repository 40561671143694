export const PasswordRegexp = /(?!\S*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/

export const DecimalDigitsRegexp = /[^\d]/g

export const FloatDigitsRegexp = /[^-?\d.,]/g

export const LettersRegexp = /^[a-z]+$/i

export const OnlyNumbersRegexp = /^\d+$/

export const NoWhiteSpacesOnBeginningRegexp = /(^\s+)/gm

export const AllowOneWhiteSpaceRegexp = /  +/g

export const ItemSkuRegexp = /^[A-Z]+(-[A-Z0-9]+)$/

export const TwoLettersAfterLastDotRegex = /^(?!.*\.\d{2,}$).*\.[^.]{2,}$/

export const DateStringRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d{1,3}/
