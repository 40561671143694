import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { GetModifications, GetModificationsQueryParams } from '../queryApi'

export const useGetModifications = (params: GetModificationsQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetModifications, params], () => GetModifications(params), {
    keepPreviousData: true,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
