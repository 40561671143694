import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId, GetReferenceItemsPayload } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { patchReferenceItem, PatchReferenceNumberItemPayload } from '../queryApi'

export const usePatchReferenceItem = (referenceId?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(patchReferenceItem, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.ReferenceNumberItemsUpdate)
      queryClient.setQueryData(
        [QueryKey.GetReferenceItems, referenceId],
        (prev: GetReferenceItemsPayload | undefined) => {
          if (!prev) {
            return undefined
          }
          return {
            ...prev,
            data: prev.data.map((item) => (item.id === data.id ? data : item)),
          }
        },
      )
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const update = useCallback(
    (itemId: EntityId, data: PatchReferenceNumberItemPayload) => {
      mutate({ referenceId, itemId, data })
    },
    [mutate, referenceId],
  )

  return {
    update,
    ...rest,
  }
}
