import { FC } from 'react'
import { Col, Row } from 'antd'

import { useLocationCodes } from 'hooks'
import { currency, itemLabels, itemTypeOptions } from 'constant'

import { Divider, FormItem, Input, InputNumber, SelectSingle, Spacer, TextArea, Typography } from 'designSystem'
import { Card, FormItemSelectSingleAsync } from 'components'

export const ItemFields: FC = () => {
  return (
    <Card>
      <Typography fontWeight="xl" size="l">
        Create Item
      </Typography>
      <Spacer mb={5} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name="name" label={itemLabels.name}>
            <Input placeholder="Enter Item Name" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="sku" label={itemLabels.sku}>
            <Input placeholder="Enter SKU" uppercase />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={3} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name="type" label={itemLabels.type}>
            <SelectSingle placeholder="Select" options={itemTypeOptions} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItemSelectSingleAsync
            name="locationCodeId"
            label={itemLabels.locationCodeId}
            getItems={useLocationCodes}
          />
        </Col>
      </Row>
      <Divider my={4} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name="price" label={itemLabels.price}>
            <InputNumber placeholder="00.00" precision={2} prefix={currency} />
          </FormItem>
        </Col>
      </Row>
      <Divider my={4} />
      <FormItem name="description" label={itemLabels.description}>
        <TextArea size="small" placeholder="Enter Description" autoSize={{ minRows: 5 }} />
      </FormItem>
    </Card>
  )
}
