import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getAssetCodeById } from '../queryApi'

export const useGetAssetCodeById = (id?: EntityId) => {
  const navigate = useNavigate()

  const { data, ...rest } = useQuery([QueryKey.GetAssetCodeById, id], () => getAssetCodeById(id), {
    enabled: !!id,
    onError: () => {
      navigate(route.assetCodes)
    },
  })

  return {
    data,
    ...rest,
  }
}
