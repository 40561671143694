import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import isEqual from 'react-fast-compare'

import { getRoles } from 'api'
import { QueryKey } from 'enums'
import { useRoles, useSetRoles, useUser } from 'store'

export const useGetRoles = () => {
  const user = useUser()
  const roles = useRoles()
  const setRoles = useSetRoles()
  const { data, ...rest } = useQuery([QueryKey.GetRoles], () => getRoles(), {
    enabled: !!user,
  })

  useEffect(() => {
    if (!isEqual(data, roles)) {
      setRoles(data)
    }
  }, [data, roles, setRoles])

  return {
    roles: data,
    ...rest,
  }
}
