import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getMyCompany } from '../queryApi'

export const useGetMyCompany = () => {
  const { data, ...rest } = useQuery([QueryKey.GetMyCompany], () => getMyCompany())

  return {
    data,
    ...rest,
  }
}
