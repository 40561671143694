import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Asset codes',
    url: route.assetCodes,
  },
  {
    title: 'Create new Asset Code',
  },
]
