import { FC, useCallback, useState } from 'react'

import { useForm } from 'hooks/useForm'
import theme from 'styles/theme'

import { Button, FormItem, Icon, Input, Spacer, Typography } from 'designSystem'
import { UnauthorizedLayout } from 'components/Layout'

import { useSubmitForgotPassword } from './hooks/useSubmitForgotPassword'
import { validationSchema } from './utils'
import { ForgotPasswordSchema, ForgotPasswordStep } from './types'
import * as Styled from './styles'

import { SubmitForgotPasswordPayload } from './queryApi'

export const ForgotPassword: FC = () => {
  const [step, setStep] = useState(ForgotPasswordStep.Form)
  const { Form, handleSubmit } = useForm<ForgotPasswordSchema>({
    validationSchema,
  })

  const onSuccess = useCallback(() => {
    setStep(ForgotPasswordStep.Success)
  }, [])

  const { forgotPassword, isLoading } = useSubmitForgotPassword({ onSuccess })

  const onSubmit = useCallback(
    (forgotPasswordData: SubmitForgotPasswordPayload) => {
      forgotPassword(forgotPasswordData)
    },
    [forgotPassword],
  )

  return (
    <UnauthorizedLayout>
      {step === ForgotPasswordStep.Form && (
        <>
          <Typography display="block" as="h1" fontWeight="xl" size="xl">
            Forgot password
          </Typography>
          <Spacer mb={3} />
          <Typography display="block" as="h2" size="xs" color="secondary" fontWeight="l">
            To reset your password enter the email associated with your account below
          </Typography>
          <Spacer mb={4} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormItem name="email" label="Email">
              <Input placeholder="Email" />
            </FormItem>
            <Spacer mb={7} />
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              Confirm
            </Button>
          </Form>
        </>
      )}
      {step === ForgotPasswordStep.Success && (
        <Styled.SuccessWrapper>
          <Styled.IconWrapper>
            <Icon icon="mail" color={theme.colors.accent[400]} />
          </Styled.IconWrapper>
          <Spacer mb={4} />
          <Typography display="block" as="h1" fontWeight="xl" size="s">
            You receive email with link
          </Typography>
          <Typography display="block" as="h2" size="xs" color="secondary" fontWeight="l">
            Follow the instruction to reset your password
          </Typography>
        </Styled.SuccessWrapper>
      )}
    </UnauthorizedLayout>
  )
}
