import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetUser, useUser } from 'store'

import { showNotification } from 'designSystem'

import { patchUser, UpdateUserDataPayload } from '../queryApi'

export const useUpdateUser = (userId?: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const currentUser = useUser()
  const setUserData = useSetUser()

  const { mutate, ...rest } = useMutation(patchUser, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.UserUpdate)

      if (currentUser?.id === userId) {
        queryClient.setQueryData([QueryKey.UserMe], data)
        setUserData(data)
      }
      queryClient.setQueryData([QueryKey.GetUserById, userId], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const updateUser = useCallback(
    (payload: Partial<UpdateUserDataPayload>) => {
      mutate({ userId, data: payload })
    },
    [mutate, userId],
  )

  return {
    updateUser,
    ...rest,
  }
}
