import { css } from 'styled-components'

export const NotificationStyles = css`
  .ant-notification {
    z-index: ${({ theme }) => theme.zIndexLevel.notifications};

    .ant-notification-notice-icon {
      top: 8px;
    }
    & .title-only {
      min-height: ${({ theme }) => theme.spacers[10]};
      display: flex;
      align-items: center;
      .ant-notification-notice-message {
        padding-right: 0;
      }
      .ant-notification-notice-description {
        display: none;
      }
      .ant-notification-notice-with-icon {
        display: flex;
        align-items: center;
      }
    }

    .with-duration {
      .ant-notification-notice-close {
        display: none;
      }
    }

    .ant-notification-notice-btn {
      float: left;
      margin-left: 36px;
      margin-top: ${({ theme }) => theme.spacers['3']};
      font-size: ${({ theme }) => theme.fontSize.xs};
      svg {
        margin-right: ${({ theme }) => theme.spacers[1]};
      }
    }

    .ant-notification-notice-close {
      top: ${({ theme }) => theme.spacers['3']};
      right: ${({ theme }) => theme.spacers['3']};
    }

    && .ant-notification-notice-message {
      font-size: ${({ theme }) => theme.fontSize.xs};
      line-height: ${({ theme }) => theme.lineHeight.s};
      font-weight: ${({ theme }) => theme.fontWeight.xl};
      margin-bottom: ${({ theme }) => theme.spacers['0']};
    }
    && .ant-notification-notice-description {
      font-weight: ${({ theme }) => theme.fontWeight.l};
      color: ${({ theme }) => theme.colors.secondary};
      font-size: ${({ theme }) => theme.fontSize.xs};
      line-height: ${({ theme }) => theme.lineHeight.s};
    }

    .ant-notification-notice {
      width: 272px;
      padding: 10px;
      box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.2);
      border-radius: ${({ theme }) => theme.borderRadius.small};

      &.error,
      &.info,
      &.success {
        .ant-notification-notice-message {
          display: flex;
          align-items: center;
          min-height: 24px;
          margin-bottom: 0;
          font-size: ${({ theme }) => theme.fontSize.xs};
        }

        .ant-notification-notice-close,
        .ant-notification-notice-message,
        .ant-notification-notice-description,
        .ant-notification-notice-icon,
        .ant-notification-notice-btn {
          color: ${({ theme }) => theme.colors.white};
        }
      }
      &.default {
        background: ${({ theme }) => theme.colors.white};
        .ant-notification-notice-icon {
          color: ${({ theme }) => theme.colors.accent[400]};
        }
        .ant-notification-notice-message {
          color: ${({ theme }) => theme.colors.primary};
        }

        .ant-notification-notice-btn {
          color: ${({ theme }) => theme.colors.accent[400]};
        }
        .ant-notification-notice-close {
          color: ${({ theme }) => theme.colors.secondary};
        }
      }
      &.error {
        background: ${({ theme }) => theme.colors.error[400]};
      }
      &.info {
        background: ${({ theme }) => theme.colors.accent[400]};
      }
      &.success {
        background: ${({ theme }) => theme.colors.success[400]};
      }
      &.warning {
        background: ${({ theme }) => theme.colors.warning[400]};
        .ant-notification-notice-message {
          color: ${({ theme }) => theme.colors.primary};
        }
        .ant-notification-notice-description {
          color: ${({ theme }) => theme.colors.primary};
        }
        .ant-notification-notice-close {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`
