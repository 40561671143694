import { AssetNumberType, ReferenceNumberVendorReleaseAsset } from 'api'
import { useAssets } from 'hooks'
import { route, vendorReleaseAssetStatusOptions } from 'constant'
import { ReferenceSubLineFormAccessor } from 'types'
import { getTableTitle } from 'utils'

import { Container, EditFormItem, Icon, IconDelete } from 'designSystem'
import {
  FormItemEditDatePicker,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  ReferenceNumberAssetStatusTag,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { ResetAssetNumberButton } from '../elements/ResetAssetNumberButton'

import { getSubLineAccessorTitle } from './getSubLineAccessorTitle'
import type { TableColumnsProps } from './getTableColumns'

export const getVendorReleaseTableColumns = ({
  skuIndex,
  fieldName,
  onDelete,
  itemId,
  handleAssetChange,
  assetsIds,
  data,
  canChangeQty,
  isFieldViewOnly,
  isFormViewOnly,
  triggerSubmit,
}: TableColumnsProps): TableColumnsType<ReferenceNumberVendorReleaseAsset> => {
  const assetsIdsArray = Object.values(assetsIds || {})
  const skuSubLinesFromData = data?.[skuIndex]?.subLines || []

  return [
    {
      title: getTableTitle(TableColumnKey.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => index + 1,
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Asset),
      key: ReferenceSubLineFormAccessor.Asset,
      dataIndex: ReferenceSubLineFormAccessor.Asset,
      width: '65%',
      ellipsis: true,
      render: (value, record, index) => {
        const asset = skuSubLinesFromData[index]?.asset

        return (
          <Container ai="center" maxWidth={100}>
            <FormItemEditSelectSingleAsync
              name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.AssetId}`}
              isFieldViewOnly={isFieldViewOnly?.(ReferenceSubLineFormAccessor.AssetId)}
              fullwidth
              defaultOption={asset ? { value: asset.id, label: asset.assetNumber } : undefined}
              href={route.assets}
              disabledValues={assetsIdsArray}
              isCreateMode={!asset}
              disabled={!itemId}
              size="small"
              getItems={useAssets}
              placeholder="Select Asset"
              onChange={(value) => handleAssetChange(index, value)}
              queryParams={{
                ...(itemId && { 'items.id': { $eq: itemId } }),
              }}
            />
            {asset?.assetNumberType === AssetNumberType.Original && (
              <ResetAssetNumberButton subLineId={skuSubLinesFromData[index].id} itemId={data?.[skuIndex]?.itemId} />
            )}
          </Container>
        )
      },
    },
    // TODO: Get Booking number from asset - view only
    // {
    //   title: getAccessorTitle(Accessor.BookingNumber),
    //   key: Accessor.BookingNumber,
    //   dataIndex: Accessor.BookingNumber,
    //   width: 160,
    //   ellipsis: true,
    //   render: (value, record, index) => (
    //
    //   ),
    // },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.GateOutDate),
      key: ReferenceSubLineFormAccessor.GateOutDate,
      dataIndex: ReferenceSubLineFormAccessor.GateOutDate,
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => (
        <EditFormItem
          size="small"
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.GateOutDate}`}
          isViewOnly={isFieldViewOnly?.(ReferenceSubLineFormAccessor.GateOutDate)}
          render={({ field }) => <FormItemEditDatePicker {...field} align="left" size="small" onBlur={triggerSubmit} />}
        />
      ),
    },
    {
      title: getSubLineAccessorTitle(ReferenceSubLineFormAccessor.Status),
      key: ReferenceSubLineFormAccessor.Status,
      dataIndex: ReferenceSubLineFormAccessor.Status,
      width: '15%',
      ellipsis: true,
      align: 'right',
      render: (value, record, index) => (
        <EditFormItem
          isViewOnly={isFieldViewOnly?.(ReferenceSubLineFormAccessor.Status)}
          name={`${fieldName}.${index}.${ReferenceSubLineFormAccessor.Status}`}
          render={({ field }) => (
            <FormItemEditSelectSingle
              {...field}
              fullwidth
              size="small"
              customPreview={
                <ReferenceNumberAssetStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
              }
              placeholder="Status"
              options={vendorReleaseAssetStatusOptions}
              onSubmit={triggerSubmit}
            />
          )}
        />
      ),
    },
    ...(canChangeQty && !isFormViewOnly
      ? [
          {
            key: TableColumnKey.HoverActions,
            dataIndex: TableColumnKey.HoverActions,
            ellipsis: true,
            render: (value: undefined, record: ReferenceNumberVendorReleaseAsset, index: number) => {
              const isLastSubLine = skuSubLinesFromData.length === 1
              return isLastSubLine ? null : (
                <Container jc="flex-end">
                  <IconDelete onClick={() => onDelete?.(index)} />
                </Container>
              )
            },
          },
        ]
      : []),
  ]
}
