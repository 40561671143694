import { PurchaseOrder, PurchaseOrderEmail, PurchaseOrderType } from 'api'
import { SendEmailAction } from 'types'

export const getPurchaseOrderEmailTypes = (action: SendEmailAction, data?: PurchaseOrder) => {
  const poTypes: PurchaseOrderEmail[] = []

  if (data?.isEmailRequired) {
    if (action === SendEmailAction.PurchaseOrderCreate) {
      if (data?.type === PurchaseOrderType.Redelivery) {
        poTypes.push(
          PurchaseOrderEmail.CreatePurchaseOrderRedeliveryToVendor,
          PurchaseOrderEmail.CreatePurchaseOrderRedeliveryToDepot,
        )
      } else if (data?.type === PurchaseOrderType.VendorRelease) {
        poTypes.push(PurchaseOrderEmail.CreatePurchaseOrderVendorRelease)
      }
    }
    if (action === SendEmailAction.PurchaseOrderUpdate) {
      if (data?.type === PurchaseOrderType.Redelivery) {
        poTypes.push(
          PurchaseOrderEmail.UpdatePurchaseOrderRedeliveryToVendor,
          PurchaseOrderEmail.UpdatePurchaseOrderRedeliveryToDepot,
        )
      } else if (data?.type === PurchaseOrderType.VendorRelease) {
        poTypes.push(PurchaseOrderEmail.UpdatePurchaseOrderVendorRelease)
      }
    }
  }

  return poTypes
}
