import { Accessor } from '../types/accessor'

export const getAccessorTitle = (accessor: Accessor): string => {
  return {
    [Accessor.Index]: '#',
    [Accessor.VendorSKU]: 'Vendor SKU',
    [Accessor.ItemSKU]: 'Internal SKU',
    [Accessor.Actions]: '',
  }[accessor]
}
