import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getUserById } from '../queryApi'

export const useGetUserById = (userId?: EntityId) => {
  const navigate = useNavigate()
  const { data, ...rest } = useQuery([QueryKey.GetUserById, userId], () => getUserById(userId), {
    enabled: !!userId,
    onError: () => {
      navigate(route.userManagement)
    },
  })

  return {
    data,
    ...rest,
  }
}
