import { useDeletePickupContact } from './useDeletePickupContact'
import { usePostPickupCustomerContacts } from './usePostPickupCustomerContacts'
import { usePostPickupNewContact } from './usePostPickupNewContact'

export const usePickupContactsRequests = (referenceId?: number, customerId?: number) => {
  const {
    post: postCustomerContacts,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isLoading: isPostLoading,
  } = usePostPickupCustomerContacts(referenceId, customerId)

  const {
    post: postNewContact,
    isSuccess: isPostNewSuccess,
    isError: isPostNewError,
    isLoading: isPostNewLoading,
  } = usePostPickupNewContact(referenceId)

  const {
    remove: deleteContact,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeletePickupContact(referenceId)

  return {
    isSuccess: isPostSuccess || isPostNewSuccess || isDeleteSuccess,
    isError: isPostError || isPostNewError || isDeleteError,
    isLoading: isPostLoading || isPostNewLoading || isDeleteLoading,
    postCustomerContacts,
    postNewContact,
    deleteContact,
  }
}
