import { PurchaseOrder, PurchaseOrderReferenceType } from 'api'

interface GetReferenceNumberDataProps {
  vendorReleaseReferenceNumber: PurchaseOrder['vendorReleaseReferenceNumber']
  redeliveryReferenceNumber: PurchaseOrder['redeliveryReferenceNumber']
  vendorReleaseNumber: string
  redeliveryNumber: string
  shouldCreateNew: boolean
}

export const getReferenceNumberData = ({
  vendorReleaseReferenceNumber,
  redeliveryReferenceNumber,
  vendorReleaseNumber,
  redeliveryNumber,
  shouldCreateNew,
}: GetReferenceNumberDataProps) => {
  const referenceType =
    vendorReleaseReferenceNumber || vendorReleaseNumber
      ? PurchaseOrderReferenceType.VendorRelease
      : redeliveryReferenceNumber || redeliveryNumber
      ? PurchaseOrderReferenceType.Redelivery
      : null

  return shouldCreateNew
    ? {
        vendorReleaseRefId: vendorReleaseReferenceNumber?.id,
        redeliveryRefId: redeliveryReferenceNumber?.id,
        referenceType,
      }
    : {
        vendorReleaseNumber: vendorReleaseReferenceNumber?.number || vendorReleaseNumber,
        redeliveryNumber: redeliveryReferenceNumber?.number || redeliveryNumber,
        referenceType,
      }
}
