export enum CustomerIndustry {
  Agriculture = 'agriculture',
  Construction = 'construction',
  Education = 'education',
  FoodService = 'foodService',
  GeneralCommercial = 'general_commercial',
  Government = 'government',
  Hospitality = 'hospitality',
  Manufacturing = 'manufacturing',
  Military = 'military',
  Other = 'other',
  Recycling = 'recycling',
  Residential = 'residential',
  Retail = 'retail',
  Wholesale = 'wholesale',
  Winery = 'winery',
}
