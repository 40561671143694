import { FC, useCallback, useState } from 'react'

import { Contact } from 'api'

import { Button, Container, Typography } from 'designSystem'
import { ContactDetailsModal } from 'components/modals/ContactDetailsModal'

interface ContactViewProps {
  contact: Contact
  showDelete?: boolean
  onDelete?: () => void
}

export const ContactView: FC<ContactViewProps> = ({ contact, showDelete, onDelete }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const handleDelete = useCallback(() => {
    onDelete?.()
    toggleOpen()
  }, [onDelete, toggleOpen])

  return (
    <Container display="flex" jc="flex-end">
      <Button type="link" onClick={toggleOpen}>
        <Typography color="info" fontWeight="l">{`${contact.firstName} ${contact.lastName}`}</Typography>
      </Button>
      <ContactDetailsModal
        open={open}
        contact={contact}
        onClose={toggleOpen}
        onDelete={handleDelete}
        showDelete={showDelete}
      />
    </Container>
  )
}
