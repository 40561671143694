import { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { VendorType } from 'api'
import { useForm, useLocationCodes } from 'hooks'
import { paymentTermsOptions, route, vendorTypeOptions } from 'constant'

import { Button, CheckboxGroup, Container, FormItem, Input, SelectSingle, Spacer, Typography } from 'designSystem'
import { Breadcrumbs, Card, Contacts, FormItemAddress, FormItemSelectSingleAsyncGroup } from 'components'

import { useCreateVendor } from './hooks/useCreateVendor'
import { maxContactsCount, maxVendorLocationsCount } from '../../constants/maxRecordsCount'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { validationSchema } from './constants/validationSchema'
import { PostVendorPayload, VendorCreateForm } from './types/vendorCreateForm'
import { CarrierSettings } from '../../elements/CarrierSettings'
import { ContainerVendorSettings } from '../../elements/ContainerVendorSettings'
import { DepotSettings } from '../../elements/DepotSettings'
import { ModificationsSettings } from '../../elements/ModificationsSettings'
import { PartsSettings } from 'modules/vendors/elements/PartsSettings'
import * as Styled from './styles'

export const VendorCreate: FC = () => {
  const { Form, handleSubmit, watch, unregister } = useForm<VendorCreateForm>({
    validationSchema,
  })

  const screens = useBreakpoint()
  const { create, isLoading } = useCreateVendor()
  const type = watch('type')

  const isModificationOn = type?.includes(VendorType.Modifications)
  const isContainerVendorOn = type?.includes(VendorType.ContainerVendor)
  const isCarrierOn = type?.includes(VendorType.Carrier)
  const isDepotOn = type?.includes(VendorType.Depot)
  const isPartsOn = type?.includes(VendorType.Parts)

  const onSubmit = useCallback(
    (data: PostVendorPayload) => {
      create(data)
    },
    [create],
  )

  useEffect(() => {
    // Reset carrierSettings data if checkbox Carrier unchecked
    if (!isCarrierOn) {
      unregister('carrierSettings')
    }
  }, [isCarrierOn, unregister])

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container ai="center" jc="center" my={7} mx={13}>
        <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Styled.Row gutter={8}>
            <Col span={24} xl={{ span: 7 }} />
            <Styled.Col span={24} xl={{ span: 10 }}>
              <Container fd="column" jc="center" fg={1} maxWidth={!screens.xl ? 500 : undefined}>
                <Card mb={3}>
                  <Typography fontWeight="xl" size="l">
                    Create Vendor
                  </Typography>
                  <Spacer mb={4} />
                  <FormItem name="companyName" label="Company Name">
                    <Input placeholder="Type Company Name" />
                  </FormItem>
                  <Spacer mb={3} />
                  <FormItem name="websiteUrl" label="Company Website">
                    <Input placeholder="https://" />
                  </FormItem>
                </Card>
                <Card>
                  <Typography fontWeight="xl" size="l">
                    Related Location Codes
                  </Typography>
                  <Spacer mb={4} />
                  <FormItemSelectSingleAsyncGroup
                    name="locationCodeIds"
                    getItems={useLocationCodes}
                    maxFieldsCount={maxVendorLocationsCount}
                  />
                </Card>
                <Spacer mb={3} />
                <Card mb={3}>
                  <Typography fontWeight="xl" size="l">
                    Vendor Type
                  </Typography>
                  <Spacer mb={4} />
                  <Row gutter={8}>
                    <FormItem name="type">
                      <CheckboxGroup options={vendorTypeOptions} />
                    </FormItem>
                  </Row>
                </Card>
                <FormItemAddress />
                <Spacer mb={3} />
                <Card>
                  <FormItem name="paymentTerms" label="Payment Terms">
                    <SelectSingle options={paymentTermsOptions} placeholder="Select" />
                  </FormItem>
                </Card>
                {isContainerVendorOn && (
                  <Container mt={3}>
                    <ContainerVendorSettings />
                  </Container>
                )}
                {isCarrierOn && (
                  <Container mt={3}>
                    <CarrierSettings />
                  </Container>
                )}
                {isDepotOn && (
                  <Container mt={3}>
                    <DepotSettings />
                  </Container>
                )}
                {isModificationOn && (
                  <Container mt={3}>
                    <ModificationsSettings />
                  </Container>
                )}

                {!screens.xl && (
                  <Container mt={3}>
                    <Contacts
                      maxContactsCount={maxContactsCount}
                      title="Business Contacts"
                      subTitle="Business Person"
                    />
                  </Container>
                )}
                {isPartsOn && (
                  <Container mt={3}>
                    <PartsSettings />
                  </Container>
                )}
                <Container jc="space-between" mt={4}>
                  <Link to={route.vendors}>
                    <Button type="default">Cancel</Button>
                  </Link>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Create
                  </Button>
                </Container>
              </Container>
            </Styled.Col>
            {screens.xl && (
              <Col span={7}>
                <Contacts maxContactsCount={maxContactsCount} title="Business Contacts" subTitle="Business Person" />
              </Col>
            )}
          </Styled.Row>
        </Form>
      </Container>
    </>
  )
}
