import { Navigate } from 'react-router-dom'

import { globalSettingsRoot, globalSettingsRoute } from 'constant'

import { NotFoundPage } from 'components'

import { AccessManagement } from '../routes/AccessManagement'
import { AssetCodes } from '../routes/AssetCodes'
import { LocationCodes } from '../routes/LocationCodes'
import { TruckCodes } from '../routes/TruckCodes'

export const globalSettingsRoutes = [
  {
    path: `/`,
    element: <Navigate to={`${globalSettingsRoot}${globalSettingsRoute.assetCodes}`} replace />,
  },
  {
    path: `${globalSettingsRoute.assetCodes}/*`,
    element: <AssetCodes />,
  },
  {
    path: `${globalSettingsRoute.locationCodes}/*`,
    element: <LocationCodes />,
  },
  {
    path: `${globalSettingsRoute.accessManagement}/*`,
    element: <AccessManagement />,
  },
  {
    path: `${globalSettingsRoute.truckCodes}/*`,
    element: <TruckCodes />,
  },
  {
    path: globalSettingsRoute.emailSettings,
    element: <>Email settings</>,
  },
  {
    path: globalSettingsRoute.assetStatuses,
    element: <>Asset statuses</>,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
]
