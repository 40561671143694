import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { prepareVendorPayload } from '../../../utils/prepareVendorPayload'
import { PostVendorPayload } from '../types/vendorCreateForm'

import { postVendor } from '../queryApi'

export const useCreateVendor = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation(postVendor, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.VendorCreate)

      const {
        carrierSettings,
        depotSettings,
        containerSupplierSettings,
        modificationsSettings,
        partSettings,
        ...rest
      } = data
      queryClient.setQueryData([QueryKey.GetVendorById, data.id], rest)
      queryClient.setQueryData([QueryKey.GetCarrierByVendorId, data.id], carrierSettings)
      queryClient.setQueryData([QueryKey.GetContainerVendorByVendorId, data.id], containerSupplierSettings)
      queryClient.setQueryData([QueryKey.GetModificationFeedByVendorId, data.id], modificationsSettings)
      queryClient.setQueryData([QueryKey.GetPartsByVendorId, data.id], partSettings)
      showNotification({ title: 'Vendor created', type: 'success' })

      if (depotSettings) {
        queryClient.invalidateQueries([QueryKey.GetDepotByVendorId, data.id])
      }

      navigate(`${route.vendors}/${data.id}`)
    },
  })

  const create = useCallback(
    (data: PostVendorPayload) => {
      const payload = prepareVendorPayload(data)
      mutate(payload)
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
