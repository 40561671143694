import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrderItem } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { DeleteSubLinesPayload } from '../types/DeleteSubLinesPayload'

import { deleteSubLines } from '../queryApi'

export const useDeleteSubLines = (purchaseOrderId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()

  const { mutate, ...rest } = useMutation(deleteSubLines, {
    onSuccess: ({ data, headers }, { purchaseOrderId }) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      dispatchAppEvent(AppEvent.PurchaseOrderItemUpdated)
      queryClient.setQueryData(
        [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
        (prev: PurchaseOrderItem[] | undefined) => {
          if (!prev) return undefined
          return prev.map((item) => {
            if (item.id === data.id) {
              return data
            }
            return item
          })
        },
      )
      showNotification({ title: 'Assets deleted', duration: 3, type: 'success' })
    },
  })

  const removeSubLines = useCallback(
    (itemId: EntityId, payload: DeleteSubLinesPayload) => {
      mutate({ purchaseOrderId, itemId, data: payload })
    },
    [mutate, purchaseOrderId],
  )

  return {
    removeSubLines,
    ...rest,
  }
}
