import { FC, useCallback, useEffect, useState } from 'react'

import { useGetVendorById } from 'modules/vendors/routes/Vendor/hooks/useGetVendorById'

import { useUpdateVendorIsFavorite } from './hooks/useUpdateVendorIsFavorite'

import { ButtonFavorite } from '../../../../ButtonFavorite'

interface VendorIsFavoriteProps {
  id: number
}

export const VendorIsFavoriteActionButton: FC<VendorIsFavoriteProps> = ({ id }) => {
  const { data } = useGetVendorById({ id })
  const { update, isLoading } = useUpdateVendorIsFavorite(id)
  const [isFavorite, setIsFavorite] = useState(data?.isFavorite)

  const handleUpdateIsFavorite = useCallback(() => {
    setIsFavorite(!isFavorite)
    update(!isFavorite)
  }, [update, isFavorite])

  useEffect(() => {
    if (data?.isFavorite !== isFavorite) {
      setIsFavorite(data?.isFavorite)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isFavorite])

  return <ButtonFavorite onClick={handleUpdateIsFavorite} isFavorite={isFavorite} disabled={isLoading} />
}
