import { ReferenceNumberBookingType } from 'api/types/referenceNumber/referenceNumberBookingType'

export const referenceNumberBookingTypeOptions: { value: ReferenceNumberBookingType; label: string }[] = [
  {
    value: ReferenceNumberBookingType.Release,
    label: 'Release',
  },
  {
    value: ReferenceNumberBookingType.VendorRelease,
    label: 'Vendor Release',
  },
]
export const getReferenceNumberBookingTypeOptionLabel = (type: ReferenceNumberBookingType): string | undefined =>
  referenceNumberBookingTypeOptions.find(({ value }) => value === type)?.label
