import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import {
  AssetNumberType,
  ReferenceNumberAsset,
  ReferenceNumberAssetStatus,
  ReferenceNumberBookingTransactionType,
  ReferenceNumberBookingType,
  ReferenceNumberItem,
  ReferenceNumberRedeliveryTransactionType,
  ReferenceNumberType,
} from 'api'
import { useFieldArray, useFormContext, useItems, useMemoCompare, useWatch } from 'hooks'
import { route } from 'constant'
import { ReferenceItemFormAccessor, ReferenceSubLineFormAccessor, typedMemo } from 'types'

import {
  Button,
  Container,
  EditFormItem,
  FormItem,
  InputNumber,
  SelectSingleOption,
  Spacer,
  Typography,
} from 'designSystem'
import {
  ConfirmationPopover,
  FormItemEditInputNumber,
  FormItemEditSelectSingleAsync,
  FormItemSelectSingleAsync,
  Table,
} from 'components'

import { getTableColumns } from './utils/getTableColumns'
import { subLinesFieldsActions } from './utils/subLinesFieldsActions'
import { maxAssetsInItem } from 'modules/references/constants/maxRecordsCount'
import { AllowedItemQty } from './elements/AllowedItemQty'
import { DecreaseItemQty } from './elements/DecreaseItemQty'
import * as Styled from './styles'

import { ReferenceItemsContext } from '../../contexts/referenceItemsContext'

interface ReferenceItemProps {
  type: ReferenceNumberType
  name: string
  index: number
  onClickRemove: () => void
  showDelete: boolean
  isFormViewOnly?: boolean
  applyOverlay: boolean
  currentQty?: Record<string, number>
  vendorReleaseItems?: Record<string, number>
}

type ReferenceNumberAssetWithKey = ReferenceNumberAsset & {
  id: string
  key: string
}

const ReferenceItemBase = ({
  type,
  name,
  index,
  onClickRemove,
  showDelete,
  isFormViewOnly,
  currentQty,
  vendorReleaseItems,
  applyOverlay,
}: ReferenceItemProps) => {
  const { data, isFieldViewOnly, canChangeQty, referenceData, assetsIds, setAssetsIds } =
    useContext(ReferenceItemsContext)
  const { triggerSubmit, setValue, getValues, unregister } = useFormContext()
  const [decreaseAssetsModalOpen, setDecreaseAssetsModalOpen] = useState(false)
  const assetNumberTypesById = useRef<Record<string, AssetNumberType> | undefined>({})

  const vendorReleaseId = referenceData?.vendorReleaseId
  const transactionType = referenceData?.transactionType
  const depotSettingId = referenceData?.depotSettingId
  const bookingType = referenceData?.bookingType

  const currentItemData = data?.[index]

  const itemName = `${name}.${index}`
  const itemAssetsName = `${itemName}.${ReferenceItemFormAccessor.SubLines}`
  const isRedelivery = type === ReferenceNumberType.Redelivery
  const isBooking = type === ReferenceNumberType.Booking

  const { fields, append, replace, remove } = useFieldArray({ name: itemAssetsName })
  const qtyChangedOutside = useRef(false)
  const currentQtyChangedError = useRef(false)

  const [qty, itemId] = useWatch({
    name: [`${itemName}.${ReferenceItemFormAccessor.Qty}`, `${itemName}.${ReferenceItemFormAccessor.ItemId}`],
  })

  const currentItemQty = currentQty?.[itemId]
  const showAllowedItemQtyError = !!vendorReleaseItems?.[itemId] && (currentItemQty || 0) > vendorReleaseItems[itemId]

  const handleClickRemove = useCallback(
    (assetIndex: number) => {
      qtyChangedOutside.current = true

      subLinesFieldsActions.removeOne({
        getValues,
        itemAssetsName,
        skuIndex: index,
        assetIndex,
        qtyFieldName: `${itemName}.${ReferenceItemFormAccessor.Qty}`,
        setValue,
        setAssetsIds,
        remove,
      })

      triggerSubmit?.()
    },
    [getValues, itemAssetsName, index, itemName, setValue, setAssetsIds, remove, triggerSubmit],
  )

  const handleSkuChange = useCallback(() => {
    subLinesFieldsActions.removeAssetsIds({
      getValues,
      itemAssetsName,
      skuIndex: index,
      setAssetsIds,
      replace,
      type,
    })
  }, [itemAssetsName, getValues, index, setAssetsIds, replace, type])

  const handleAssetChange = useCallback(
    (i: number, value: number | undefined, option?: SelectSingleOption) => {
      const selectedAssets = {
        ...assetsIds,
        [`${index}.${i}`]: value,
      }
      setAssetsIds?.(selectedAssets)
      if (isRedelivery) {
        if (value) {
          assetNumberTypesById.current = { ...assetNumberTypesById.current, [value]: option?.data?.assetNumberType }
        }
        setValue(`${itemAssetsName}.${i}.${ReferenceSubLineFormAccessor.Condition}`, option?.data?.condition)
        setValue(`${itemAssetsName}.${i}.${ReferenceSubLineFormAccessor.ContainerColor}`, option?.data?.containerColor)
        setValue(
          `${itemAssetsName}.${i}.${ReferenceSubLineFormAccessor.Status}`,
          ReferenceNumberAssetStatus.PendingRedelivery,
        )
        setValue(`${itemAssetsName}.${i}.${ReferenceSubLineFormAccessor.PickupReferenceId}`, null)
      }
    },
    [setAssetsIds, assetsIds, index, isRedelivery, setValue, itemAssetsName],
  )

  useEffect(() => {
    if (
      isRedelivery &&
      !currentItemData &&
      ![
        ReferenceNumberRedeliveryTransactionType.Pickup,
        ReferenceNumberRedeliveryTransactionType.RentalSwapReturn,
        ReferenceNumberRedeliveryTransactionType.SaleSwapReturn,
      ].includes(transactionType as ReferenceNumberRedeliveryTransactionType)
    ) {
      const subLines = (getValues(itemAssetsName) || []) as ReferenceNumberItem[]

      subLines.forEach((subLine, index) => {
        unregister(`${itemAssetsName}.${index}.${ReferenceSubLineFormAccessor.PickupReferenceId}`)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionType])

  useEffect(() => {
    if (isBooking && !currentItemData && bookingType === ReferenceNumberBookingType.VendorRelease) {
      const subLines = (getValues(itemAssetsName) || []) as ReferenceNumberItem[]

      subLines.forEach((subLine, index) => {
        unregister(`${itemAssetsName}.${index}.${ReferenceSubLineFormAccessor.IsModificationRequired}`)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingType])

  useEffect(() => {
    if (isBooking && !currentItemData && transactionType === ReferenceNumberBookingTransactionType.Sale) {
      subLinesFieldsActions.removeAssetsIds({
        getValues,
        itemAssetsName,
        skuIndex: index,
        setAssetsIds,
        replace,
        type,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depotSettingId, transactionType])

  const columns = useMemoCompare(
    getTableColumns(type)({
      fieldName: itemAssetsName,
      onDelete: handleClickRemove,
      itemId,
      skuIndex: index,
      handleAssetChange,
      assetsIds,
      assetNumberTypesById: assetNumberTypesById.current,
      canChangeQty,
      isFieldViewOnly,
      isFormViewOnly: isFormViewOnly || showAllowedItemQtyError,
      data,
      referenceData,
      getValues,
      triggerSubmit,
    }) as ReferenceNumberAssetWithKey[],
  )

  useEffect(() => {
    if (qtyChangedOutside.current) {
      qtyChangedOutside.current = false
      return
    }

    if (showAllowedItemQtyError) {
      currentQtyChangedError.current = true
      return
    }
    currentQtyChangedError.current = false

    if (qty !== undefined) {
      const assetsForm = getValues(itemAssetsName) || []
      const countDiff = qty - assetsForm.length

      if (countDiff > 0) {
        if (qty > maxAssetsInItem) return

        append(
          new Array(countDiff).fill({
            status: isRedelivery ? ReferenceNumberAssetStatus.PendingRedelivery : ReferenceNumberAssetStatus.Open,
          }),
          { shouldFocus: false },
        )
        triggerSubmit?.()
      } else {
        if (currentItemData) {
          if (currentItemData.qty !== qty) {
            setDecreaseAssetsModalOpen(true)
          }
        } else {
          subLinesFieldsActions.removeMultiple({
            getValues,
            itemAssetsName,
            skuIndex: index,
            assetIndexes: countDiff,
            setAssetsIds,
            remove,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qty])

  const handleSubmitDecreaseQty = useCallback(
    (idsToDelete: string[]) => {
      subLinesFieldsActions.removeSubLinesByIds({
        ids: idsToDelete.map((id) => Number(id)),
        itemAssetsName,
        skuIndex: index,
        setAssetsIds,
        getValues,
        replace,
      })
      setDecreaseAssetsModalOpen(false)
      triggerSubmit?.()
    },
    [itemAssetsName, index, setAssetsIds, getValues, replace, triggerSubmit],
  )

  const handleCancelDecreaseQty = useCallback(() => {
    setValue(`${itemName}.${ReferenceItemFormAccessor.Qty}`, currentItemData?.qty)
    setDecreaseAssetsModalOpen(false)
  }, [itemName, currentItemData, setValue])

  const dataSource = fields?.map((field) => ({ ...field, key: field.id })) as ReferenceNumberAssetWithKey[]

  const itemQueryParams = vendorReleaseId ? { 'referenceItems.referenceId': { $eq: vendorReleaseId } } : undefined
  const itemJoinParams = vendorReleaseId ? ['referenceItems'] : undefined

  return (
    <Styled.ItemContainer fd="column" $applyOverlay={applyOverlay && !currentQtyChangedError.current}>
      <Container fd="column">
        <Container gap={8} jc="space-between" ai="center">
          {currentItemData ? (
            <Container ai="center" height={32}>
              <FormItemEditSelectSingleAsync
                name={`${itemName}.${ReferenceItemFormAccessor.ItemId}`}
                isFieldViewOnly={isFormViewOnly || isFieldViewOnly?.(ReferenceItemFormAccessor.ItemId)}
                label="SKU:"
                defaultOption={{ value: currentItemData.item.id, label: currentItemData.item.sku }}
                href={route.items}
                getItems={useItems}
                withFullDataOption
                isCreateMode={!currentItemData}
                width={160}
                placeholder="Select SKU"
                lineEllipsis={1}
                nowrap
                onSubmit={triggerSubmit}
              />
              <Typography>,</Typography>
              <Spacer mr={2} />
              <EditFormItem
                name={`${itemName}.${ReferenceItemFormAccessor.Qty}`}
                isViewOnly={isFormViewOnly || isFieldViewOnly?.(ReferenceItemFormAccessor.Qty)}
                label="Qty:"
                render={({ field }) => (
                  <FormItemEditInputNumber
                    {...field}
                    disabled={!itemId}
                    align="left"
                    value={qty}
                    width={100}
                    placeholder="Qty"
                    withConfirmation
                  />
                )}
              />
              <Spacer mr={2} />
              <AllowedItemQty isError={showAllowedItemQtyError} allowedQty={vendorReleaseItems?.[itemId]} />
            </Container>
          ) : (
            <Container gap={4} ai="center">
              <FormItemSelectSingleAsync
                onChange={handleSkuChange}
                name={`${itemName}.${ReferenceItemFormAccessor.ItemId}`}
                getItems={useItems}
                queryParams={itemQueryParams}
                join={itemJoinParams}
                withFullDataOption
                width={160}
                placeholder="Select SKU"
                onSelect={triggerSubmit}
              />
              <FormItem name={`${itemName}.${ReferenceItemFormAccessor.Qty}`} withConfirmation disableOnChange>
                <InputNumber disabled={!itemId} value={qty} width={100} placeholder="Qty" />
              </FormItem>
              <AllowedItemQty isError={showAllowedItemQtyError} allowedQty={vendorReleaseItems?.[itemId]} />
            </Container>
          )}
          {showDelete && (
            <ConfirmationPopover text="Are you sure you want to delete assets?" onOk={onClickRemove}>
              <Button size="small" icon="delete" />
            </ConfirmationPopover>
          )}
        </Container>
        <Spacer mt={3} />
        <Table<ReferenceNumberAssetWithKey>
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: 850 }}
          locale={{
            emptyText: (
              <Container pa={4} jc="center" ai="center">
                List of Assets is empty for now, change Qty. to add assets
              </Container>
            ),
          }}
        />
        {!!currentItemData && (
          <DecreaseItemQty
            referenceType={type}
            item={currentItemData}
            open={decreaseAssetsModalOpen}
            onCancel={handleCancelDecreaseQty}
            desiredQty={qty}
            rowsCount={currentItemData.subLines?.length || 0}
            itemName={currentItemData.item.sku}
            onSubmit={handleSubmitDecreaseQty}
          />
        )}
      </Container>
    </Styled.ItemContainer>
  )
}

export const ReferenceItem = typedMemo(ReferenceItemBase)
