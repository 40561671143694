import type { ReferenceNumber } from 'api'

interface ReferenceLabels extends Record<keyof Omit<ReferenceNumber, 'items'>, string> {
  gateOutLocationId: string
  gateOutDepotId: string
  // items
}

export const referenceLabels: ReferenceLabels = {
  id: '',
  number: 'Reference Number',
  type: 'Type',
  status: 'Status',
  createdAt: 'Created Date',
  expirationDate: 'Expiration Date',
  notes: 'Internal Notes',
  locationCodeId: 'Location',
  gateOutLocationId: 'Gate Out Location',
  locationCode: 'Location',
  gateInLocationCodeId: 'Gate In Location',
  gateInDepotSettingId: 'Gate In Depot',
  gateInLocationCode: 'Gate In Location',
  gateInDepotSetting: 'Gate In Depot',
  depotSettingId: 'Depot',
  gateOutDepotId: 'Gate Out Depot',
  depotSetting: 'Depot',
  purchaseOrderId: 'Purchase Order',
  purchaseOrder: 'Purchase Order',
  salesOrderId: 'Related Order',
  salesOrder: 'Related Order',
  availableAssets: 'Available Assets',
  transactionType: 'Transaction Type',
  bookingNumbers: 'Booking',
  bookingType: 'Type',
  assetPrefix: 'Asset Prefix',
  depotNotes: 'Depot Notes',
  assets: 'Asset',
  vendorReleaseId: 'Vendor Release',
  vendorRelease: 'Vendor Release Number',
  redeliveryNumbers: 'Redelivery',
  dpp: 'DPP Amount',
  bookingNumberId: 'Booking',
  bookingNumber: 'Booking',
  customer: 'Customer',
  customerId: 'Customer',
  customerAddressId: 'Address',
  customerAddress: 'Address',
  contacts: 'Contacts',
  urgency: 'Urgency',
  pickupDate: 'Pickup Date',
  scheduledPickupDate: 'Scheduled Pickup Date',
  dispatchedDate: 'Dispatched Date',
  vendor: 'Vendor(Trucking)',
  vendorId: 'Vendor(Trucking)',
  dispatcher: 'Dispatcher',
  dispatcherId: 'Dispatcher',
  transportationPrice: 'Transportation Price',
  transportationCost: 'Transportation Cost',
}
