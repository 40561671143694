import { FC } from 'react'

import { ActivityStreamlineType } from 'api'

import { ActivityStreamLineTable } from 'components'

interface PurchaseOrderHistoryProps {
  id: number
}

export const PurchaseOrderHistory: FC<PurchaseOrderHistoryProps> = ({ id }) => {
  const filters = {
    $and: [
      {
        $or: [
          {
            topLevelEntityId: { $eq: id },
          },
          {
            entityId: { $eq: id },
          },
        ],
      },
      { topLevelType: { $eq: ActivityStreamlineType.PurchaseOrderEntity } },
    ],
  }

  return <ActivityStreamLineTable filters={JSON.stringify(filters)} />
}
