import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'designSystem'
import { ConfirmationPopover } from 'components'

import { useRemoveUser } from '../../hooks/useRemoveUser'

export const ActionButtons: FC = () => {
  const { userId } = useParams()
  const { removeUser, isLoading: removeUserLoading } = useRemoveUser(Number(userId))

  const handleRemoveUser = useCallback(() => {
    removeUser()
  }, [removeUser])

  return (
    <ConfirmationPopover
      text="Are you sure you want to remove user?"
      onOk={handleRemoveUser}
      isLoading={removeUserLoading}
    >
      <Button icon="delete" danger size="small" />
    </ConfirmationPopover>
  )
}
