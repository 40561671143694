import { FC, useCallback, useState } from 'react'

import { AssetNumberType, ReferenceNumberAssetStatus, ReferenceNumberRedeliveryAsset } from 'api'
import { useFormContext } from 'hooks'
import { redeliveryAssetStatusOptions } from 'constant'

import { EditFormItem, Icon } from 'designSystem'
import { ConfirmationPopover, FormItemEditSelectSingle, ReferenceNumberAssetStatusTag } from 'components'

interface SubLineStatusColumnProps {
  recordName: string
  fieldName: string
  assetNumberType?: AssetNumberType
}
export const SubLineStatusColumn: FC<SubLineStatusColumnProps> = ({ recordName, fieldName, assetNumberType }) => {
  const { getValues, setValue, triggerSubmit } = useFormContext()
  const [openPopover, setOpenPopover] = useState(false)

  const recordFieldName = `${recordName}.${fieldName}`

  const handleClosePopover = useCallback(() => {
    setOpenPopover(false)
  }, [])

  const handleStatusChange = useCallback(
    (value?: string | number | null) => {
      if (value === ReferenceNumberAssetStatus.Received) {
        const { assetId, condition, containerColor, receivedDate } = getValues(
          recordName,
        ) as ReferenceNumberRedeliveryAsset

        const isAbleToChangeToReceived =
          assetId && condition && containerColor && receivedDate && assetNumberType === AssetNumberType.Original

        if (!isAbleToChangeToReceived) {
          setOpenPopover(true)
          return
        }
      }

      setValue(recordFieldName, value)
      triggerSubmit?.()
    },
    [triggerSubmit, setValue, getValues, recordFieldName, recordName, assetNumberType],
  )

  return (
    <EditFormItem
      name={recordFieldName}
      render={({ field }) => (
        <FormItemEditSelectSingle
          {...field}
          disableOnChange
          fullwidth
          size="small"
          customPreview={
            <ReferenceNumberAssetStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
          }
          placeholder="Status"
          options={redeliveryAssetStatusOptions}
          onSubmit={handleStatusChange}
          popover={
            openPopover && (
              <ConfirmationPopover
                placement="left"
                open={openPopover}
                onOpenChange={handleClosePopover}
                onCancel={handleClosePopover}
                onOk={handleClosePopover}
                cancelText="Close"
                showOk={false}
                text="You cannot change status to Received. Fields Asset number, Received date, Condition, Container color must be filled. Asset number type must be Original"
              />
            )
          }
        />
      )}
    />
  )
}
