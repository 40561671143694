import { useQuery } from '@tanstack/react-query'

import { getUsers, GetUsersQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetUsers = (params: GetUsersQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetUsers, params], () => getUsers({ join: ['roles'], ...params }), {
    keepPreviousData: true,
  })

  const { total, data: users } = data || {}

  return {
    data: users,
    total,
    ...rest,
  }
}
