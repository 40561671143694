import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { showNotification } from 'designSystem'

import { postPurchaseOrderEmail, PostPurchaseOrderEmailProps } from '../queryApi'

export const usePostPurchaseOrderEmail = (purchaseOrderId?: number, onSuccess?: () => void) => {
  const { mutate, ...rest } = useMutation(postPurchaseOrderEmail, {
    onSuccess: () => {
      onSuccess?.()
      showNotification({ title: 'Email was sent', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: PostPurchaseOrderEmailProps['payload']) => {
      mutate({ purchaseOrderId, payload })
    },
    [mutate, purchaseOrderId],
  )

  return {
    post,
    ...rest,
  }
}
