import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CarrierLocationField } from '../types/carrierLocationsForm'

import { GetCarrierLocationsResponse, postCarrierLocation } from '../queryApi'

export const usePostCarrierLocation = (id: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(postCarrierLocation, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.VendorCarrierLocationCreate)

      queryClient.setQueryData(
        [QueryKey.GetCarrierLocationsByVendorId, id],
        (old: GetCarrierLocationsResponse | undefined) =>
          old
            ? {
                ...old,
                data: old?.data.length ? [...old.data, data] : [data],
              }
            : undefined,
      )
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: CarrierLocationField) => {
      const { boundary, locationCode } = payload
      mutate({ vendorId: id, data: { boundary, locationCodeId: locationCode.value } })
    },
    [mutate, id],
  )

  return {
    post,
    ...rest,
  }
}
