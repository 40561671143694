import { useQuery } from '@tanstack/react-query'

import { getLocationCodes, GetLocationCodesQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetLocationCodes = (params: GetLocationCodesQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetLocationCodes, params], () => getLocationCodes(params), {
    keepPreviousData: true,
  })

  const { total, data: locationCodes } = data || {}

  return {
    data: locationCodes,
    total,
    ...rest,
  }
}
