import { useQuery } from '@tanstack/react-query'

import { EntityId, getCustomerContacts } from 'api'
import { QueryKey } from 'enums'

export const useGetCustomerContacts = (id?: EntityId, enabled = true) => {
  const { data, isLoading, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetCustomerContacts, id],
    () => getCustomerContacts(id),
    {
      enabled: enabled && !!id,
    },
  )

  const { total, data: items } = data || {}

  return {
    data: items,
    isLoading: isLoading && fetchStatus !== 'idle',
    total,
    ...rest,
  }
}
