import type { Asset } from 'api'

type AssetLabels = Record<keyof Omit<Asset, 'redeliveryReferenceNumber'>, string>

export const assetLabels: AssetLabels = {
  id: '',
  zohoId: 'Zoho ID',
  status: 'Asset Status',
  assetHolder: 'Asset Holder',
  assetNumberType: 'Asset Number Type',
  vendorReleaseNumber: 'Vendor Release Number',
  vendorReleaseReferenceNumber: 'Vendor Release Number',
  redeliveryNumber: 'Redelivery Number',
  assetNumber: 'Asset Number',
  isBilled: 'Billed',
  isReceived: 'Received',
  loanNumber: 'Loan Number',
  loanExpirationDate: 'Loan Expiration Date',
  locationCodeId: 'Location',
  birthmarkNumber: 'Birthmark Number',
  birthmarkSize: 'Birthmark Size',
  condition: 'Condition',
  transactionType: 'Transaction type',
  locationCode: '',
  createdAt: 'Created Time',
  updatedAt: '',
  items: 'Items SKU',
  billingReference: '',
  containerColor: 'Container Color',
  cost: 'Cost',
  vendorDepotId: 'Depot Code',
  vendorDepot: '',
  vendorId: 'Vendor',
  vendor: '',
  purchaseOrderSubLine: 'Related PO',
  redeliveryRefId: 'Redelivery Reference Number',
  vendorReleaseRefId: 'Vendor Release Reference Number',
  ageOfUnit: 'Age of Unit',
}
