import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { submitForgotPassword, SubmitForgotPasswordPayload } from '../queryApi'

interface UseSubmitForgotPasswordProps {
  onSuccess: () => void
}

export const useSubmitForgotPassword = ({ onSuccess }: UseSubmitForgotPasswordProps) => {
  const { mutate, ...rest } = useMutation(submitForgotPassword, {
    onSuccess,
  })

  const forgotPassword = useCallback(
    (payload: SubmitForgotPasswordPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    forgotPassword,
    ...rest,
  }
}
