import { api, AssetCode, EntityId } from 'api'

import { AssetCodePayload } from '../../types/assetCodePayload'

export interface UpdateAssetCodePayload {
  id?: EntityId
  data: Partial<AssetCodePayload>
}

export const getAssetCodeById = (id?: EntityId): Promise<AssetCode> => {
  return api({
    method: 'get',
    url: `/api/v1/asset-codes/${id}`,
    params: {
      join: ['items'],
    },
  })
}

export const patchAssetCode = ({ id, data }: UpdateAssetCodePayload): Promise<AssetCode> => {
  return api({
    method: 'patch',
    url: `/api/v1/asset-codes/${id}`,
    data,
    params: {
      join: ['items'],
    },
  })
}

export const deleteAssetCode = (id?: EntityId): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/asset-codes/${id}`,
  })
}
