import { FC, useCallback, useEffect } from 'react'
import { Col, Row } from 'antd'

import { LocationCode } from 'api'
import { useFormContext, useLocationCodes } from 'hooks'

import { Checkbox, Divider, FormItem, Input, SelectSingleOption, Spacer, Typography } from 'designSystem'
import { Card, FormItemAddress, FormItemSelectSingleAsync } from 'components'

import { StorageFeeList } from './elements/ StorageFeeList'
import { CalculatorFeeList } from './elements/CalculatorFeeList'
import { EmailArray } from './elements/EmailArray'

interface DepotSettingsProps {
  isEdit?: boolean
}
export const DepotSettings: FC<DepotSettingsProps> = ({ isEdit }) => {
  const name = 'depotSettings'
  const { unregister, setValue } = useFormContext()

  const handleLocationCodeChange = useCallback(
    (value?: string | number | null, option?: SelectSingleOption) => {
      if (value) {
        const { country, state, city } = (option?.data || {}) as LocationCode

        setValue(`${name}.country`, country)
        setValue(`${name}.state`, state)
        setValue(`${name}.city`, city)
      }
    },
    [name, setValue],
  )

  useEffect(() => {
    return () => {
      unregister(name)
    }
  }, [unregister])

  return (
    <Card bordered={!isEdit} pa={isEdit ? 0 : undefined}>
      {!isEdit && (
        <Typography size="l">
          <Typography size="l" fontWeight="xl">
            Depot
          </Typography>{' '}
          Settings
        </Typography>
      )}
      <Spacer mb={4} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItemSelectSingleAsync
            name={`${name}.locationCodeId`}
            label="Related Location"
            withFullDataOption
            getItems={useLocationCodes}
            fields="id,code,country,state,city"
            onChange={handleLocationCodeChange}
          />
        </Col>
        <Col span={12}>
          <FormItem name={`${name}.code`} label="Depot Code">
            <Input placeholder="Type Depot Code" />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={4} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={`${name}.accountNumber`} label="Account Number">
            <Input placeholder="Type Account Number" type="digits" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={`${name}.phoneNumber`} label="Phone Number">
            <Input placeholder="+" type="phone" />
          </FormItem>
        </Col>
      </Row>
      <Spacer mb={4} />
      <FormItemAddress
        name={name}
        bordered={false}
        pa={0}
        title={
          <Typography size="l" fontWeight="xl">
            Depot Address
          </Typography>
        }
      />
      <Divider my={4} />
      <Row gutter={8}>
        <Col span={12}>
          <FormItem name={`${name}.acceptTransload`}>
            <Checkbox>Accept Transload</Checkbox>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={`${name}.availableInCalculator`}>
            <Checkbox>Available in Calculator</Checkbox>
          </FormItem>
        </Col>
      </Row>
      <Divider my={4} />
      <StorageFeeList />
      <Spacer mb={4} />
      <CalculatorFeeList />
      <Spacer mb={4} />
      <EmailArray name={`${name}.primaryEmails`} title="Primary" />
      <Spacer mb={4} />
      <EmailArray name={`${name}.redeliveryEmails`} title="Redelivery Emails" />
      <Spacer mb={4} />
      <EmailArray name={`${name}.bookingEmails`} title="Booking Emails" />
      <Spacer mb={4} />
      <EmailArray name={`${name}.secondaryEmails`} title="Secondary Emails" />
    </Card>
  )
}
