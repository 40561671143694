import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Container, Spacer, Typography } from 'designSystem'
import { Card, Spinner } from 'components'
import { useGetModificationFeedByVendorId } from 'modules/vendors/hooks/useGetModificationFeedByVendorId'

import { ModificationFeedSettings } from './elements/ModificationFeedSettings'
import { ModificationTable } from './elements/ModificationTable'

export const Modifications: FC = () => {
  const { id } = useParams()
  const { data, isLoading } = useGetModificationFeedByVendorId({ id: Number(id) })

  if (isLoading) {
    return <Spinner />
  }

  if (!data) {
    return null
  }

  return (
    <Container pa={4} fd="column">
      <Typography fontWeight="xl" size="l">
        Modifications Info
      </Typography>
      <Spacer mb={4} />
      <Card maxWidth={390}>
        <ModificationFeedSettings id={Number(id)} data={data} />
      </Card>
      <Spacer mb={7} />
      <ModificationTable id={Number(id)} />
    </Container>
  )
}
