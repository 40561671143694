import { useQuery } from '@tanstack/react-query'

import { isAuthenticated } from 'api'
import { UserSettingsService } from 'services'
import { QueryKey } from 'enums'
import { useSetUser, useUser } from 'store'

import { getUserMe } from '../queryApi'

interface UseGetMeProps {
  enabled?: boolean
}

export const useGetMe = ({ enabled }: UseGetMeProps) => {
  const isToken = isAuthenticated()
  const setUserData = useSetUser()
  const user = useUser()

  const { isFetching, isLoading, fetchStatus, ...rest } = useQuery([QueryKey.UserMe], () => getUserMe(), {
    enabled: enabled && isToken,
    onSuccess: (data) => {
      UserSettingsService.setUserSettings(data.settings)
      setUserData(data)
    },
  })

  return {
    isLoggedIn: !!user,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
