import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { EntityId, getReferenceItems } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'

import { getIsAllSubLinesStatusReceived } from '../utils/getIsAllSubLinesStatusReceived'

export const useGetReferenceItems = (referenceId?: EntityId, shouldCheckAllReceived?: boolean) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetReferenceItems, referenceId],
    () => getReferenceItems(referenceId),
    {
      enabled: !!referenceId,
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (shouldCheckAllReceived) {
      const isAllReceived = getIsAllSubLinesStatusReceived(data?.data)
      if (isAllReceived) {
        queryClient.invalidateQueries([QueryKey.GetReferenceNumberById, referenceId])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data])

  return {
    data: data?.data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
