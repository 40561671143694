import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CreateLocationCodePayload, postLocationCode } from '../queryApi'

export const useCreateLocationCode = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation(postLocationCode, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.LocationCodeCreate)

      queryClient.setQueryData([QueryKey.GetLocationCodeById, data.id], data)
      showNotification({ title: 'Location code created', type: 'success' })
      navigate(`${route.locationCodes}/${data.id}`)
    },
  })

  const createLocationCode = useCallback(
    (payload: CreateLocationCodePayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    createLocationCode,
    ...rest,
  }
}
