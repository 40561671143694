import { FC, useCallback } from 'react'

import { VendorFeedSettings } from 'api'
import { useForm } from 'hooks'
import { vendorFetchOptions } from 'constant'
import { getFeedSettingsDefaultValues } from 'utils'

import { Container, Divider, EditFormItem, FormItem, Switch } from 'designSystem'
import {
  CopyToClipboard,
  FormItemEditInput,
  FormItemEditMarginSettings,
  FormItemEditSelectSingle,
  StripLoader,
} from 'components'
import { FeedPayload } from 'modules/vendors/types/FeedPayload'

import { useUpdateModificationFeed } from '../../../../../../hooks/useUpdateModificationFeed'
import { validationSchema } from './constants/validationSchema'
import { ModificationsFeedForm } from './types/ModificationsPayloadForm'

interface ModificationFeedSettingsProps {
  id: number
  data: VendorFeedSettings
}

export const ModificationFeedSettings: FC<ModificationFeedSettingsProps> = ({ id, data }) => {
  const { update, isLoading, isError } = useUpdateModificationFeed({ id })

  const onSubmit = useCallback(
    (payload: FeedPayload) => {
      update(payload)
    },
    [update],
  )

  const { Form, triggerSubmit, watch, setValue, trigger } = useForm<ModificationsFeedForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: getFeedSettingsDefaultValues(data),
  })

  const feedUrl = watch('scrappingSettings.feedUrl')

  const handleChangeSwitch = useCallback(
    (value: boolean) => {
      setValue('scrappingSettings.isEnabled', value)
      if (value) {
        trigger('scrappingSettings.feedUrl')
      }
      triggerSubmit()
    },
    [trigger, triggerSubmit, setValue],
  )

  return (
    <Form>
      <StripLoader isLoading={isLoading} />
      <Container display="flex" fd="column" gap={4} nowrap>
        <EditFormItem
          name="scrappingSettings.feedUrl"
          label="Feed URL"
          render={({ field }) => <FormItemEditInput {...field} placeholder="Type Feed URL" onBlur={triggerSubmit} />}
        />
        <Container jc="space-between" ai="center">
          <FormItem name="scrappingSettings.isEnabled">
            <Switch text="Enable" onChange={handleChangeSwitch} />
          </FormItem>
          <CopyToClipboard copyText={feedUrl} />
        </Container>
        <Divider my={4} />
        <EditFormItem
          name="scrappingSettings.fetchSchedule"
          label="Fetch Schedule"
          render={({ field }) => (
            <FormItemEditSelectSingle {...field} width={120} options={vendorFetchOptions} onSubmit={triggerSubmit} />
          )}
        />
        <FormItemEditMarginSettings name="resaleMargin" onConfirm={triggerSubmit} />
      </Container>
    </Form>
  )
}
