import { FC } from 'react'
import { Radio } from 'antd'

import { FormItem, Radio as RadioButton, Spacer, Typography } from 'designSystem'

import { UploadTypeOption } from '../../../../constants/uploadTypeOptions'

interface VendorTypeItemProps {
  uploadTypeOptions: UploadTypeOption[]
}

export const VendorTypeItem: FC<VendorTypeItemProps> = ({ uploadTypeOptions }) => {
  return (
    <>
      <Typography fontWeight="xl" size="m">
        Vendor type
      </Typography>
      <Spacer mb={3} />
      <FormItem name="vendorType">
        <Radio.Group>
          {uploadTypeOptions.map(({ label, value }) => (
            <RadioButton key={value} value={value}>
              {label}
            </RadioButton>
          ))}
        </Radio.Group>
      </FormItem>
    </>
  )
}
