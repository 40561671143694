import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useForm, useGetItemCategories, useLocationCodes } from 'hooks'
import { currency, itemLabels, itemTypeOptions } from 'constant'
import { getSingleCategoriesOptions } from 'utils'

import { Container, Divider, EditFormItem, SelectSingleOption, Spacer, Typography } from 'designSystem'
import {
  Card,
  ConfirmationRequiredPopover,
  FormItemEditInput,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditTextArea,
  Spinner,
  StripLoader,
} from 'components'
import { FormItemEditInputNumber } from 'components/FormElements/FormItemEditInputNumber'

import { useGetItemById } from './hooks/useGetItemById'
import { useUpdateItem } from './hooks/useUpdateItem'
import { validationSchema } from '../../../../utils/validationSchema'
import { ItemForm } from '../../../../types/itemForm'
import { ItemFormData } from '../../../../types/itemPayload'
import { EditGroupSKUs } from './elements/EditGroupSKUs'
import * as Styled from './styles'

export const ItemDetails: FC = () => {
  const { id } = useParams()

  const { data, isLoading: isGetLoading } = useGetItemById(Number(id))
  const { update, isError, isLoading } = useUpdateItem(Number(id))
  const { data: categories, isLoading: isCategoriesLoading } = useGetItemCategories()

  const categoriesOptions = getSingleCategoriesOptions(categories)

  const onSubmit = useCallback(
    (data: ItemFormData) => {
      update(data)
    },
    [update],
  )

  const { groupedSKUs, locationCode, ...restData } = data || {}

  const { Form, triggerSubmit } = useForm<ItemForm>({
    validationSchema,
    context: { itemView: true },
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: data
      ? {
          ...restData,
          groupedSKUs: groupedSKUs?.map(({ id, sku }) => ({
            value: id,
            label: sku,
          })),
        }
      : {},
    viewOnlyFields: ['type'],
  })

  if (isGetLoading) {
    return <Spinner />
  }

  const categoryDefaultOption = {
    value: data?.category?.id,
    label: data?.category?.name,
  } as SelectSingleOption

  return (
    <>
      {data && (
        <>
          <Form>
            <Container pa={4} display="flex" gap={8} fw="wrap">
              <Card maxWidth={390}>
                <StripLoader isLoading={isLoading} />

                <EditFormItem
                  name="name"
                  render={({ field }) => (
                    <FormItemEditInput
                      size="large"
                      align="left"
                      {...field}
                      onConfirm={triggerSubmit}
                      popover={<ConfirmationRequiredPopover withPopover />}
                      placeholder="Enter Item Name"
                    />
                  )}
                />
                <Divider mt={3} mb={4} />
                <Container gap={4} fd="column">
                  <EditFormItem
                    name="sku"
                    label={itemLabels.sku}
                    render={({ field }) => (
                      <FormItemEditInput uppercase placeholder="Enter SKU" {...field} onBlur={triggerSubmit} />
                    )}
                  />
                  <EditFormItem
                    name="type"
                    label={itemLabels.type}
                    render={({ field }) => (
                      <FormItemEditSelectSingle {...field} options={itemTypeOptions} onSubmit={triggerSubmit} />
                    )}
                  />
                  <FormItemEditSelectSingleAsync
                    name="locationCodeId"
                    label={itemLabels.locationCodeId}
                    onSubmit={triggerSubmit}
                    returnDefaultValueOnBlur={false}
                    defaultOption={
                      locationCode
                        ? {
                            value: locationCode.id,
                            label: locationCode.code,
                          }
                        : undefined
                    }
                    getItems={useLocationCodes}
                  />
                  <Styled.Divider />
                  <EditFormItem
                    name="price"
                    label={itemLabels.price}
                    render={({ field }) => (
                      <FormItemEditInputNumber
                        placeholder="Enter Price"
                        {...field}
                        onBlur={triggerSubmit}
                        precision={2}
                        suffix={currency}
                      />
                    )}
                  />
                  <Styled.Divider />
                  <EditFormItem
                    name="categoryId"
                    label={itemLabels.categoryId}
                    render={({ field }) => (
                      <FormItemEditSelectSingle
                        {...field}
                        width={200}
                        showSearch
                        options={categoriesOptions || [categoryDefaultOption]}
                        onSubmit={triggerSubmit}
                        loading={isCategoriesLoading}
                        defaultOption={categoryDefaultOption}
                      />
                    )}
                  />
                  <EditGroupSKUs />
                </Container>
              </Card>
              <Container display="block" width={390}>
                <Card maxWidth={390}>
                  <Typography size="m" fontWeight="xl">
                    {itemLabels.description}
                  </Typography>
                  <Spacer mb={4} />
                  <EditFormItem
                    name="description"
                    render={({ field }) => (
                      <FormItemEditTextArea placeholder="Type description" {...field} onConfirm={triggerSubmit} />
                    )}
                  />
                </Card>
              </Container>
            </Container>
          </Form>
        </>
      )}
    </>
  )
}
