import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getSalesOrderById } from 'modules/salesOrders/queryApi'

export const useGetSalesOrderById = (id: EntityId) => {
  const navigate = useNavigate()

  const { data, ...rest } = useQuery([QueryKey.GetSalesOrderById, id], () => getSalesOrderById(id), {
    enabled: !!id,
    onError: () => {
      navigate(route.so)
    },
  })

  return {
    data,
    ...rest,
  }
}
