import { FC } from 'react'

import { LocationCode } from 'api'
import { route } from 'constant'

import { Container } from 'designSystem'
import { Table, TableSearchSingle, TableTitle } from 'components'

import { useLocationCodes } from './hooks/useLocationCodes'
import { getTableColumns } from './utils/getTableColumns'

export const LocationCodesList: FC = () => {
  const { data, isLoading, pagination, onTableChange, getFieldSorter, offsetCount, TableContextProvider } =
    useLocationCodes()
  const columns = getTableColumns(getFieldSorter, offsetCount)

  const locationCodesList: LocationCode[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <Container pa={4} display="flex" fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Location Codes"
          total={pagination?.total}
          addRoute={route.locationCodesCreate}
          search={<TableSearchSingle searchFieldName="code" />}
        />
        <Table<LocationCode>
          scroll={{ x: 750 }}
          dataSource={locationCodesList}
          loading={isLoading}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </TableContextProvider>
    </Container>
  )
}
