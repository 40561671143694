import { FC, useEffect } from 'react'
import { Skeleton } from 'antd'

import { HashRouterComponentProps } from 'types'
import { useClearPurchaseOrderStore, useSetPurchaseOrderData } from 'store'

import { Drawer } from 'designSystem'

import { useGetPurchaseOrderById } from './hooks/useGetPurchaseOrderById'
import { PurchaseOrderTabs } from './elements/PurchaseOrderTabs'
import { PurchaseOrderTitle } from './elements/PurchaseOrderTitle'

export const PurchaseOrder: FC<HashRouterComponentProps> = ({ hash }) => {
  const purchaseOrderId = Number(hash.split('/')?.[1])
  const { data, isLoading } = useGetPurchaseOrderById(purchaseOrderId)
  const setPurchaseOrderData = useSetPurchaseOrderData()
  const clearPurchaseOrderStore = useClearPurchaseOrderStore()

  useEffect(() => {
    return () => {
      setPurchaseOrderData(undefined)
      clearPurchaseOrderStore()
    }
  }, [setPurchaseOrderData, clearPurchaseOrderStore])

  return (
    <Drawer
      hashUrl={hash}
      width={1200}
      bg="transparent"
      pa={0}
      title={
        isLoading && !data?.number ? (
          <Skeleton.Input active />
        ) : (
          data && (
            <PurchaseOrderTitle id={data.id} number={data.number} contacts={data.contacts} vendorId={data.vendorId} />
          )
        )
      }
      isLoading={isLoading}
    >
      {!isLoading && <PurchaseOrderTabs data={data} />}
    </Drawer>
  )
}
