import { api, AssetCode } from 'api'

import { AssetCodePayload } from '../../types/assetCodePayload'

export type CreateAssetCodePayload = AssetCodePayload

export const postAssetCode = (data: CreateAssetCodePayload): Promise<AssetCode> => {
  return api({
    method: 'post',
    url: '/api/v1/asset-codes',
    data,
  })
}
