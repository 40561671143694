import { FC, useEffect } from 'react'

import { useFormContext } from 'hooks'

import { Spacer, Typography } from 'designSystem'
import { Card } from 'components'

import { FeedSettings } from '../FeedSettings'

interface ContainerVendorSettingsProps {
  isEdit?: boolean
}
export const ContainerVendorSettings: FC<ContainerVendorSettingsProps> = ({ isEdit }) => {
  const { unregister } = useFormContext()

  useEffect(() => {
    return () => {
      unregister('containerSupplierSettings')
    }
  }, [unregister])

  return (
    <Card bordered={!isEdit} pa={isEdit ? 0 : undefined}>
      {!isEdit && (
        <Typography size="l">
          <Typography size="l" fontWeight="xl">
            Container Vendor
          </Typography>{' '}
          Settings
        </Typography>
      )}
      <Spacer mb={4} />
      <FeedSettings name="containerSupplierSettings" />
    </Card>
  )
}
