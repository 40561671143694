import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { VendorDataMapping } from 'api'

import { getPatchPayload } from '../utils/getPatchPayload'
import { DataMappingFormPayload } from '../types/dataMappingForm'

import { useDeleteDataMapping } from './useDeleteDataMapping'
import { usePatchDataMapping } from './usePatchDataMapping'
import { usePostDataMapping } from './usePostDataMapping'

export const useDataMappingRequests = (setIsAddNew: (isAddNew: boolean) => void, data: VendorDataMapping[]) => {
  const { id: vendorId } = useParams()

  const {
    post,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isLoading: isPostLoading,
  } = usePostDataMapping(Number(vendorId))

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isLoading: isPatchLoading,
  } = usePatchDataMapping(Number(vendorId))

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeleteDataMapping(Number(vendorId))

  const onSubmitPost = useCallback(
    (payload: DataMappingFormPayload) => {
      if (payload.item) {
        post(payload.item)
        setIsAddNew(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: DataMappingFormPayload) => {
      const patchData = getPatchPayload(payload, data)
      patchData?.forEach(({ id, payload }) => {
        patch(id as number, payload)
      })
    },
    [patch, data],
  )

  const onDelete = useCallback(
    (equipmentId?: number) => {
      if (equipmentId) {
        remove(equipmentId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPostSuccess || isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteError,
    isEditLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
  }
}
