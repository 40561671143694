import { Link } from 'react-router-dom'

import { Item, ItemType, LocationCode } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import type { TableField, TableSortingOrder } from 'hooks'
import { itemTypeLabel, route } from 'constant'
import { getFormattedPriceWithCurrency } from 'utils'

import { Typography } from 'designSystem'
import { RelatedLinksList, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<Item> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    ellipsis: true,
    render: (value, record, index) => offsetCount + index + 1,
  },
  {
    title: getAccessorTitle(Accessor.Name),
    key: Accessor.Name,
    dataIndex: Accessor.Name,
    width: 200,
    sorter: true,
    sortOrder: getFieldSorter('name'),
    ellipsis: true,
    render: (name: string, record) => {
      const onClick = () => {
        queryClient.setQueryData([QueryKey.GetItemById, record.id], record)
      }

      return (
        <Link to={`${route.items}/${record.id}`} onClick={onClick}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {name}
          </Typography>
        </Link>
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.Description),
    key: Accessor.Description,
    dataIndex: Accessor.Description,
    width: 300,
    ellipsis: true,
  },
  {
    title: getAccessorTitle(Accessor.Sku),
    key: Accessor.Sku,
    dataIndex: Accessor.Sku,
    width: 150,
    ellipsis: true,
  },
  {
    title: getAccessorTitle(Accessor.Type),
    key: Accessor.Type,
    dataIndex: Accessor.Type,
    width: 150,
    ellipsis: true,
    render: (type: ItemType) => itemTypeLabel[type],
  },
  {
    title: getAccessorTitle(Accessor.LocationCode),
    key: Accessor.LocationCode,
    dataIndex: Accessor.LocationCode,
    width: 150,
    ellipsis: true,
    render: (locationCode: LocationCode | null) => {
      return locationCode ? (
        <RelatedLinksList entity="locationCodes" data={[{ id: locationCode.id, title: locationCode.code }]} />
      ) : (
        '-'
      )
    },
  },
  {
    title: getAccessorTitle(Accessor.GroupedSKUs),
    key: Accessor.GroupedSKUs,
    dataIndex: Accessor.GroupedSKUs,
    width: 150,
    ellipsis: true,
    render: (groupedSKUs: Item[]) => {
      const data = groupedSKUs.map(({ id, sku }) => ({
        id,
        title: sku,
      }))
      return <RelatedLinksList entity="items" data={data} />
    },
  },
  {
    title: getAccessorTitle(Accessor.Category),
    key: Accessor.Category,
    dataIndex: Accessor.Category,
    width: 150,
    ellipsis: true,
    render: (category) => category?.name,
  },
  {
    title: getAccessorTitle(Accessor.Price),
    key: Accessor.Price,
    dataIndex: Accessor.Price,
    width: 150,
    ellipsis: true,
    align: 'right',
    render: (price) =>
      price ? (
        <Typography fontWeight="xl" size="xs">
          {getFormattedPriceWithCurrency(price)}
        </Typography>
      ) : (
        '-'
      ),
  },
]
