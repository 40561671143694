import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ReferenceNumber } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deletePickupContact } from '../queryApi'

export const useDeletePickupContact = (referenceId?: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(deletePickupContact, {
    onSuccess: (data, payload) => {
      dispatchAppEvent(AppEvent.ReferenceNumberPickupContactsUpdate)
      queryClient.setQueryData(
        [QueryKey.GetReferenceNumberById, referenceId],
        (prev: { reference: ReferenceNumber } | undefined) =>
          prev
            ? {
                reference: {
                  ...prev.reference,
                  contacts: (prev.reference.contacts || []).filter((contact) => contact.id !== payload.contactId),
                },
              }
            : undefined,
      )
      showNotification({ title: 'Contact deleted', type: 'success' })
    },
  })

  const remove = useCallback(
    (contactId: number) => {
      mutate({ referenceId, contactId })
    },
    [mutate, referenceId],
  )

  return {
    remove,
    ...rest,
  }
}
