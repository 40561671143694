import { getVendorSKUs, VendorSKU } from 'api'
import { QueryKey } from 'enums'
import { AsyncSelectGetItemsProps } from 'types'

import type { SelectSingleOption } from 'designSystem'

import { useAsyncList } from './useAsyncList'

type UseVendorSKUsProps = AsyncSelectGetItemsProps

export const useVendorSKUs = ({
  id,
  defaultOption,
  valuePropName, // keyof VendorSKUs - the value of it should be of type string | number
  nestedEntityId,
}: UseVendorSKUsProps) => {
  const { list, ...props } = useAsyncList<VendorSKU>({
    id,
    defaultOption,
    queryFn: getVendorSKUs(nestedEntityId),
    queryKey: QueryKey.GetVendorSKUsList,
    searchFieldName: 'sku',
    limit: 10,
  })

  const options = list.map((item) => ({
    value: valuePropName ? (item[valuePropName as keyof VendorSKU] as string | number) : item.id,
    label: item.sku,
  })) satisfies SelectSingleOption[]

  return {
    options,
    ...props,
  }
}
