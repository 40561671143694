import { FC } from 'react'

import { useActivityStreamline } from 'hooks'
import { typedMemo } from 'types'

import { Container } from 'designSystem'

import { getTableColumns } from './utils/getTableColumns'

import { Table, TableTitle } from '../Table'

interface ActivityStreamLineTableProps {
  filters?: string
}

const ActivityStreamLineTableBase: FC<ActivityStreamLineTableProps> = ({ filters }) => {
  const { data, pagination, isLoading, offsetCount } = useActivityStreamline(filters)

  const dataSource = data?.map((dataItem, index) => ({ ...dataItem, key: index }))

  return (
    <Container bg="white" fd="column" pa={4} gap={16} width="100%">
      <TableTitle withoutDefaultPadding title="History" />
      <Table
        loading={isLoading}
        pagination={pagination}
        dataSource={dataSource}
        columns={getTableColumns(offsetCount)}
        scroll={{ x: 950 }}
      />
    </Container>
  )
}

export const ActivityStreamLineTable = typedMemo(ActivityStreamLineTableBase)
