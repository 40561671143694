import { FC } from 'react'

import { Asset } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'

import {
  Table,
  TableFilterDrawer,
  TableFilterOptions,
  TableSearchSingle,
  TableTitle,
  useTableColumns,
} from 'components'

import { useAssets } from './hooks/useAssets'
import { getTableColumns } from './utils/getTableColumns'
import { assetsFilterOptions } from './constants/assetsFilterOptions'
import { assetsVisibleColumnsOptions } from './constants/assetsVisibleColumns'

export const AssetsList: FC = () => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useAssets()
  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<Asset>({
    module: AppModule.Assets,
    allColumns,
  })

  const assetsList: Asset[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <TableContextProvider>
      <TableTitle
        title="Assets"
        total={pagination?.total}
        addRoute={route.assetsCreate}
        search={<TableSearchSingle searchFieldName={['assetNumber', 'assetCode.code']} />}
        filter={
          <TableFilterDrawer
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            options={assetsFilterOptions}
            module={AppModule.Assets}
          />
        }
        columns={
          <TableFilterOptions
            title="Columns"
            icon="columns"
            options={assetsVisibleColumnsOptions}
            value={visibleColumns}
            onChange={setVisibleColumns}
          />
        }
      />
      <Table<Asset>
        withBorderRadius={false}
        scroll={{ x: 750 }}
        dataSource={assetsList}
        loading={isLoading}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </TableContextProvider>
  )
}
