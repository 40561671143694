import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

import { useForm } from 'hooks'
import {
  assetCodeAdditionalAttributesOptions,
  assetCodeGradeOptions,
  assetCodeHeightOptions,
  assetCodeLengthOptions,
  assetCodeTypeOptions,
  assetCodeWidthOptions,
  route,
} from 'constant'

import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormItem,
  Input,
  SelectMulti,
  SelectSingle,
  Spacer,
  Typography,
} from 'designSystem'
import { Breadcrumbs, Card } from 'components'

import { useCreateAssetCode } from './hooks/useCreateAssetCode'
import { validationSchema } from '../../utils/validationSchema'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { AssetCodeForm } from '../../types/assetCodeForm'

import { CreateAssetCodePayload } from './queryApi'

export const AssetCodeCreate: FC = () => {
  const { Form, handleSubmit } = useForm<AssetCodeForm>({ validationSchema })
  const { create, isLoading } = useCreateAssetCode()

  const onSubmit = useCallback(
    (data: CreateAssetCodePayload) => {
      create(data)
    },
    [create],
  )

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container display="flex" jc="center" fd="column" ai="center">
        <Container display="block" maxWidth={500}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card mb={5} mt={5}>
              <Typography fontWeight="xl" size="l">
                Create Asset Code
              </Typography>
              <Spacer mb={5} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="code" label="Asset Code">
                    <Input placeholder="ABCD-0000000" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="type" label="Type">
                    <SelectSingle placeholder="Select type" options={assetCodeTypeOptions} />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={8}>
                  <FormItem name="length" label="Length">
                    <SelectSingle placeholder="Select length" options={assetCodeLengthOptions} />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem name="width" label="Width">
                    <SelectSingle placeholder="Select width" options={assetCodeWidthOptions} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem name="height" label="Height">
                    <SelectSingle placeholder="Select height" options={assetCodeHeightOptions} />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Row>
                <Col span={12}>
                  <FormItem name="grade" label="Grade">
                    <SelectSingle placeholder="Select grade" options={assetCodeGradeOptions} />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Row>
                <FormItem name="showInCalculator">
                  <Checkbox>Display in Calculator</Checkbox>
                </FormItem>
              </Row>
              <Spacer mb={4} />
              <Row>
                <FormItem name="showInGlobalInventory">
                  <Checkbox>Display in Global Inventory</Checkbox>
                </FormItem>
              </Row>
              <Divider my={4} />
              <FormItem name="additionalAttributes" label="Additional Attributes">
                <SelectMulti
                  placeholder="Select additional attributes"
                  options={assetCodeAdditionalAttributesOptions}
                />
              </FormItem>
            </Card>
            <Container jc="space-between">
              <Link to={route.assetCodes}>
                <Button type="default">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Create
              </Button>
            </Container>
          </Form>
        </Container>
      </Container>
    </>
  )
}
