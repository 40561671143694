import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getContainerVendorByVendorId } from '../queryApi'

type UseGetContainerVendorByVendorIdProps = {
  id: EntityId
  enabled?: boolean
}

export const useGetContainerVendorByVendorId = ({ id, enabled = true }: UseGetContainerVendorByVendorIdProps) => {
  const { data, isFetching, isLoading, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetContainerVendorByVendorId, id],
    () => getContainerVendorByVendorId(id),
    {
      enabled: !!id && enabled,
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
