import { Link } from 'react-router-dom'

import { route } from 'constant'

export const headerNavigationItems = [
  {
    label: <Link to={route.vendors}>Vendors</Link>,
    key: route.vendors,
  },
  {
    label: <Link to={route.assets}>Assets</Link>,
    key: route.assets,
  },
  //TODO temporary hide
  // {
  //   label: <Link to={route.globalInventory}>Global Inventory</Link>,
  //   key: route.globalInventory,
  // },
  {
    label: <Link to={route.items}>Items</Link>,
    key: route.items,
  },
  {
    label: <Link to={route.pos}>POs</Link>,
    key: route.pos,
  },
  {
    label: <Link to={route.customers}>Customers</Link>,
    key: route.customers,
  },
  {
    label: <Link to={route.salesOrders}>Sales Orders</Link>,
    key: route.salesOrders,
  },
  {
    label: <Link to={route.references}>References</Link>,
    key: route.references,
  },
]
