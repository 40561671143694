import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Company } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'

import { showNotification } from 'designSystem'

import { patchMyCompany } from '../queryApi'

export const useUpdateMyCompany = () => {
  const { mutate, ...rest } = useMutation(patchMyCompany, {
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.GetMyCompany], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const updateMyCompany = useCallback(
    (payload: Partial<Company>) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    updateMyCompany,
    ...rest,
  }
}
