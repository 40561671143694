import { FC } from 'react'

import { ReferenceNumber } from 'api'
import { QueryKey } from 'enums'
import { TableField, TableFilter, TableSortingOrder, useReferencesTable } from 'hooks'
import { AppModule } from 'types'

import { Container } from 'designSystem'
import { Table, TableColumnsType, TableFilterOptions, TableSearchSingle, TableTitle, useTableColumns } from 'components'

interface AssetReferenceNumbersProps {
  title: string
  module: AppModule
  queryKey: QueryKey
  defaultFilters?: TableFilter
  getTableColumns: (
    getFieldSorter: (dataIndex: TableField) => TableSortingOrder,
    offsetCount: number,
  ) => TableColumnsType<ReferenceNumber>
  visibleColumnsOptions: { label: string; value: string }[]
}

export const AssetReferenceNumbers: FC<AssetReferenceNumbersProps> = ({
  title,
  module,
  queryKey,
  defaultFilters,
  getTableColumns,
  visibleColumnsOptions,
}) => {
  const { list, isLoading, pagination, TableContextProvider, offsetCount, getFieldSorter, onTableChange } =
    useReferencesTable({
      module,
      queryKey,
      defaultFilters,
      join: [
        'items',
        'items.subLines',
        'items.subLines.asset||id,assetNumber',
        'vendorRelease||id,number',
        'bookingNumber||id,number',
        'bookingNumbers||id,number',
        'redeliveryNumbers||id,number',
      ],
    })

  const allColumns = getTableColumns(getFieldSorter, offsetCount)
  const { visibleColumns, setVisibleColumns, columns } = useTableColumns<ReferenceNumber>({
    allColumns,
    module,
  })

  return (
    <TableContextProvider>
      <Container fd="column" pa={4} gap={16}>
        <TableTitle
          title={title}
          total={pagination?.total}
          withoutDefaultPadding
          search={<TableSearchSingle searchFieldName="number" />}
          columns={
            <TableFilterOptions
              title="Columns"
              icon="columns"
              options={visibleColumnsOptions}
              value={visibleColumns}
              onChange={setVisibleColumns}
            />
          }
        />
        <Table<ReferenceNumber>
          loading={isLoading}
          pagination={pagination}
          dataSource={list}
          columns={columns}
          onChange={onTableChange}
          scroll={{ x: 750 }}
        />
      </Container>
    </TableContextProvider>
  )
}
