import { useQuery } from '@tanstack/react-query'

import { getAssets, GetAssetsQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetAssets = (enabled?: boolean, props?: GetAssetsQueryParams) => {
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetAssetsList, props],
    () => getAssets(props),
    {
      enabled,
      cacheTime: 0,
      staleTime: 0,
    },
  )

  return {
    data: data?.data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
