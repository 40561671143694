import { useQuery } from '@tanstack/react-query'

import { getReferenceNumbers, GetReferenceNumbersQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetReferenceNumbers = (queryKey: QueryKey, params: GetReferenceNumbersQueryParams) => {
  const { data, ...rest } = useQuery([queryKey, params], () => getReferenceNumbers(params), {
    keepPreviousData: true,
  })

  const { total, data: items } = data || {}

  const formattedItems = items?.map((item) => item.reference)

  return {
    data: formattedItems,
    total,
    ...rest,
  }
}
