import { FC, useCallback, useContext, useEffect } from 'react'

import { ReferenceNumberItem, ReferenceNumberStatus, ReferenceNumberType, ReferenceNumberVendorRelease } from 'api'
import { useFirstRender, useFormContext, useReferences, useWatch } from 'hooks'
import { referenceLabels } from 'constant'
import { ReferenceFormAccessor, typedMemo } from 'types'

import { SelectSingleOption } from 'designSystem'
import { FormItemSelectSingleAsync } from 'components'

import { getVendorReleaseItemsQty } from '../../utils/getVendorReleaseItemsQty'

import { ReferenceItemsContext } from '../ReferenceItems/contexts/referenceItemsContext'

interface VendorReleaseProps {
  defaultValue?: ReferenceNumberVendorRelease | null
  setVendorReleaseItems?: (items: Record<string, number>) => void
  setDepotSettingSelectedOption: (option?: SelectSingleOption) => void
}

const VendorReleaseBase: FC<VendorReleaseProps> = ({
  defaultValue,
  setVendorReleaseItems,
  setDepotSettingSelectedOption,
}) => {
  const { setValue, resetField, trigger } = useFormContext()
  const { setAssetsIds } = useContext(ReferenceItemsContext)
  const isFirstRender = useFirstRender()

  const vendorReleaseId = useWatch({ name: ReferenceFormAccessor.VendorReleaseId })
  const depotSettingId = useWatch({ name: ReferenceFormAccessor.DepotSettingId })
  const locationCodeId = useWatch({ name: ReferenceFormAccessor.LocationCodeId })
  const items = useWatch({ name: ReferenceFormAccessor.Items }) as ReferenceNumberItem[]
  const isSomeItemSelected = items?.length > 1 || !!items?.[0]?.itemId

  useEffect(() => {
    if (!isFirstRender) {
      setAssetsIds?.({})
      setValue(
        ReferenceFormAccessor.Items,
        items?.map((item) => ({
          ...item,
          itemId: null,
          qty: null,
          subLines: item.subLines?.map((subLine) => ({
            ...subLine,
            assetId: null,
          })),
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorReleaseId])

  const handleVendorReleaseChange = useCallback(
    (value?: string | number | null, option?: SelectSingleOption) => {
      setVendorReleaseItems?.(getVendorReleaseItemsQty(option?.data?.items))
      if (isSomeItemSelected) {
        resetField(ReferenceFormAccessor.Items)
      }
      if (!depotSettingId) {
        const depotSetting = option?.data?.depotSetting
        setValue(ReferenceFormAccessor.DepotSettingId, depotSetting?.id)
        setDepotSettingSelectedOption(depotSetting ? { value: depotSetting.id, label: depotSetting.code } : undefined)
        trigger(ReferenceFormAccessor.DepotSettingId)
      }
    },
    [
      isSomeItemSelected,
      depotSettingId,
      resetField,
      setValue,
      setVendorReleaseItems,
      setDepotSettingSelectedOption,
      trigger,
    ],
  )

  return (
    <FormItemSelectSingleAsync
      name={ReferenceFormAccessor.VendorReleaseId}
      label={referenceLabels.vendorRelease}
      getItems={useReferences}
      withFullDataOption
      disabled={!locationCodeId}
      placeholder={locationCodeId ? undefined : 'Select Location first'}
      fields="id,number,status,items,depotSetting"
      join={['items||itemId,qty', 'depotSetting||id,code']}
      onChange={handleVendorReleaseChange}
      queryParams={{
        locationCodeId: { $eq: locationCodeId },
        ...(depotSettingId && { depotSettingId: { $eq: depotSettingId } }),
        type: { $eq: ReferenceNumberType.VendorRelease },
        status: { $notin: [ReferenceNumberStatus.Voided, ReferenceNumberStatus.Closed] },
      }}
      selectedOption={
        vendorReleaseId && defaultValue && vendorReleaseId === defaultValue.id
          ? { value: defaultValue.id, label: defaultValue.number }
          : undefined
      }
    />
  )
}

export const VendorRelease = typedMemo(VendorReleaseBase)
