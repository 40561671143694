import { FC } from 'react'

import { PurchaseOrderReferenceType } from 'api'
import { useForm } from 'hooks'

import { Button, Container, FormItem, Input, Modal, ModalProps } from 'designSystem'

import { validationSchema } from '../../utils/validationSchema'
import { CreateNewReferenceNumberPayload } from '../../types/createNewReferenceNumberPayload'

interface CreateNewReferenceNumberModalProps extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  type: PurchaseOrderReferenceType
  toggleCreateReferenceNumberModal: () => void
  onConfirm: (data: CreateNewReferenceNumberPayload) => void
  isLoading: boolean
}

export const CreateNewReferenceNumberModal: FC<CreateNewReferenceNumberModalProps> = ({
  width = 440,
  title = 'Create Reference Number',
  open,
  footer = null,
  onConfirm,
  toggleCreateReferenceNumberModal,
  isLoading,
  ...modalProps
}) => {
  const { Form, handleSubmit, reset } = useForm<CreateNewReferenceNumberPayload>({
    validationSchema,
    mode: 'onChange',
  })

  return (
    <Modal
      destroyOnClose
      {...modalProps}
      afterClose={reset}
      open={open}
      width={width}
      title={title}
      footer={footer}
      onCancel={toggleCreateReferenceNumberModal}
    >
      <Form onSubmit={handleSubmit(onConfirm)}>
        <FormItem name="referenceNumber" label="Reference Number">
          <Input placeholder="Type Reference Number" />
        </FormItem>
        <Container display="flex" jc="space-between" gap={8} mt={6}>
          <Button type="default" onClick={toggleCreateReferenceNumberModal}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" fullwidth onClick={handleSubmit(onConfirm)}>
            Confirm
          </Button>
        </Container>
      </Form>
    </Modal>
  )
}
