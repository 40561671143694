import { ReferenceSubLineFormAccessor } from 'types'

export const getSubLineAccessorTitle = (accessor: ReferenceSubLineFormAccessor): string => {
  return {
    [ReferenceSubLineFormAccessor.Asset]: 'Asset',
    [ReferenceSubLineFormAccessor.AssetId]: 'Asset',
    [ReferenceSubLineFormAccessor.PickupDate]: 'Pickup Date',
    [ReferenceSubLineFormAccessor.RedeliveryReferenceId]: 'Redelivery',
    [ReferenceSubLineFormAccessor.Status]: 'Status',
    [ReferenceSubLineFormAccessor.IsModificationRequired]: 'Modification',
    [ReferenceSubLineFormAccessor.IsRepairRequired]: 'Repair',
    [ReferenceSubLineFormAccessor.GateOutDate]: 'Gate Out Date',
    [ReferenceSubLineFormAccessor.ReceivedDate]: 'Received Date',
    [ReferenceSubLineFormAccessor.Condition]: 'Condition',
    [ReferenceSubLineFormAccessor.ContainerColor]: 'Container Color',
    [ReferenceSubLineFormAccessor.PickupReferenceId]: 'Pickup Number',
  }[accessor]
}
