import { Link } from 'react-router-dom'

import { itemsRoute, route } from 'constant'

export const getSidebarItems = (id: number) => {
  const itemRoute = `${route.items}/${id}`

  return [
    {
      label: <Link to={`${itemRoute}${itemsRoute.details}`}>Item Details</Link>,
      key: `${itemRoute}${itemsRoute.details}`,
    },
    {
      label: <Link to={`${itemRoute}${itemsRoute.integrations}`}>Integrations</Link>,
      key: `${itemRoute}${itemsRoute.integrations}`,
    },
  ]
}
