import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Warehouse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { UpdateWarehousePayload } from '../types/warehouses'

import { patchWarehouse } from '../queryApi'

export const usePatchWarehouse = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(patchWarehouse, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.WarehouseUpdate)

      queryClient.setQueryData([QueryKey.GetWarehouses], (old: Warehouse[] | undefined) =>
        old ? old.map((warehouse) => (warehouse.id === data.id ? data : warehouse)) : undefined,
      )
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const patch = useCallback(
    ({ id, data }: UpdateWarehousePayload) => {
      mutate({ id, data })
    },
    [mutate],
  )

  return {
    patch,
    ...rest,
  }
}
