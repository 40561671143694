import { FC, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { PurchaseOrderItemsSubLine } from 'api'
import { useFormContext } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'
import { generateUniqueId } from 'utils'

import { EditFormItem } from 'designSystem'
import { DecreaseAssetsQtyModal, Table } from 'components'
import { FormItemEditInputNumber } from 'components/FormElements'
import { getTableFieldName } from 'components/Table/elements/EditableTable/utils/getTableFieldName'
import { useGetPurchaseOrderById } from 'modules/purchaseOrders/routes/PurchaseOrder/hooks/useGetPurchaseOrderById'
import type { PurchaseOrderRowType } from 'modules/purchaseOrders/utils/getTableColumns'

import { getUpdatedSubLines } from './utils/getUpdatedSubLines'
import { columns } from './constants/columns'
import { maxSubLineCount } from 'modules/salesOrders/constants/recordsCount'
import { usePurchaseOrderItemsRequests } from 'modules/purchaseOrders/routes/PurchaseOrder/elements/PurchaseOrderTabs/elements/PurchaseOrderDetails/elements/PurchaseOrderDetailsItems/hooks/usePurchaseOrderItemsRequests'

interface ChangeQuantityProps {
  index: number
  isAddNew: boolean
  isEditMode?: boolean
  record: PurchaseOrderRowType
}

export const ChangeQuantity: FC<ChangeQuantityProps> = ({ index, isAddNew, isEditMode, record }) => {
  const { setValue, triggerSubmit, getValues } = useFormContext()
  const { hash } = useLocation()
  const [openModal, setOpenModal] = useState(false)
  const [desiredQty, setDesiredQty] = useState<number | null | undefined>(null)
  const hashRouteKey = hash.split('/')[1]
  const { data: poData } = useGetPurchaseOrderById(Number(hashRouteKey))
  const { onDeleteSubLines } = usePurchaseOrderItemsRequests({
    purchaseOrderId: Number(hashRouteKey),
  })

  const handleChangeQty = (quantity: number | null | undefined) => {
    setDesiredQty(quantity)

    const subLinesCount = record.subLines?.length || 0
    if (!quantity || quantity > maxSubLineCount || quantity === subLinesCount) return

    if (quantity > subLinesCount) {
      const subLineFieldName = `${PurchaseOrderFormAccessor.Items}.${index}.${PurchaseOrderFormAccessor.SubLines}`
      const prevSubLines = getValues(subLineFieldName)
      const updatedSubLines = getUpdatedSubLines({
        prevSubLines,
        poData,
        increaseQty: quantity - subLinesCount,
      })
      setValue(subLineFieldName, updatedSubLines)
      triggerSubmit?.()
    } else {
      setOpenModal(true)
    }
  }

  const handleCancel = useCallback(() => {
    setValue(`${PurchaseOrderFormAccessor.Items}.${index}.${PurchaseOrderFormAccessor.ItemQty}`, record.qty)
    setDesiredQty(null)
    setOpenModal(false)
  }, [record.qty, index, setValue])

  const dataSource = record?.subLines?.reduce((acc, subLine) => {
    if (!subLine.asset?.isReceived) {
      const { id, ...rest } = subLine
      const newSubLine = {
        key: id ? id.toString() : generateUniqueId(),
        id,
        ...rest,
      }
      acc.push(newSubLine)
    }
    return acc
  }, [] as PurchaseOrderItemsSubLine[])

  const handleSubmit = useCallback(
    (selectedRows: string[]) => {
      onDeleteSubLines(Number(record.id), selectedRows, record.subLines)
      setDesiredQty(null)
      setOpenModal(false)
    },
    [record, onDeleteSubLines],
  )

  return (
    <>
      <EditFormItem
        name={getTableFieldName(PurchaseOrderFormAccessor.ItemQty, 'item', 'items', index, isAddNew)}
        render={({ field }) => (
          <FormItemEditInputNumber
            {...field}
            size="small"
            align="left"
            placeholder="Quantity"
            withConfirmation
            onConfirm={(currentValue) => (isEditMode && !isAddNew ? handleChangeQty?.(currentValue) : undefined)}
          />
        )}
      />
      <DecreaseAssetsQtyModal
        open={openModal}
        desiredQty={desiredQty}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        itemName={record.item?.sku}
        rowsCount={record?.subLines?.length || 0}
        table={<Table<Partial<PurchaseOrderItemsSubLine>> dataSource={dataSource} columns={columns} />}
      />
    </>
  )
}
