import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getCarrierPriceList, GetCarrierPriceListQueryParams } from '../queryApi'

export const useGetCarrierPriceList = (params: GetCarrierPriceListQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetVendorCarrierPriceList, params], () => getCarrierPriceList(params), {
    keepPreviousData: true,
    enabled: !!params?.vendorId,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
