import { FC, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import { route } from 'constant'

import { NotFoundPage } from 'components'
import { AuthorizedLayout } from 'components/Layout'
import { SalesOrders } from '../modules/salesOrders'
import { Assets } from 'modules/assets'
import { ForgotPassword } from 'modules/auth/ForgotPassword'
import { Login } from 'modules/auth/Login'
import { ResetPassword } from 'modules/auth/ResetPassword'
import { Customers } from 'modules/customers'
import { GlobalSettings } from 'modules/globalSettings'
import { Home } from 'modules/home'
import { Items } from 'modules/items'
import { PurchaseOrders } from 'modules/purchaseOrders'
import { References } from 'modules/references'
import { Settings } from 'modules/settings'
import { Vendors } from 'modules/vendors'

import { useGetMe } from './hooks/useGetMe'
import { useGetMySettings } from './hooks/useGetMySettings'
import { UnauthorizedRoutes } from './constants/unauthorizedRoutes'
import { AppModals } from './elements/AppModals'
import { HashRouter } from './elements/HashRouter'
import * as Styled from './styles'

const Router: FC = () => {
  const { pathname } = useLocation()
  const { isLoggedIn, isLoading } = useGetMe({
    enabled: !UnauthorizedRoutes.includes(pathname),
  })
  const { isLoading: isSettingsLoading } = useGetMySettings(isLoggedIn)

  useEffect(() => {
    // TODO: find better way to remove containers
    // Remove pac-containers created by google autocomplete
    document.querySelectorAll('.pac-container').forEach((container) => container.remove())
  }, [pathname])

  // Todo: Get routes for logged in user based on role

  if (isLoading || isSettingsLoading) {
    return (
      <Styled.SpinWrapper>
        <Spin spinning size="large" />
      </Styled.SpinWrapper>
    )
  }

  return (
    <>
      {isLoggedIn ? (
        <AuthorizedLayout>
          <AppModals />
          <HashRouter />
          <Routes>
            <Route path={route.home} element={<Home />} />
            <Route path={`${route.globalSettings}/*`} element={<GlobalSettings />} />
            <Route path={`${route.settings}/*`} element={<Settings />} />
            <Route path={`${route.assets}/*`} element={<Assets />} />
            <Route path={`${route.items}/*`} element={<Items />} />
            <Route path={`${route.vendors}/*`} element={<Vendors />} />
            {/* TODO temporary hide */}
            {/* <Route path={`${route.globalInventory}/*`} element={<GlobalInventory />} /> */}
            <Route path={`${route.pos}/*`} element={<PurchaseOrders />} />
            <Route path={`${route.customers}/*`} element={<Customers />} />
            <Route path={`${route.references}/*`} element={<References />} />
            <Route path={`${route.salesOrders}/*`} element={<SalesOrders />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthorizedLayout>
      ) : (
        <Routes>
          <Route path={route.login} element={<Login />} />
          <Route path={route.forgotPassword} element={<ForgotPassword />} />
          <Route path={route.resetPassword} element={<ResetPassword />} />
          <Route path="*" element={<Navigate to={route.login} />} />
        </Routes>
      )}
    </>
  )
}

export default Router
