import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useItemAddCategory } from 'store'

import { CategoryPayload } from '../types/categoryPayload'

import { postCategoryItem } from '../queryApi'

interface UsePostCategoryItemProps {
  onSuccess: () => void
}

export const usePostCategoryItem = ({ onSuccess }: UsePostCategoryItemProps) => {
  const addCategory = useItemAddCategory()
  const { mutate, ...rest } = useMutation(postCategoryItem, {
    onSuccess: (data) => {
      addCategory(data)
      onSuccess()
    },
  })

  const createCategoryItem = useCallback(
    (payload: CategoryPayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    createCategoryItem,
    ...rest,
  }
}
