import { FC } from 'react'

import { TruckCode } from 'api'
import { route } from 'constant'

import { Container } from 'designSystem'
import { Table, TableTitle } from 'components'

import { useTruckCodes } from './hooks/useTruckCodes'
import { getTableColumns } from './utils/getTableColumns'
import { ListSearch } from './elements/ListSearch'

export const TruckCodesList: FC = () => {
  const { data, isLoading, pagination, onTableChange, getFieldSorter, offsetCount, TableContextProvider } =
    useTruckCodes()
  const columns = getTableColumns(getFieldSorter, offsetCount)

  const truckCodesList: TruckCode[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <Container pa={4} display="flex" fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Truck Codes"
          total={pagination?.total}
          addRoute={route.truckCodesCreate}
          search={<ListSearch />}
        />
        <Table<TruckCode>
          scroll={{ x: 1000 }}
          dataSource={truckCodesList}
          loading={isLoading}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </TableContextProvider>
    </Container>
  )
}
