import { useQuery } from '@tanstack/react-query'

import { EntityId, getCustomerShippingAddresses } from 'api'
import { QueryKey } from 'enums'
import { getAddressString } from 'utils'

export const useGetCustomerShippingAddresses = (id?: EntityId, enabled?: boolean) => {
  const { data, ...rest } = useQuery(
    [QueryKey.GetCustomerShippingAddresses, id],
    () => getCustomerShippingAddresses(id),
    {
      enabled: !!id && enabled,
    },
  )

  const { total, data: items } = data || {}

  const options = items?.map((address) => {
    const label = getAddressString(address)

    return {
      value: address.id,
      label,
    }
  })

  return {
    ...rest,
    isLoading: (rest.isLoading || rest.isFetching) && rest.fetchStatus !== 'idle',
    data: items,
    options,
    total,
  }
}
