import type { PurchaseOrder } from 'api'

import { purchaseOrderItemLabels } from './purchaseOrderItemLabels'

interface PurchaseOrderLabels
  extends Record<
    keyof Omit<PurchaseOrder, 'items' | 'vendorReleaseReferenceNumber' | 'redeliveryReferenceNumber'>,
    string
  > {
  items: typeof purchaseOrderItemLabels
  // Custom
  totalAmount: string
  vendorReleaseRefId: string
}

export const purchaseOrderLabels: PurchaseOrderLabels = {
  id: '',
  zohoId: 'Zoho ID',
  department: 'Department',
  type: 'PO Type',
  vendorId: 'Vendor Name',
  vendorName: 'Vendor Name',
  contacts: 'Contacts',
  expirationDate: 'Expiration Date',
  expectedDate: 'Expected Date',
  warehouseId: 'Warehouse',
  warehouse: 'Warehouse Address',
  redeliveryNumber: 'Redelivery Number',
  vendorReleaseNumber: 'Vendor Release Number',
  vendorReleaseStatus: 'Vendor Release Status',
  depot: 'Depot',
  depotId: 'Depot',
  locationCodeId: 'Location',
  locationCode: 'Location',
  containerColor: 'Container Color',
  paymentTerms: 'Payment Terms',
  dpp: 'DPP',
  status: 'Status',
  isEmailRequired: 'Send Email',
  billingStatus: 'Billing Status',
  receivingStatus: 'Received Status',
  number: 'PO Number',
  notes: 'Internal PO Notes',
  externalPoNotes: 'External PO Notes',
  salesOrderId: 'Sales Order',
  salesOrder: 'Sales Order',
  createdAt: 'Created Time',
  isVendorFavorite: 'Vendor Favorite',
  items: purchaseOrderItemLabels,
  totalAmount: 'Total Amount',
  vendorReleaseRefId: 'Vendor release ID',
}
