import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CarrierPriceListPayload } from '../../../types/carrierPriceListPayload'

import { postCarrierPriceList } from '../queryApi'

export const usePostCarrierPriceList = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(postCarrierPriceList, {
    onSuccess: () => {
      dispatchAppEvent(AppEvent.VendorCarrierPriceListCreate)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: CarrierPriceListPayload) => {
      mutate({ vendorId, data: payload })
    },
    [mutate, vendorId],
  )

  return {
    post,
    ...rest,
  }
}
