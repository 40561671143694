import dayjs, { Dayjs } from 'dayjs'

import { DateStringRegex } from '../constant'
import { DateFormat } from 'types'

export { Dayjs }

export const isValidDateString = (value: any) => {
  if (!value) return false
  return DateStringRegex.test(value)
}

export const formatDate = (date: Dayjs | Date | null | undefined, format: keyof typeof DateFormat = 'Standard') =>
  date ? dayjs(date).format(DateFormat[format]) : ''

export const endOfDayDate = (date?: Dayjs | Date) => dayjs(date).endOf('day')

export const startOfDayDate = (date?: Dayjs | Date) => dayjs(date).startOf('day')

export const isDateBeforeCurrentDateEndOfDay = (date: Dayjs) => date < endOfDayDate()

export const toDate = (date?: Dayjs | Date | string | null) => dayjs(date)

export const isDateBefore = (firstDate: Dayjs | Date, secondDate: Dayjs | Date) => dayjs(firstDate).isBefore(secondDate)

export const isDateAfter = (firstDate: Dayjs | Date, secondDate: Dayjs | Date) => dayjs(firstDate).isAfter(secondDate)
