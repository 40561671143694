import { assetCodesRoute } from 'constant'

import { NotFoundPage } from 'components'

import { AssetCodeCreate } from '../routes/AssetCodeCreate'
import { AssetCodesList } from '../routes/AssetCodesList'
import { AssetCodeView } from '../routes/AssetCodeView'

export const assetCodesRoutes = [
  {
    path: assetCodesRoute.list,
    element: <AssetCodesList />,
  },
  {
    path: assetCodesRoute.create,
    element: <AssetCodeCreate />,
  },
  {
    path: assetCodesRoute.view,
    element: <AssetCodeView />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
