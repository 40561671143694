import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { getResaleMarginPayload } from 'utils'
import { getScrappingSettingsPayload } from 'utils/getScrappingSettingsPayload'

import { showNotification } from 'designSystem'

import { FeedPayload } from '../types/FeedPayload'

import { patchContainerVendorFeed } from '../queryApi'

interface useUpdateContainerVendorFeedProps {
  id: EntityId
  onSettled?: () => void
}

export const useUpdateContainerVendorFeed = ({ id, onSettled }: useUpdateContainerVendorFeedProps) => {
  const { mutate, ...rest } = useMutation(patchContainerVendorFeed, {
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.GetContainerVendorByVendorId, id], data)
      showNotification({ title: 'Changes saved', type: 'success' })
    },
    onSettled,
  })

  const update = useCallback(
    (payload: FeedPayload) => {
      const data = {
        ...getScrappingSettingsPayload(payload.scrappingSettings),
        ...getResaleMarginPayload(payload.resaleMargin),
      }
      mutate({ id, data })
    },
    [mutate, id],
  )

  return {
    update,
    ...rest,
  }
}
