import { Tab } from 'designSystem'

import { UploadTypeOption } from 'modules/vendors/constants/uploadTypeOptions'
import { FeedUrlTab } from '../elements/FeedUrlTab'
import { UploadLocalFile } from 'modules/vendors/elements/UploadLocalFile'

interface GetTabItemsProps {
  vendorId: number
  handleCloseModal: () => void
  uploadTypeOptions: UploadTypeOption[]
}

export const getTabItems = ({ vendorId, handleCloseModal, uploadTypeOptions }: GetTabItemsProps): Tab[] => {
  return [
    {
      key: 'localFile',
      label: 'Local File',
      children: (
        <UploadLocalFile
          vendorId={vendorId}
          handleCloseModal={handleCloseModal}
          uploadTypeOptions={uploadTypeOptions}
        />
      ),
    },
    {
      key: 'feedUrl',
      label: 'Feed URL',
      children: (
        <FeedUrlTab vendorId={vendorId} handleCloseModal={handleCloseModal} uploadTypeOptions={uploadTypeOptions} />
      ),
    },
  ]
}
