import type { ApiQueryParams, Item } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export const getItemsJoinQueryParams = ['locationCode', 'category', 'groupedSKUs']

export interface GetItemsResponse extends Pagination {
  data?: Item[]
}

export type GetItemsQueryParams = ApiQueryParams

export const getItems = (params?: GetItemsQueryParams): Promise<GetItemsResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/items/',
    params,
  })
}
