import { useQuery } from '@tanstack/react-query'

import { getTruckCodes, GetTruckCodesQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetTruckCodes = (params: GetTruckCodesQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetTruckCodes, params], () => getTruckCodes(params), {
    keepPreviousData: true,
  })

  const { total, data: locationCodes } = data || {}

  return {
    data: locationCodes,
    total,
    ...rest,
  }
}
