import { VendorType } from 'api'
import { vendorViewRoute } from 'constant'

import { Carrier } from '../routes/Carrier'
import { ContainerVendor } from '../routes/ContainerVendor'
import { DataMapping } from '../routes/DataMapping'
import { Depot } from '../routes/Depot'
import { Modifications } from '../routes/Modifications'
import { Parts } from '../routes/Parts'
import { VendorDetails } from '../routes/VendorDetails'
import { VendorIntegrations } from '../routes/VendorIntegrations'
import { VendorPurchaseOrders } from '../routes/VendorPurchaseOrders'

export const getVendorRoutesByType = (type?: VendorType[]) => [
  {
    path: vendorViewRoute.details,
    element: <VendorDetails />,
  },
  {
    path: vendorViewRoute.po,
    element: <VendorPurchaseOrders />,
  },
  ...(type?.includes(VendorType.Modifications)
    ? [
        {
          path: vendorViewRoute.modifications,
          element: <Modifications />,
        },
      ]
    : []),
  ...(type?.includes(VendorType.ContainerVendor)
    ? [
        {
          path: `${vendorViewRoute.containerVendor}/*`,
          element: <ContainerVendor />,
        },
      ]
    : []),
  ...(type?.includes(VendorType.Carrier)
    ? [
        {
          path: `${vendorViewRoute.carrier}/*`,
          element: <Carrier />,
        },
      ]
    : []),
  ...(type?.includes(VendorType.Depot)
    ? [
        {
          path: vendorViewRoute.depot,
          element: <Depot />,
        },
      ]
    : []),
  ...(type?.includes(VendorType.Parts)
    ? [
        {
          path: `${vendorViewRoute.parts}/*`,
          element: <Parts />,
        },
      ]
    : []),
  {
    path: vendorViewRoute.integrations,
    element: <VendorIntegrations />,
  },
  {
    path: vendorViewRoute.dataMapping,
    element: <DataMapping />,
  },
]
