import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useForm, useLocationCodes } from 'hooks'
import { paymentTermsOptions, route } from 'constant'

import { Container, Divider, EditFormItem, Spacer, Typography } from 'designSystem'
import {
  ButtonFavorite,
  Card,
  ConfirmationRequiredPopover,
  FormItemEditAddress,
  FormItemEditInput,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsyncGroup,
  StripLoader,
} from 'components'

import { useGetVendorById } from '../../hooks/useGetVendorById'
import { useUpdateVendor } from '../../hooks/useUpdateVendor'
import { getValidationSchema } from './utils/getValidationSchema'
import { maxVendorLocationsCount } from '../../../../constants/maxRecordsCount'
import { VendorDetailsForm, VendorDetailsFormData } from './types/vendorDetailsForm'
import { VendorContacts } from './elements/VendorContacts'
import { VendorTypeFormItem } from './elements/VendorTypeFormItem'
import * as Styled from './styles'

export const VendorDetails: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data } = useGetVendorById({
    id: Number(id),
    onError: () => {
      navigate(route.vendors)
    },
  })
  const { update, isError, isLoading } = useUpdateVendor({ id: Number(id) })

  const onSubmit = useCallback(
    (data: VendorDetailsFormData) => {
      update(data)
    },
    [update],
  )

  const { locationCodes, ...rest } = data || {}

  const { Form, triggerSubmit, setValue } = useForm<VendorDetailsForm>({
    validationSchema: getValidationSchema(data?.type),
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: {
      ...(data
        ? {
            ...rest,
            locationCodeIds: locationCodes?.map(({ id, code }) => ({ value: id, label: code })),
          }
        : {}),
      websiteUrl: data?.websiteUrl || '',
    },
    viewOnlyFields: data?.isOwn ? ['companyName'] : [],
  })

  const handleIsFavorite = useCallback(() => {
    setValue('isFavorite', !data?.isFavorite)
    triggerSubmit()
  }, [data?.isFavorite, setValue, triggerSubmit])

  return (
    <>
      {data && (
        <Container pa={4}>
          <Card maxWidth={390}>
            <Form>
              <StripLoader isLoading={isLoading} />
              <Container gap={16}>
                <Styled.CompanyNameWrapper>
                  <EditFormItem
                    name="companyName"
                    render={({ field }) => (
                      <FormItemEditInput
                        size="large"
                        align="left"
                        {...field}
                        onConfirm={triggerSubmit}
                        popover={<ConfirmationRequiredPopover withPopover />}
                        placeholder="Enter Company Name"
                      />
                    )}
                  />
                </Styled.CompanyNameWrapper>
                <ButtonFavorite onClick={handleIsFavorite} isFavorite={data?.isFavorite} disabled={isLoading} />
              </Container>
              <Divider my={4} />
              <EditFormItem
                name="websiteUrl"
                label="Company Website"
                render={({ field }) => (
                  <FormItemEditInput {...field} placeholder="Enter Company Website" onBlur={triggerSubmit} />
                )}
              />
              <Divider my={4} />
              <VendorTypeFormItem onSubmit={triggerSubmit} />
              <Divider my={4} />
              <Typography fontWeight="xl">Billing Address</Typography>
              <Spacer mb={3} />
              <FormItemEditAddress onConfirm={triggerSubmit} />
              <EditFormItem
                name="paymentTerms"
                label="Payment Terms"
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    placeholder="Payment Terms"
                    onSubmit={triggerSubmit}
                    options={paymentTermsOptions}
                  />
                )}
              />
              <Divider my={4} />
              <Typography fontWeight="xl">Locations</Typography>
              <Spacer mb={3} />
              <FormItemEditSelectSingleAsyncGroup
                name="locationCodeIds"
                label="Location"
                onSubmit={triggerSubmit}
                getItems={useLocationCodes}
                maxFieldsCount={maxVendorLocationsCount}
              />
            </Form>
            <Divider my={4} />
            <VendorContacts id={Number(id)} />
          </Card>
        </Container>
      )}
    </>
  )
}
