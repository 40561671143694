import { FC, FocusEvent, forwardRef, ReactNode, useCallback, useState } from 'react'

import { useFormContext } from 'hooks/useForm'
import { typedMemo } from 'types'

import type { EditSelectMultiProps } from 'designSystem'
import { EditSelectMulti, EditView, SelectSingleOption } from 'designSystem'

import * as Styled from './styles'

export interface FormItemEditSelectMultiProps extends Omit<EditSelectMultiProps, 'name'> {
  name: string
  href?: string
  customPreview?: ReactNode
  isFieldViewOnly?: boolean
}

const FormItemEditSelectMultiBase: FC<FormItemEditSelectMultiProps> = forwardRef(
  (
    {
      disabled,
      href,
      size = 'middle',
      mode = 'multiple',
      width = 160,
      onBlur,
      onSearch,
      name,
      value,
      onChange,
      onClear,
      customPreview,
      placement = 'bottomRight',
      isFieldViewOnly,
      defaultOptions,
      ...props
    },
    ref,
  ) => {
    const [isEdit, setIsEdit] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(defaultOptions)
    const {
      getFieldState,
      formState: { defaultValues },
    } = useFormContext()

    const { error } = getFieldState(name)
    const defaultValue = defaultValues?.[name]

    const handleChange = useCallback(
      (value?: (string | number)[], options?: SelectSingleOption[]) => {
        setSelectedOptions(options)
        onChange?.(value)
      },
      [onChange],
    )

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onBlur?.(event)
        setIsEdit(false)

        if (error) {
          onChange?.(defaultValue)
        }
      },
      [onBlur, onChange, defaultValue, error],
    )

    const handleViewClick = useCallback(() => {
      setIsEdit(true)
    }, [setIsEdit])

    const valuesFromOptions =
      onSearch && value
        ? selectedOptions
        : props.options
            ?.filter((option) => value?.includes(option.value as string | number))
            .map(({ label }) => label as string)

    return (
      <Styled.Wrapper $isLinkAndViewOnly={isFieldViewOnly && !!href && !!value?.length}>
        {isEdit ? (
          <EditSelectMulti
            {...props}
            defaultOptions={defaultOptions}
            width={width}
            onClear={onClear}
            onSearch={onSearch}
            mode={mode}
            size={size}
            onChange={handleChange}
            popupMatchSelectWidth
            name={name}
            placement={placement}
            value={onSearch ? selectedOptions?.map(({ value }) => value) : value}
            error={error?.message}
            autoFocus
            onBlur={handleBlur}
            ref={ref}
            disabled={disabled}
          />
        ) : (
          <>
            {customPreview ? (
              <Styled.CustomPreview
                disabled={disabled}
                onClick={() => {
                  !disabled && handleViewClick()
                }}
              >
                {customPreview}
              </Styled.CustomPreview>
            ) : (
              <EditView
                align="end"
                as={href ? 'select-link' : 'select'}
                withEdit={!!href && !isFieldViewOnly}
                href={href}
                size={size}
                disabled={isFieldViewOnly ? false : disabled}
                value={valuesFromOptions}
                onClick={handleViewClick}
              />
            )}
          </>
        )}
      </Styled.Wrapper>
    )
  },
)

export const FormItemEditSelectMulti = typedMemo(FormItemEditSelectMultiBase)
