import * as Yup from 'yup'

export const dataMappingItemSchema = Yup.object({
  vendorSKU: Yup.string().required('Vendor SKU is required'),
  itemSKU: Yup.string().required('Internal SKU is required'),
})

export const validationSchema = (isAddNew?: boolean) =>
  Yup.object({
    item: isAddNew ? dataMappingItemSchema : Yup.object(),
    list: Yup.array(dataMappingItemSchema),
  })
