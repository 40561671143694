import { FC, useMemo } from 'react'
import { Skeleton } from 'antd'

import {
  ReferenceNumber,
  ReferenceNumberBooking,
  ReferenceNumberPickup,
  ReferenceNumberRedelivery,
  ReferenceNumberStatus,
  ReferenceNumberType,
  ReferenceNumberVendorRelease,
} from 'api'
import { useMemoCompare } from 'hooks'
import { HashRouterComponentProps } from 'types'

import { Drawer } from 'designSystem'

import { useGetReferenceNumberById } from './hooks/useGetReferenceNumberById'
import { Booking } from './elements/Booking'
import { Pickup } from './elements/Pickup'
import { Redelivery } from './elements/Redelivery'
import { ReferenceNumberTitle } from './elements/ReferenceNumberTitle'
import { VendorRelease } from './elements/VendorRelease'

export const Reference: FC<HashRouterComponentProps> = ({ hash }) => {
  const referenceId = Number(hash.split('/')?.[1])

  const { data, isLoading } = useGetReferenceNumberById(referenceId)

  const memoizedData = useMemoCompare(data) as ReferenceNumber | undefined

  const canEditOnlyStatus = [
    ReferenceNumberStatus.Closed,
    ReferenceNumberStatus.Voided,
    ReferenceNumberStatus.Received,
  ].includes(memoizedData?.status as ReferenceNumberStatus)

  const component = useMemo(() => {
    if (!memoizedData) {
      return null
    }

    return {
      [ReferenceNumberType.Booking]: (
        <Booking data={memoizedData as ReferenceNumberBooking} canEditOnlyStatus={canEditOnlyStatus} />
      ),
      [ReferenceNumberType.Redelivery]: (
        <Redelivery data={memoizedData as ReferenceNumberRedelivery} canEditOnlyStatus={canEditOnlyStatus} />
      ),
      [ReferenceNumberType.VendorRelease]: (
        <VendorRelease data={memoizedData as ReferenceNumberVendorRelease} canEditOnlyStatus={canEditOnlyStatus} />
      ),
      [ReferenceNumberType.Pickup]: (
        <Pickup data={memoizedData as ReferenceNumberPickup} canEditOnlyStatus={canEditOnlyStatus} />
      ),
    }[memoizedData.type]
  }, [memoizedData, canEditOnlyStatus])

  return (
    <Drawer
      hashUrl={hash}
      width={930}
      title={
        isLoading && !memoizedData?.number ? (
          <Skeleton.Input active />
        ) : (
          !!memoizedData && <ReferenceNumberTitle data={memoizedData} canEdit={!canEditOnlyStatus} />
        )
      }
      isLoading={isLoading}
    >
      {component}
    </Drawer>
  )
}
