import { Asset, ReferenceNumberType } from 'api'
import { QueryKey } from 'enums'
import { assetsRoute } from 'constant'
import { AppModule } from 'types'
import { getEqualsFilter } from 'utils'

import { assetBookingReferencesVisibleColumnsOptions } from '../elements/AssetReferenceNumbers/constants/assetBookingReferencesVisibleColumns'
import { assetRedeliveryReferencesVisibleColumnsOptions } from '../elements/AssetReferenceNumbers/constants/assetRedeliveryReferencesVisibleColumns'
import { AssetReferenceNumbers } from '../elements/AssetReferenceNumbers'
import { getAssetBookingReferencesTableColumns } from '../elements/AssetReferenceNumbers/utils/getAssetBookingReferencesTableColumns'
import { getAssetRedeliveryReferencesTableColumns } from '../elements/AssetReferenceNumbers/utils/getAssetRedeliveryReferencesTableColumns'

import { AssetDetails } from '../routes/AssetDetails'
import { AssetIntegrations } from '../routes/AssetIntegrations'
import { AssetPurchaseOrder } from '../routes/AssetPurchaseOrder'

export const getAssetRoutes = (data?: Asset) => [
  {
    path: assetsRoute.details,
    element: <AssetDetails />,
  },
  {
    path: assetsRoute.integrations,
    element: <AssetIntegrations />,
  },
  {
    path: assetsRoute.purchaseOrders,
    element: <AssetPurchaseOrder data={data?.purchaseOrderSubLine?.poItem?.purchaseOrder} />,
  },
  ...(data?.id
    ? [
        {
          path: assetsRoute.bookingNumbers,
          element: (
            <AssetReferenceNumbers
              title="Booking Numbers"
              key={assetsRoute.bookingNumbers}
              module={AppModule.AssetBookingNumbers}
              queryKey={QueryKey.GetAssetBookingNumbers}
              defaultFilters={{
                ...getEqualsFilter('items.subLines.asset.id', { value: data?.id }),
                ...getEqualsFilter('type', { value: ReferenceNumberType.Booking }),
              }}
              getTableColumns={getAssetBookingReferencesTableColumns}
              visibleColumnsOptions={assetBookingReferencesVisibleColumnsOptions}
            />
          ),
        },
        {
          path: assetsRoute.redeliveryNumbers,
          element: (
            <AssetReferenceNumbers
              title="Redelivery Numbers"
              key={assetsRoute.redeliveryNumbers}
              module={AppModule.AssetRedeliveryNumbers}
              queryKey={QueryKey.GetAssetRedeliveryNumbers}
              defaultFilters={{
                ...getEqualsFilter('items.subLines.asset.id', { value: data?.id }),
                ...getEqualsFilter('type', { value: ReferenceNumberType.Redelivery }),
              }}
              getTableColumns={getAssetRedeliveryReferencesTableColumns}
              visibleColumnsOptions={assetRedeliveryReferencesVisibleColumnsOptions}
            />
          ),
        },
      ]
    : []),
]
