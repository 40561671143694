import { useQuery } from '@tanstack/react-query'

import { getAssetCodes, GetAssetCodesQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetAssetCodes = (params: GetAssetCodesQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetAssetCodes, params], () => getAssetCodes(params), {
    keepPreviousData: true,
  })

  const { total, data: assetCodes } = data || {}

  return {
    data: assetCodes,
    total,
    ...rest,
  }
}
