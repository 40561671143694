import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import { LocationCode, PurchaseOrder, VendorDepotSettings } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { getPurchaseOrderTypeLabel, hashRoute } from 'constant'
import { formatDate, getFormattedPriceWithCurrency } from 'utils'

import { Container, Typography } from 'designSystem'
import { PurchaseOrderStatusTag, TableColumnKey, TableColumnsType } from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<PurchaseOrder> => {
  return [
    {
      title: getAccessorTitle(Accessor.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => offsetCount + index + 1,
    },
    {
      title: getAccessorTitle(Accessor.Number),
      key: Accessor.Number,
      dataIndex: Accessor.Number,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter(Accessor.Number),
      ellipsis: true,
      render: (number: string, record) => {
        return (
          <Link target="_blank" to={hashRoute.po(record.id)}>
            <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
              {number}
            </Typography>
          </Link>
        )
      },
    },

    {
      title: getAccessorTitle(Accessor.Type),
      key: Accessor.Type,
      dataIndex: Accessor.Type,
      width: 150,
      ellipsis: true,
      render: (type) => getPurchaseOrderTypeLabel(type),
    },
    {
      title: getAccessorTitle(Accessor.LocationCode),
      key: Accessor.LocationCode,
      dataIndex: Accessor.LocationCode,
      width: 150,
      ellipsis: true,
      render: (locationCode: LocationCode | null) => {
        const tooltipTitle = `${locationCode || ''}`
        if (locationCode) {
          return (
            <Tooltip title={tooltipTitle} overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
              <Container display="flex" fd="column">
                {locationCode.code}
              </Container>
            </Tooltip>
          )
        }

        return '-'
      },
    },
    {
      title: getAccessorTitle(Accessor.Depot),
      key: Accessor.Depot,
      dataIndex: Accessor.Depot,
      width: 150,
      ellipsis: true,
      render: (depot: VendorDepotSettings | null) => {
        const tooltipTitle = `${depot || ''}`
        if (depot) {
          return (
            <Tooltip title={tooltipTitle} overlayInnerStyle={{ whiteSpace: 'pre-line' }}>
              <Container display="flex" fd="column">
                {depot.code}
              </Container>
            </Tooltip>
          )
        }

        return '-'
      },
    },
    {
      title: getAccessorTitle(Accessor.ExpectedDate),
      key: Accessor.ExpectedDate,
      dataIndex: Accessor.ExpectedDate,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter(Accessor.ExpectedDate),
      ellipsis: true,
      render: (expectedDate) => formatDate(expectedDate),
    },
    {
      title: getAccessorTitle(Accessor.ExpirationDate),
      key: Accessor.ExpirationDate,
      dataIndex: Accessor.ExpirationDate,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter(Accessor.ExpirationDate),
      ellipsis: true,
      render: (expirationDate) => formatDate(expirationDate),
    },
    {
      title: getAccessorTitle(Accessor.CreatedAt),
      key: Accessor.CreatedAt,
      dataIndex: Accessor.CreatedAt,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter(Accessor.CreatedAt),
      ellipsis: true,
      render: (createdAt) => formatDate(createdAt),
    },
    {
      title: getAccessorTitle(Accessor.Qty),
      key: Accessor.Qty,
      dataIndex: Accessor.Qty,
      width: 100,
      ellipsis: true,
      render: (value) => value || 0,
    },
    {
      title: getAccessorTitle(Accessor.ItemsTotal),
      key: Accessor.ItemsTotal,
      dataIndex: Accessor.ItemsTotal,
      width: 150,
      sorter: true,
      sortOrder: getFieldSorter(Accessor.ItemsTotal),
      ellipsis: true,
      render: (itemsTotal) => {
        const itemsTotalFormatted = getFormattedPriceWithCurrency(itemsTotal)
        return (
          <Typography size="xs" fontWeight="xl">
            {itemsTotalFormatted}
          </Typography>
        )
      },
    },
    {
      title: getAccessorTitle(Accessor.Status),
      key: Accessor.Status,
      dataIndex: Accessor.Status,
      width: 150,
      ellipsis: true,
      render: (status) => status && <PurchaseOrderStatusTag status={status} />,
    },
    {
      title: getAccessorTitle(Accessor.Notes),
      key: Accessor.Notes,
      dataIndex: Accessor.Notes,
      width: 200,
      ellipsis: true,
      render: (notes) => notes || '-',
    },
  ]
}
