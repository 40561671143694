import { useQuery } from '@tanstack/react-query'

import { getPurchaseOrders, GetPurchaseOrdersQueryParams } from 'api'
import { QueryKey } from 'enums'
import { PurchaseOrderFormAccessor } from 'types'

export const useGetVendorPurchaseOrders = (queryParams: GetPurchaseOrdersQueryParams) => {
  const searchParams = queryParams?.s
  const isSearchInItems = searchParams !== undefined && searchParams.includes('items.item.sku')
  const params = {
    ...queryParams,
    join: [`${PurchaseOrderFormAccessor.LocationCode}`, `${PurchaseOrderFormAccessor.Depot}`],
  }

  if (isSearchInItems) {
    params.join.push(
      `${PurchaseOrderFormAccessor.Items}`,
      `${PurchaseOrderFormAccessor.Items}.${PurchaseOrderFormAccessor.Item}`,
    )
  }

  const { data, ...rest } = useQuery([QueryKey.GetVendorPurchaseOrders, params], () => getPurchaseOrders(params), {
    keepPreviousData: true,
  })

  const { total, data: purchaseOrders } = data || {}

  return {
    data: purchaseOrders,
    total,
    ...rest,
  }
}
