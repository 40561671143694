import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { ApiHeader, EntityId, PurchaseOrderItem } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'
import { useSetPurchaseOrderRequestId } from 'store'

import { showNotification } from 'designSystem'

import { patchPurchaseOrderItem } from '../queryApi'

export const usePatchPurchaseOrderItem = (purchaseOrderId: EntityId) => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const setPurchaseOrderRequestId = useSetPurchaseOrderRequestId()

  const { mutate, mutateAsync, ...rest } = useMutation(patchPurchaseOrderItem, {
    onSuccess: ({ data, headers }, { purchaseOrderId }) => {
      setPurchaseOrderRequestId(headers[ApiHeader.RequestId])
      dispatchAppEvent(AppEvent.PurchaseOrderItemUpdated)
      queryClient.setQueryData(
        [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
        (prev: PurchaseOrderItem[] | undefined) => {
          if (!prev) return undefined
          return prev.map((item) => {
            if (item.id === data.id) {
              return data
            } else {
              return item
            }
          })
        },
      )
      showNotification({ title: 'Changes saved', type: 'success' })
    },
  })

  const patch = useCallback(
    (itemId: EntityId, payload: Partial<PurchaseOrderItem>) => {
      mutate({ purchaseOrderId, itemId, data: payload })
    },
    [mutate, purchaseOrderId],
  )

  const patchAsync = useCallback(
    async (itemId: EntityId, payload: Partial<PurchaseOrderItem>) => {
      return mutateAsync({ purchaseOrderId, itemId, data: payload })
    },
    [mutateAsync, purchaseOrderId],
  )

  return {
    patch,
    patchAsync,
    ...rest,
  }
}
