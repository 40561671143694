import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'

import { getModificationFeedByVendorId } from '../routes/Vendor/queryApi'

interface UseGetModificationFeedByVendorIdProps {
  id: EntityId
  enabled?: boolean
}

export const useGetModificationFeedByVendorId = ({ id, enabled = true }: UseGetModificationFeedByVendorIdProps) => {
  const { isFetching, isLoading, fetchStatus, data, ...rest } = useQuery(
    [QueryKey.GetModificationFeedByVendorId, id],
    () => getModificationFeedByVendorId(id),
    {
      enabled: !!id && enabled,
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
