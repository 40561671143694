import { ConfigProvider, Table as TableBasic } from 'antd'
import type { ColumnsType, TableProps as TableBasicProps } from 'antd/lib/table'

import { Button, Container, Icon } from 'designSystem'

import { tablePageLimits } from './constants/tablePageLimits'
import { TableColumnKey } from './types/tableColumnKey'
import { TablePagination } from './elements'
import * as Styled from './styles'

import { StripLoader } from '../StripLoader'

interface TableCustomProps {
  viewOnlyExceptFirstRow?: boolean
  viewOnly?: boolean
  isEditLoading?: boolean
  withBorderRadius?: boolean
  zebraStripedStyle?: boolean
  onAddNewRow?: () => void
}
export type TableColumnsType<T extends object> = ColumnsType<T>
type TableProps<T extends object> = TableBasicProps<T> & TableCustomProps

export const Table = <T extends object & TableCustomProps>({
  pagination,
  viewOnlyExceptFirstRow,
  viewOnly,
  isEditLoading,
  withBorderRadius = true,
  zebraStripedStyle,
  onAddNewRow,
  columns,
  ...props
}: TableProps<T>) => {
  let isActionColumn = false

  const formattedColumns = (columns || []).map(({ width, fixed, className, ...column }) => {
    const isActionsColumnKey = column.key === TableColumnKey.HoverActions
    const isIndexColumnKey = column.key === TableColumnKey.Index
    isActionColumn = isActionsColumnKey || column.key === TableColumnKey.Actions

    const classNames = [className, isActionsColumnKey && 'hover-actions-column', isIndexColumnKey && 'index-column']
      .filter(Boolean)
      .join(' ')

    return {
      ...column,
      className: classNames,
      width: width || (isActionsColumnKey && 30) || (isIndexColumnKey && 42) || undefined,
      fixed: fixed || (isActionsColumnKey && 'right') || undefined,
    }
  })
  const withoutHeader = formattedColumns?.every(({ title }) => !title)
  const withEmptyColumnColumns = isActionColumn
    ? formattedColumns
    : [...formattedColumns, { key: TableColumnKey.Empty }]

  return (
    <Styled.TableComponentWrapper
      display="flex"
      fd="column"
      fg={1}
      $withBorderRadius={withBorderRadius}
      $withoutHeader={withoutHeader}
      $zebraStripedStyle={zebraStripedStyle}
    >
      <StripLoader isLoading={isEditLoading} />
      <ConfigProvider renderEmpty={onAddNewRow ? () => <>No data</> : undefined}>
        <TableBasic
          {...props}
          pagination={false}
          columns={withEmptyColumnColumns}
          expandable={{
            ...props.expandable,
            columnWidth: 25,
            expandIcon: ({
              expanded,
              onExpand,
              record,
            }: {
              expanded: boolean
              onExpand: (record: T, e: any) => void
              record: T
            }) => {
              if (!props.expandable?.rowExpandable?.(record)) {
                return null
              }
              return expanded ? (
                <Styled.ExpandedWrapper>
                  <Icon icon="arrowUp" onClick={(e) => onExpand(record, e)} />
                </Styled.ExpandedWrapper>
              ) : (
                <Icon icon="arrowRight" onClick={(e) => onExpand(record, e)} />
              )
            },
          }}
          rowClassName={(record, index) => {
            const isIndexOdd = (index + 1) % 2 === 0 ? 'odd' : 'even'

            if (zebraStripedStyle) {
              return (viewOnlyExceptFirstRow && index !== 0) || viewOnly || isEditLoading
                ? `disabled-row row-${isIndexOdd}`
                : `row-${isIndexOdd}`
            }

            return (viewOnlyExceptFirstRow && index !== 0) || viewOnly || isEditLoading ? 'disabled-row' : ''
          }}
          components={{
            table: Styled.TableWrapper,
            header: {
              wrapper: (headerProps: React.HTMLAttributes<HTMLTableSectionElement>) => (
                <Styled.WrapperHeader
                  {...headerProps}
                  $zebraStripedStyle={zebraStripedStyle}
                  $withBorderRadius={withBorderRadius}
                />
              ),
            },
            body: {
              wrapper: Styled.BodyWrapper,
              row: Styled.Row,
            },
          }}
        />
      </ConfigProvider>
      {onAddNewRow && (
        <Container bg="white" pa={2}>
          <Button type="link" icon="plus" onClick={onAddNewRow}>
            Add
          </Button>
        </Container>
      )}
      {pagination && !!pagination.total && pagination.total > tablePageLimits[0] && (
        <TablePagination withoutDefaultPadding={!withBorderRadius} pageSizeOptions={tablePageLimits} {...pagination} />
      )}
    </Styled.TableComponentWrapper>
  )
}
