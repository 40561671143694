import { useQuery } from '@tanstack/react-query'

import { getAssets, GetAssetsQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetAssets = (params: GetAssetsQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetAssets, params], () => getAssets(params), {
    keepPreviousData: true,
  })

  const { total, data: assets } = data || {}

  return {
    data: assets,
    total,
    ...rest,
  }
}
