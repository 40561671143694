export enum PurchaseOrderFormAccessor {
  Department = 'department',
  Type = 'type',
  VendorId = 'vendorId',
  VendorName = 'vendorName',
  ContactsIds = 'contactsIds',
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  ExpectedDate = 'expectedDate',
  DeliveryType = 'deliveryType',
  WarehouseId = 'warehouseId',
  SalesOrderId = 'salesOrderId',
  Warehouse = 'warehouse',
  ReferenceNumber = 'referenceNumber',
  RedeliveryNumber = 'redeliveryNumber',
  VendorReleaseNumber = 'vendorReleaseNumber',
  DepotId = 'depotId',
  Depot = 'depot',
  LocationCodeId = 'locationCodeId',
  LocationCode = 'locationCode',
  ContainerColor = 'containerColor',
  PaymentTerms = 'paymentTerms',
  Dpp = 'dpp',
  Status = 'status',
  Contacts = 'contacts',
  IsEmailRequired = 'isEmailRequired',
  BillingStatus = 'billingStatus',
  ReceivingStatus = 'receivingStatus',
  PaymentStatus = 'paymentStatus',
  Number = 'number',
  Id = 'id',
  Notes = 'notes',
  ExternalPoNotes = 'externalPoNotes',
  RedeliveryRefId = 'redeliveryRefId',
  VendorReleaseRefId = 'vendorReleaseRefId',
  SalesOrder = 'salesOrder',
  ShouldGenerateReferenceNumber = 'shouldGenerateReferenceNumber',
  VendorReleaseReferenceNumber = 'vendorReleaseReferenceNumber',
  RedeliveryReferenceNumber = 'redeliveryReferenceNumber',

  // Purchase Order Item Fields
  Item = 'item',
  Items = 'items',
  ItemQty = 'qty',
  ItemId = 'itemId',
  ItemRate = 'rate',
  ItemBilledQty = 'billedQty',
  ItemReceivedQty = 'receivedQty',

  // Purchase Order SubLine Fields
  SubLines = 'subLines',
  SubLineExpectedDeliveryDate = 'expectedDeliveryDate',
  SubLineClosedDate = 'closedDate',
  SubLineVendorDepotId = 'vendorDepotId',
  SubLineAssetNumber = 'assetNumber',
  SubLineBillingReference = 'billingReference',
  SubLineIsReceived = 'isReceived',
  SubLineIsBilled = 'isBilled',
}
