import { Link } from 'react-router-dom'

import { SalesOrder } from 'api'
import type { TableField, TableSortingOrder } from 'hooks'
import { hashRoute } from 'constant'

import { Typography } from 'designSystem'
import { TableColumnKey, TableColumnsType, ZohoLink } from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

export const getTableColumns = (
  getFieldSorter: (field: TableField) => TableSortingOrder,
  offsetCount: number,
): TableColumnsType<SalesOrder> => {
  return [
    {
      title: getAccessorTitle(Accessor.Index),
      key: TableColumnKey.Index,
      ellipsis: true,
      render: (value, record, index) => offsetCount + index + 1,
    },
    {
      title: getAccessorTitle(Accessor.Number),
      key: Accessor.Number,
      dataIndex: Accessor.Number,
      width: 300,
      ellipsis: true,
      render: (number: string, record) => (
        <Link to={hashRoute.so(record.id)}>
          <Typography color="primary" size="xs" fontWeight="l" lineEllipsis={1}>
            {number}
          </Typography>
        </Link>
      ),
    },
    {
      title: getAccessorTitle(Accessor.ZohoId),
      key: Accessor.ZohoId,
      dataIndex: Accessor.ZohoId,
      width: 200,
      ellipsis: true,
      render: (value) => <ZohoLink module="zohoSaleOrdersLink" id={value} />,
    },
    //TODO need join from backend
    // {
    //   title: getAccessorTitle(Accessor.Customer),
    //   key: Accessor.Customer,
    //   dataIndex: Accessor.Customer,
    //   width: 200,
    //   ellipsis: true,
    // },
    // TODO zoho invoice. not implemented yet
    // {
    //   title: getAccessorTitle(Accessor.Invoice),
    //   key: Accessor.Invoice,
    //   dataIndex: Accessor.Invoice,
    //   width: 200,
    //   ellipsis: true,
    // },
    // TODO need join from backend
    // {
    //   title: getAccessorTitle(Accessor.SalesRepresentative),
    //   key: Accessor.SalesRepresentative,
    //   dataIndex: Accessor.SalesRepresentative,
    //   width: 200,
    //   ellipsis: true,
    // },
    // TODO need join from backend
    // {
    //   title: getAccessorTitle(Accessor.Dispatcher),
    //   key: Accessor.Dispatcher,
    //   dataIndex: Accessor.Dispatcher,
    //   width: 200,
    //   ellipsis: true,
    // },
    // {
    //   title: getAccessorTitle(Accessor.Status),
    //   key: Accessor.Status,
    //   dataIndex: Accessor.Status,
    //   width: 150,
    //   ellipsis: true,
    //   render: (status: SalesOrderStatus) => <SalesOrderStatusTag status={status} />,
    // },
    // {
    //   title: getAccessorTitle(Accessor.CreatedAt),
    //   key: Accessor.CreatedAt,
    //   dataIndex: Accessor.CreatedAt,
    //   width: 150,
    //   sorter: true,
    //   sortOrder: getFieldSorter(Accessor.CreatedAt),
    //   ellipsis: true,
    //   render: (expirationDate) => formatDate(expirationDate),
    // },
    // {
    //   title: getAccessorTitle(Accessor.ExpectedDate),
    //   key: Accessor.ExpectedDate,
    //   dataIndex: Accessor.ExpectedDate,
    //   width: 150,
    //   sorter: true,
    //   sortOrder: getFieldSorter(Accessor.ExpectedDate),
    //   ellipsis: true,
    //   render: (expirationDate) => formatDate(expirationDate) || '-',
    // },
    // TODO need field from backend
    // {
    //   title: getAccessorTitle(Accessor.ItemSKUs),
    //   key: Accessor.ItemSKUs,
    //   dataIndex: Accessor.ItemSKUs,
    //   width: 150,
    //   sorter: true,
    //   sortOrder: getFieldSorter(Accessor.ExpectedDate),
    //   ellipsis: true,
    // },
    // {
    //   title: getAccessorTitle(Accessor.Total),
    //   key: Accessor.Total,
    //   dataIndex: Accessor.Total,
    //   width: 150,
    //   ellipsis: true,
    //   render: (total: number) => {
    //     const itemsTotalFormatted = getFormattedPriceWithCurrency(total)
    //     return (
    //       <Typography size="xs" fontWeight="xl">
    //         {itemsTotalFormatted}
    //       </Typography>
    //     )
    //   },
    // },
    // TODO need field from backend
    // {
    //   title: getAccessorTitle(Accessor.Modification),
    //   key: Accessor.Modification,
    //   dataIndex: Accessor.Modification,
    //   width: 150,
    //   ellipsis: true,
    // },
    // {
    //   title: getAccessorTitle(Accessor.AccountingStatus),
    //   key: Accessor.AccountingStatus,
    //   dataIndex: Accessor.AccountingStatus,
    //   width: 150,
    //   ellipsis: true,
    //   render: (status: SalesOrderAccountingStatus) => <SalesOrderAccountingStatusTag status={status} />,
    // },
    // {
    //   title: getAccessorTitle(Accessor.GeneralNotes),
    //   key: Accessor.GeneralNotes,
    //   dataIndex: Accessor.GeneralNotes,
    //   width: 200,
    //   ellipsis: true,
    //   render: (notes) => notes || '-',
    // },
  ]
}
