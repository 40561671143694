import { useQuery } from '@tanstack/react-query'

import { QueryKey } from 'enums'

import { getPurchaseOrderItems, GetPurchaseOrderItemsPayload } from '../queryApi'

export const useGetPurchaseOrderItems = ({ purchaseOrderId }: GetPurchaseOrderItemsPayload) => {
  const { data, isLoading, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetPurchaseOrderItems, purchaseOrderId],
    () => getPurchaseOrderItems({ purchaseOrderId }),
    {
      keepPreviousData: true,
      enabled: !!purchaseOrderId,
    },
  )

  return {
    data,
    isLoading: isLoading && fetchStatus !== 'idle',
    ...rest,
  }
}
