import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { VendorResalePriceOverwriteRule, VendorType } from 'api'

import { getPatchPayload } from '../utils/getPatchPayload'
import { ResaleRuleFormPayload } from '../types/resaleRuleCreateForm'

import { useDeleteResaleRule } from './useDeleteResaleRule'
import { usePatchResaleRule } from './usePatchResaleRule'
import { usePostResaleRule } from './usePostResaleRule'

export const useResaleRuleRequests = (
  setIsAddNew: (isAddNew: boolean) => void,
  data: VendorResalePriceOverwriteRule[],
  type: VendorType.ContainerVendor | VendorType.Parts,
) => {
  const { id: vendorId } = useParams()

  const {
    post,
    isSuccess: isPostSuccess,
    isError: isPostError,
    isLoading: isPostLoading,
  } = usePostResaleRule(Number(vendorId), type)

  const {
    patch,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
    isLoading: isPatchLoading,
  } = usePatchResaleRule(Number(vendorId), type)

  const {
    remove,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeleteResaleRule(Number(vendorId), type)

  const onSubmitPost = useCallback(
    (payload: ResaleRuleFormPayload) => {
      if (payload.item) {
        post(payload.item)
        setIsAddNew(false)
      }
    },
    [post, setIsAddNew],
  )

  const onSubmitPatch = useCallback(
    (payload: ResaleRuleFormPayload) => {
      const patchData = getPatchPayload(payload, data)
      patchData?.forEach(({ id, payload }) => {
        patch(id as number, payload)
      })
    },
    [patch, data],
  )

  const onDelete = useCallback(
    (equipmentId?: number) => {
      if (equipmentId) {
        remove(equipmentId)
      }
    },
    [remove],
  )

  return {
    isSubmitSuccess: isPostSuccess || isPatchSuccess || isDeleteSuccess,
    isSubmitError: isPostError || isPatchError || isDeleteError,
    isEditLoading: isPostLoading || isPatchLoading || isDeleteLoading,
    onSubmitPost,
    onSubmitPatch,
    onDelete,
  }
}
