import { locationCodesRoute } from 'constant'

import { NotFoundPage } from 'components'

import { LocationCodesCreate } from '../routes/LocationCodesCreate'
import { LocationCodesList } from '../routes/LocationCodesList'
import { LocationCodeView } from '../routes/LocationCodeView'

export const locationCodesRoutes = [
  {
    path: locationCodesRoute.list,
    element: <LocationCodesList />,
  },
  {
    path: locationCodesRoute.create,
    element: <LocationCodesCreate />,
  },
  {
    path: locationCodesRoute.view,
    element: <LocationCodeView />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
