import { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'

import { useForm } from 'hooks'
import { distanceUnits, route } from 'constant'

import { Button, Checkbox, Container, Divider, FormItem, Input, InputNumber, Spacer, Typography } from 'designSystem'
import { Breadcrumbs, Card } from 'components'

import { useTruckCodeCreate } from './hooks/useCreateTruckCode'
import { validationSchema } from '../utils/validationSchema'
import { breadcrumbsData } from './constants/breadcrumbsData'
import { TruckCodeForm } from '../../types/TruckCodeForm'

import { CreateTruckCodePayload } from './queryApi'

export const TruckCodeCreate: FC = () => {
  const { Form, handleSubmit } = useForm<TruckCodeForm>({ validationSchema })
  const { createTruckCode, isLoading } = useTruckCodeCreate()

  const onSubmit = useCallback(
    (data: CreateTruckCodePayload) => {
      createTruckCode(data)
    },
    [createTruckCode],
  )

  return (
    <>
      <Breadcrumbs breadcrumbItems={breadcrumbsData} />
      <Container display="flex" jc="center" fd="column" ai="center">
        <Container display="block" maxWidth={500}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card mb={5} mt={5}>
              <Typography fontWeight="xl" size="l">
                Create Truck Code
              </Typography>
              <Spacer mb={5} />
              <Row>
                <Col span={24}>
                  <FormItem name="title" label="Title">
                    <Input placeholder="Type title" />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem name="code" label="Truck Code">
                    <Input placeholder="Type Truck Code" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem name="bedLengthInFt" label="Bed Length">
                    <InputNumber placeholder="0" suffix={distanceUnits.Ft} />
                  </FormItem>
                </Col>
              </Row>
              <Divider my={4} />
              <Col span={24}>
                <FormItem name="allow5ftOverhang">
                  <Checkbox>Allow 5ft overhang</Checkbox>
                </FormItem>
              </Col>
            </Card>
            <Container jc="space-between">
              <Link to={route.truckCodes}>
                <Button type="default">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Create
              </Button>
            </Container>
          </Form>
        </Container>
      </Container>
    </>
  )
}
