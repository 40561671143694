import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getAssetById } from '../queryApi'

export const useGetAssetById = (assetId?: EntityId) => {
  const navigate = useNavigate()
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetAssetById, assetId],
    () => getAssetById(assetId),
    {
      enabled: !!assetId,
      onError: () => {
        navigate(route.assets)
      },
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
