import { useQuery } from '@tanstack/react-query'

import { PurchaseOrderEmail } from 'api'
import { QueryKey } from 'enums'

import { getPurchaseOrderEmailTemplate } from '../queryApi'

interface UseGetEmailTemplateProps {
  key?: PurchaseOrderEmail
}

export const useGetPurchaseOrderEmailTemplate = ({ key }: UseGetEmailTemplateProps) => {
  const { isFetching, isLoading, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetEmailTemplateByType, key],
    () => getPurchaseOrderEmailTemplate(key),
    {
      enabled: !!key,
    },
  )

  return {
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
