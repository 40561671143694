import { FC, useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'

import { VendorType } from 'api'
import { useForm } from 'hooks'
import { getMarginDefaultValues, getScrappingSettingsDefaultValues } from 'utils'

import { Spacer } from 'designSystem'
import { Spinner } from 'components'
import { useGetContainerVendorByVendorId } from 'modules/vendors/hooks/useGetContainerVendorByVendorId'
import { useGetModificationFeedByVendorId } from 'modules/vendors/hooks/useGetModificationFeedByVendorId'
import { useGetPartsByVendorId } from 'modules/vendors/hooks/useGetPartsByVendorId'
import { useUpdateContainerVendorFeed } from 'modules/vendors/hooks/useUpdateContainerVendorFeed'
import { useUpdateModificationFeed } from 'modules/vendors/hooks/useUpdateModificationFeed'
import { useUpdatePartsFeed } from 'modules/vendors/hooks/useUpdatePartsFeed'

import { getDefaultValues } from '../../utils/getDefaultValues'
import { validationSchema } from './utils/validationSchema'
import { UploadTypeOption } from 'modules/vendors/constants/uploadTypeOptions'
import { FeedUrlForm } from './types/FeedUrlForm'
import { PatchFeedPayload } from './types/PatchFeedPayload'
import { FeedSettings } from 'modules/vendors/elements/FeedSettings'
import { ConfirmItem } from 'modules/vendors/elements/UploadLocalFile/elements/ConfirmItem'
import { VendorTypeItem } from 'modules/vendors/elements/UploadLocalFile/elements/VendorTypeItem'
import * as Styled from './styles'
interface FeedUrlTabProps {
  vendorId: number
  handleCloseModal: () => void
  uploadTypeOptions: UploadTypeOption[]
}

export const FeedUrlTab: FC<FeedUrlTabProps> = ({ vendorId, handleCloseModal, uploadTypeOptions }) => {
  const { update: updateModificationFeed, isLoading: isModificationFeedLoading } = useUpdateModificationFeed({
    id: vendorId,
    onSettled: handleCloseModal,
  })
  const { update: updateContainerVendorFeed, isLoading: isContainerVendorFeedLoading } = useUpdateContainerVendorFeed({
    id: vendorId,
    onSettled: handleCloseModal,
  })
  const { update: updatePartsFeed, isLoading: isPartsLoading } = useUpdatePartsFeed({
    id: vendorId,
    onSettled: handleCloseModal,
  })

  const isContainerVendorFeedAvailable = uploadTypeOptions.some((option) => option.value === VendorType.ContainerVendor)
  const isModificationFeedData = uploadTypeOptions.some((option) => option.value === VendorType.Modifications)
  const isPartsFeedData = uploadTypeOptions.some((option) => option.value === VendorType.Parts)

  const { data: containerVendorFeedData, isLoading: isLoadingContainerVendorData } = useGetContainerVendorByVendorId({
    id: Number(vendorId),
    enabled: isContainerVendorFeedAvailable,
  })
  const { data: modificationFeedData, isLoading: isLoadingModificationData } = useGetModificationFeedByVendorId({
    id: Number(vendorId),
    enabled: isModificationFeedData,
  })
  const { data: partsData, isLoading: isLoadingPartsData } = useGetPartsByVendorId({
    id: Number(vendorId),
    enabled: isPartsFeedData,
  })

  const onSubmit = useCallback(
    (data: PatchFeedPayload) => {
      const { vendorType, ...payload } = data
      const uploadRequests = {
        [VendorType.ContainerVendor]: updateContainerVendorFeed,
        [VendorType.Parts]: updatePartsFeed,
        [VendorType.Carrier]: null,
        [VendorType.Depot]: null,
        [VendorType.Modifications]: updateModificationFeed,
      }
      const request = uploadRequests[vendorType]
      request?.(payload)
    },
    [updateContainerVendorFeed, updateModificationFeed, updatePartsFeed],
  )

  const allData = {
    [VendorType.ContainerVendor]: containerVendorFeedData,
    [VendorType.Parts]: partsData,
    [VendorType.Carrier]: undefined,
    [VendorType.Depot]: undefined,
    [VendorType.Modifications]: modificationFeedData,
  }

  const { Form, handleSubmit, setValue, watch } = useForm<FeedUrlForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    defaultValues: getDefaultValues({
      type: uploadTypeOptions[0].value,
      feedData: allData[uploadTypeOptions[0].value],
    }),
  })
  const type = watch('vendorType')
  const feedData = allData[type]

  const checkIfDataChanged = () => {
    const allFieldValues = watch()

    const defaultValues = getDefaultValues({
      type,
      feedData,
    })

    return !isEqual(allFieldValues, defaultValues)
  }

  useEffect(() => {
    setValue('scrappingSettings', getScrappingSettingsDefaultValues(feedData?.scrappingSettings))
    setValue('resaleMargin', getMarginDefaultValues(feedData?.resaleMargin))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const isDataChanged = checkIfDataChanged()

  if (isLoadingContainerVendorData || isLoadingModificationData || isLoadingPartsData) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner size="large" />
      </Styled.SpinnerWrapper>
    )
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <VendorTypeItem uploadTypeOptions={uploadTypeOptions} />
      <Spacer mb={4} />
      <FeedSettings />
      <Spacer mb={7} />
      <ConfirmItem
        isLoading={isModificationFeedLoading || isContainerVendorFeedLoading || isPartsLoading}
        isDisabled={!isDataChanged}
      />
    </Form>
  )
}
