import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ReferenceNumberType } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { hashRoute } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { postReferenceNumber } from '../queryApi'

export const usePostReferenceNumber = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  return useMutation(postReferenceNumber, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.ReferenceNumberCreate)
      queryClient.setQueryData([QueryKey.GetReferenceNumberById, data.reference.id], data)

      const message = {
        [ReferenceNumberType.Booking]: 'Booking Number created',
        [ReferenceNumberType.Redelivery]: 'Redelivery Number created',
        [ReferenceNumberType.VendorRelease]: 'Vendor Release Number created',
        [ReferenceNumberType.Pickup]: 'Pickup Number created',
      }[data.reference.type]
      showNotification({ title: message, type: 'success' })

      navigate(hashRoute.reference(data.reference.id))
    },
  })
}
