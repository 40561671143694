import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { route } from 'constant'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { CreateAssetCodePayload, postAssetCode } from '../queryApi'

export const useCreateAssetCode = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()
  const navigate = useNavigate()

  const { mutate, ...rest } = useMutation(postAssetCode, {
    onSuccess: (data) => {
      dispatchAppEvent(AppEvent.AssetCodeCreate)

      queryClient.setQueryData([QueryKey.GetAssetCodeById, data.id], data)
      showNotification({ title: 'Asset code created', type: 'success' })
      navigate(`${route.assetCodes}/${data.id}`)
    },
  })

  const create = useCallback(
    (payload: CreateAssetCodePayload) => {
      mutate(payload)
    },
    [mutate],
  )

  return {
    create,
    ...rest,
  }
}
