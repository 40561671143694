import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { EntityId } from 'api'
import { QueryKey } from 'enums'
import { route } from 'constant'

import { getItemById } from '../queryApi'

export const useGetItemById = (id?: EntityId) => {
  const navigate = useNavigate()
  const { data, isLoading, isFetching, fetchStatus, ...rest } = useQuery(
    [QueryKey.GetItemById, id],
    () => getItemById(id),
    {
      enabled: !!id,
      onError: () => {
        navigate(route.items)
      },
    },
  )

  return {
    data,
    isLoading: (isLoading || isFetching) && fetchStatus !== 'idle',
    ...rest,
  }
}
