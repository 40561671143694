import * as Yup from 'yup'

import { AssetCondition, AssetHolder, AssetNumberType, AssetStatus, AssetTransactionType, ContainerColor } from 'api'
import { dateValidation, numberBasicValidation } from 'utils'

import { SelectSingleOption } from 'designSystem'

import { getAssetNumberTypeByAssetNumber } from '../elements/AssetNumberTypeView/utils/getAssetNumberTypeByAssetNumber'

export const validationSchema = Yup.object({
  assetNumber: Yup.string()
    .when(['$prevAssetNumberType', '$prevAssetNumber'], ([$prevAssetNumberType, $prevAssetNumber], schema) => {
      if ($prevAssetNumberType === AssetNumberType.Temporary) {
        return schema.test('canChangeTo', 'You can change type to Original only', (value) => {
          const newType = getAssetNumberTypeByAssetNumber(value)
          return $prevAssetNumber === value || newType === AssetNumberType.Original
        })
      }

      if ($prevAssetNumberType === AssetNumberType.Original) {
        return schema.test('canChangeTo', 'You can change type to Original or Rental only', (value) => {
          const newType = getAssetNumberTypeByAssetNumber(value)
          return $prevAssetNumber === value || newType !== AssetNumberType.Temporary
        })
      }

      return schema
    })
    .required('Asset number is required')
    .max(100, 'Max length of 100 characters'),
  assetNumberType: Yup.mixed<AssetNumberType>()
    .oneOf(Object.values(AssetNumberType))
    .required('Asset number type is required'),
  status: Yup.mixed<AssetStatus>().when('$assetView', ([$assetView], schema) => {
    if ($assetView) {
      return Yup.mixed<AssetStatus>().oneOf(Object.values(AssetStatus)).required('Asset status is required')
    } else return schema.nullable()
  }),
  assetHolder: Yup.mixed<AssetHolder>().oneOf(Object.values(AssetHolder)).required('Asset holder is required'),
  condition: Yup.mixed<AssetCondition>().oneOf(Object.values(AssetCondition)).required('Condition is required'),
  transactionType: Yup.mixed<AssetTransactionType>()
    .oneOf(Object.values(AssetTransactionType))
    .required('Transaction type is required'),
  birthmarkNumber: Yup.string().max(100, 'Max length of 100 characters').required('Birthmark number is required'),
  birthmarkSize: Yup.string().max(100, 'Max length of 100 characters').required('Birthmark size is required'),
  isBilled: Yup.boolean().nullable().notRequired().default(false),
  isReceived: Yup.boolean().nullable().notRequired().default(false),
  cost: numberBasicValidation.nullable().positive('Cost should be positive number'),
  locationCodeId: Yup.number().notRequired().nullable(),
  loanNumber: Yup.string().max(100, 'Max length of 100 characters').notRequired(),
  loanExpirationDate: dateValidation.when('loanNumber', {
    is: (loanNumber: string) => !!loanNumber,
    then: (schema) => schema.required('Loan Expiration Date is required'),
    otherwise: (schema) => schema.nullable().default(undefined),
  }),
  itemsIds: Yup.array()
    .of(
      Yup.mixed<SelectSingleOption>()
        .required('Item SKU is required')
        .test('items', 'At least one item is required', (_, record) => {
          const isEmptyFieldsExist =
            record?.parent?.filter((item: number | SelectSingleOption | undefined) => {
              if (typeof item === 'number' || item === undefined) {
                return item !== undefined
              } else {
                return item?.value !== undefined
              }
            }).length === 0

          return !isEmptyFieldsExist
        }),
    )

    .required('Items are required'),
  billingReference: Yup.string().nullable().notRequired(),
  vendorDepotId: numberBasicValidation.nullable(),
  containerColor: Yup.mixed<ContainerColor>().nullable().notRequired().default(null),
  vendorId: numberBasicValidation.nullable(),
  ageOfUnit: dateValidation.nullable().notRequired(),
})
