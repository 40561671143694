import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { DataMappingPayload } from '../types/dataMappingForm'

import { postDataMapping } from '../queryApi'

export const usePostDataMapping = (vendorId: number) => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(postDataMapping, {
    onSuccess: () => {
      dispatchAppEvent(AppEvent.DataMappingCreate)
      showNotification({ title: 'Data mapping added', type: 'success' })
    },
  })

  const post = useCallback(
    (payload: DataMappingPayload) => {
      mutate({ vendorId, data: payload })
    },
    [mutate, vendorId],
  )

  return {
    post,
    ...rest,
  }
}
