import { route } from 'constant'

export const breadcrumbsData = [
  {
    title: 'Location codes',
    url: route.locationCodes,
  },
  {
    title: 'Create new Location Code',
  },
]
