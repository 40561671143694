import { useQuery } from '@tanstack/react-query'

import { getItems, GetItemsQueryParams } from 'api'
import { QueryKey } from 'enums'

export const useGetItems = (params: GetItemsQueryParams) => {
  const { data, ...rest } = useQuery([QueryKey.GetItems, params], () => getItems(params), {
    keepPreviousData: true,
  })

  const { total, data: items } = data || {}

  return {
    data: items,
    total,
    ...rest,
  }
}
