import * as Yup from 'yup'

import { ItemSkuRegexp, PasswordRegexp, TwoLettersAfterLastDotRegex } from 'constant'

import { MarginSettingMeasure, MarginSettingOperator } from 'api/types/marginSetting'

import { isValidPhoneNumber } from './isValidPhoneNumber'
import { isValueUniqueInArray } from './isValueUniqueInArray'

export const numberBasicValidation = Yup.number()
  .typeError('Not valid numbers format')
  .max(9999999.99, 'Number is too large')

export const passwordValidation = Yup.string()
  .trim()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters')
  .matches(
    PasswordRegexp,
    'Should not contain space, should contain at least one letter, should contain at least one number, should contain at least one special character',
  )

export const emailValidation = Yup.string()
  .email('Invalid email')
  .required('Email is required')
  .max(100, 'Email must be shorter than or equal to 100 characters')
  .matches(TwoLettersAfterLastDotRegex, 'Invalid email')

export const phoneNumberValidation = Yup.string().test('validPhoneNumber', 'Invalid phone number', (val) =>
  val ? isValidPhoneNumber(val) : true,
)

export const dateValidation = Yup.date().transform((curr, orig) => (orig === '' ? null : curr))

export const notesValidation = Yup.string().max(500, 'Max length of 500 characters').nullable().default(null)

export const addressValidation = {
  country: Yup.string().nullable(),
  city: Yup.string().max(100, 'Max length of 100 characters').nullable(),
  state: Yup.string().max(50, 'Max length of 50 characters').nullable(),
  street: Yup.string().max(200, 'Max length of 200 characters').nullable(),
  zipCode: Yup.string().max(20, 'Max length of 20 characters').nullable(),
}

export const addressValidationSchema = Yup.object(addressValidation)

export const marginOperandValidation = numberBasicValidation
  .moreThan(0, 'Should be positive number')
  .max(20000, 'Should be less than 20,000')
  .notRequired()
  .nullable()

export const marginOperatorValidation = Yup.mixed<MarginSettingOperator>()
  .oneOf(Object.values(MarginSettingOperator))
  .notRequired()
  .nullable()

export const marginMeasureValidation = Yup.mixed<MarginSettingMeasure>()
  .oneOf(Object.values(MarginSettingMeasure))
  .notRequired()
  .nullable()

export const resaleMarginValidation = Yup.object({
  operator: marginOperatorValidation,
  operand: marginOperandValidation,
  measure: marginMeasureValidation,
})
  .notRequired()
  .nullable()

export const urlValidation = Yup.string().url('Invalid URL').matches(TwoLettersAfterLastDotRegex, {
  message: 'Invalid URL',
  excludeEmptyString: true,
})

export const scrappingSettingsValidation = Yup.object({
  feedUrl: urlValidation.when('isEnabled', {
    is: (isEnabled: boolean) => isEnabled,
    then: (schema) => schema.required('Feed URL is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  fetchSchedule: Yup.string().notRequired().nullable(),
  isEnabled: Yup.boolean().nullable(),
})

export const resalePriceOverwriteRuleSchema = Yup.object({
  vendorId: Yup.number().required('Vendor name is required'),
  condOperator: Yup.string().required('Condition operator is required'),
  condQuantity: numberBasicValidation
    .required('Condition quantity is required')
    .moreThan(0, 'Must be greater than 0')
    .max(9999, 'Must be less than 9999'),
  statementOperator: Yup.mixed<MarginSettingOperator>()
    .oneOf(Object.values(MarginSettingOperator))
    .required('Statement operator is required'),
  statementQuantity: marginOperandValidation.required('Statement quantity is required'),
  statementMeasure: Yup.string().required('Statement measure is required'),
  itemId: Yup.number().required('Item is required'),
})

export const resalePriceOverwriteRulesValidation = Yup.array()
  .of(resalePriceOverwriteRuleSchema)
  .min(1, 'At least one rule is required')
  .required()
  .test('unique-rule', 'Can not create same diff rule for same Statement Quantity and Asset Code', function (value) {
    if (!value) {
      return true
    }
    const seenRules = new Set()
    for (let i = 0; i < value.length; i++) {
      const rule = value[i]
      const key = `${rule.statementQuantity}-${rule.itemId}`
      if (seenRules.has(key)) {
        return false
      }
      seenRules.add(key)
    }
    return true
  })

export const uniqueEmailValidation = emailValidation.test('unique', 'Every email should be unique', (value, record) => {
  if (!value) return true
  const emails = record.parent
  return isValueUniqueInArray(value, emails)
})

export const skuValidation = Yup.string()
  .max(20, 'Max length of 20 characters')
  .required('SKU is required')
  .matches(ItemSkuRegexp, 'Wrong format, ex: SKU-99999A')

export const contactValidation = {
  firstName: Yup.string().max(100, 'Max length of 100 characters').required('First name is required'),
  lastName: Yup.string().max(100, 'Max length of 100 characters').required('Last name is required'),
  phoneNumber: phoneNumberValidation.notRequired().nullable(),
  phoneExtension: Yup.string().max(5, 'Max length of 5 characters').notRequired().nullable(),
  email: emailValidation.test('unique', 'Every email should be unique', (value, record: any) => {
    let emails
    if (record?.options?.context?.contacts) {
      emails = record?.options?.context?.contacts.map(({ email }: { email: string }) => email)
      return isValueUniqueInArray(value, emails, true)
    } else {
      const contacts = record.from[1].value.contacts
      emails = contacts.map(({ email }: { email: string }) => email)
      return isValueUniqueInArray(value, emails)
    }
  }),
  notes: Yup.string().max(100, 'Max length of 100 characters').nullable().notRequired(),
}

export const contactValidationSchema = Yup.object(contactValidation)
