import { VendorDataMapping } from 'api'
import { useItems, useVendorSKUs } from 'hooks'

import {
  EditableTableActions,
  EditableTableIndex,
  FormItemEditSelectSingleAsync,
  FormItemSelectSingleAsync,
  getEditableColumnFormItemName,
  isEditableTableAddNewIndex,
  TableColumnKey,
  TableColumnsType,
} from 'components'

import { Accessor } from '../types/accessor'

import { getAccessorTitle } from './getAccessorTitle'

interface DataMappingTableColumnsProps {
  vendorId: number
  offsetCount: number
  isAddNew: boolean
  onCancelNew: () => void
  onDelete: (id?: number) => void
  triggerSubmit: () => void
}

export const getTableColumns = ({
  vendorId,
  offsetCount,
  isAddNew,
  onCancelNew,
  onDelete,
  triggerSubmit,
}: DataMappingTableColumnsProps): TableColumnsType<Partial<VendorDataMapping>> => [
  {
    title: getAccessorTitle(Accessor.Index),
    key: TableColumnKey.Index,
    width: isAddNew ? 50 : undefined,
    ellipsis: true,
    render: (value, record, index) => (
      <EditableTableIndex isAddNew={isAddNew} index={index} offsetCount={offsetCount} />
    ),
  },
  {
    title: getAccessorTitle(Accessor.VendorSKU),
    key: Accessor.VendorSKU,
    dataIndex: Accessor.VendorSKU,
    width: 200,
    ellipsis: true,
    render: (vendorSKU, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItemSelectSingleAsync
          name="item.vendorSKU"
          getItems={useVendorSKUs}
          valuePropName="sku"
          nestedEntityId={vendorId}
        />
      ) : (
        <FormItemEditSelectSingleAsync
          name={getEditableColumnFormItemName('list', Accessor.VendorSKU, index, isAddNew)}
          size="small"
          fullwidth
          onSubmit={triggerSubmit}
          defaultOption={{
            value: vendorSKU,
            label: vendorSKU,
          }}
          getItems={useVendorSKUs}
          valuePropName="sku"
          nestedEntityId={vendorId}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.ItemSKU),
    key: Accessor.ItemSKU,
    dataIndex: Accessor.ItemSKU,
    width: 200,
    ellipsis: true,
    render: (itemSKU, record, index) =>
      isEditableTableAddNewIndex(index, isAddNew) ? (
        <FormItemSelectSingleAsync name="item.itemSKU" getItems={useItems} valuePropName="sku" />
      ) : (
        <FormItemEditSelectSingleAsync
          name={getEditableColumnFormItemName('list', Accessor.ItemSKU, index, isAddNew)}
          size="small"
          fullwidth
          onSubmit={triggerSubmit}
          defaultOption={{
            value: itemSKU,
            label: itemSKU,
          }}
          valuePropName="sku"
          getItems={useItems}
        />
      ),
  },
  {
    title: getAccessorTitle(Accessor.Actions),
    key: TableColumnKey.Actions,
    render: (value, record, index) => (
      <EditableTableActions
        isAddNew={isAddNew}
        index={index}
        onCancelNew={onCancelNew}
        onDelete={() => onDelete(record.id)}
      />
    ),
  },
]
