import { FC } from 'react'

import { Container } from 'designSystem'
import { ZohoIntegration } from 'components'

interface LocationCodeIntegrationsProps {
  zohoId: number
}

export const LocationCodeIntegrations: FC<LocationCodeIntegrationsProps> = ({ zohoId }) => {
  return (
    <Container fd="column" bg="white">
      <ZohoIntegration module="zohoLocationsLink" id={zohoId} />
    </Container>
  )
}
