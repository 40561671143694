import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react'

import { ItemCategory } from 'api'
import { useForm } from 'hooks'

import { EditFormItem } from 'designSystem'
import { FormItemEditInput } from 'components'

import { usePatchCategoryItem } from 'modules/items/routes/ItemCreate/elements/Categories/hooks/usePatchCategoryItem'
import { CategoryItemForm } from 'modules/items/routes/ItemCreate/elements/Categories/types/categoryItemForm'
import { CategoryPayload } from 'modules/items/routes/ItemCreate/elements/Categories/types/categoryPayload'
import { validationSchema } from 'modules/items/routes/ItemCreate/elements/Categories/utils/validationSchema'
import * as Styled from './styles'
interface FormNameProps {
  item: ItemCategory
  icon?: ReactNode
}

export const FormName: FC<FormNameProps> = ({ item, icon }) => {
  const { updateCategoryItem, isError } = usePatchCategoryItem()
  const [isEditMode, setIsEditMode] = useState(false)
  const onSubmit = useCallback(
    (payload: CategoryPayload) => {
      updateCategoryItem({ id: item.id, ...payload })
    },
    [updateCategoryItem, item.id],
  )

  const { Form, triggerSubmit } = useForm<CategoryItemForm>({
    validationSchema,
    mode: 'onChange',
    onSubmit,
    isSubmitError: isError,
    defaultValues: item,
  })

  const onClick = useCallback((e: MouseEvent) => {
    e.stopPropagation()
  }, [])

  const handleFocus = useCallback(() => {
    setIsEditMode(true)
  }, [])

  const handleBlur = useCallback(() => {
    triggerSubmit()
    setIsEditMode(false)
  }, [triggerSubmit])

  return (
    <Styled.Wrapper onClick={onClick} isEditMode={isEditMode}>
      <Form>
        <EditFormItem
          name="name"
          render={({ field }) => (
            <FormItemEditInput size="middle" align="left" {...field} onFocus={handleFocus} onBlur={handleBlur} />
          )}
        />
      </Form>
      {icon}
    </Styled.Wrapper>
  )
}
