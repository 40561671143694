import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { EntityId, Warehouse } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useDispatchAppEvent } from 'hooks'
import { AppEvent } from 'types'

import { showNotification } from 'designSystem'

import { deleteWarehouse } from '../queryApi'

export const useDeleteWarehouse = () => {
  const { dispatchAppEvent } = useDispatchAppEvent()

  const { mutate, ...rest } = useMutation(deleteWarehouse, {
    onSuccess: (response, id) => {
      dispatchAppEvent(AppEvent.WarehouseDelete)

      queryClient.setQueryData([QueryKey.GetWarehouses], (old: Warehouse[] | undefined) => {
        return old?.filter((warehouse: Warehouse) => warehouse.id !== id)
      })

      showNotification({ title: 'Warehouse deleted', type: 'success' })
    },
  })

  const deleteWarehouseEntity = useCallback(
    (id: EntityId) => {
      mutate(id)
    },
    [mutate],
  )

  return {
    deleteWarehouseEntity,
    ...rest,
  }
}
