import { FC } from 'react'

import { ReferenceNumberPickup, SystemRoles } from 'api'
import { useAssets, useCustomers, useForm, useGetCustomerBillingAddresses, useUsers, useVendors } from 'hooks'
import { currency, pickupStatusOptions, referenceLabels, referenceNumberPickupUrgencyOptions, route } from 'constant'
import { ReferenceFormAccessor, typedMemo } from 'types'
import { isDateBeforeCurrentDateEndOfDay } from 'utils'

import { Container, EditFormItem, GridLayout, Icon, Spacer, Typography } from 'designSystem'
import {
  FormContactsWithDetailsModal,
  FormItemEditDatePicker,
  FormItemEditInputNumber,
  FormItemEditSelectMultiAsync,
  FormItemEditSelectSingle,
  FormItemEditSelectSingleAsync,
  FormItemEditTextArea,
  ReferenceStatusTag,
  StripLoader,
} from 'components'
import { PickupNumberForm, ReferenceNumberByTypeProps } from 'modules/references/types'
import { getViewOnlyFields } from 'modules/references/utils/getViewOnlyFields'

import { usePickupContactsRequests } from '../../hooks/usePickupContactsRequests'
import { useUpdatePickupNumber } from './hooks/useUpdatePickupNumber'
import { pickupFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { ReferenceItemsEdit } from 'modules/references/elements/ReferenceItemsEdit'

interface PickupProps extends ReferenceNumberByTypeProps {
  data: ReferenceNumberPickup
}

const PickupBase: FC<PickupProps> = ({ canEditOnlyStatus, data }) => {
  const { update, isError, isLoading } = useUpdatePickupNumber(data.id)
  const { deleteContact } = usePickupContactsRequests(data.id)
  const { options: addressOptions = [] } = useGetCustomerBillingAddresses(data.customerId)

  const { Form, triggerSubmit } = useForm<Omit<PickupNumberForm, 'items' | 'contacts'>>({
    mode: 'onChange',
    onSubmit: update,
    isSubmitError: isError,
    validationSchema: pickupFieldsValidation,
    defaultValues: {
      ...data,
      availableAssetsIds: data.availableAssets?.map((asset) => asset.id),
    },
    viewOnlyFields: canEditOnlyStatus
      ? getViewOnlyFields(ReferenceFormAccessor.Status)
      : [ReferenceFormAccessor.CreatedAt, ReferenceFormAccessor.CustomerId],
  })

  const customerDefaultOption = data?.customer
    ? { value: data.customer.id, label: data.customer.companyName }
    : undefined

  const vendorDefaultOption = data?.vendor ? { value: data.vendor.id, label: data.vendor.companyName } : undefined

  const dispatcherDefaultOption = data?.dispatcher
    ? { value: data.dispatcher.id, label: `${data.dispatcher.firstName} ${data.dispatcher.lastName}` }
    : undefined

  return (
    <>
      <Form>
        <StripLoader noBorderRadius isLoading={isLoading} />
        <GridLayout columns={3} gap={16}>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Pickup Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Status}
                label={referenceLabels.status}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    customPreview={
                      <ReferenceStatusTag status={field.value} icon={<Icon icon="arrowDown" size={16} />} />
                    }
                    options={pickupStatusOptions}
                    onSubmit={triggerSubmit}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.CreatedAt}
                label={referenceLabels.createdAt}
                render={({ field }) => <FormItemEditDatePicker {...field} />}
              />
              <FormItemEditSelectSingleAsync
                name={ReferenceFormAccessor.CustomerId}
                label={referenceLabels.customerId}
                href={route.customers}
                getItems={useCustomers}
                defaultOption={customerDefaultOption}
              />
              <EditFormItem
                name={ReferenceFormAccessor.CustomerAddressId}
                label={referenceLabels.customerAddressId}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    lineEllipsis={1}
                    fullwidth
                    options={addressOptions}
                    onSubmit={triggerSubmit}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.PickupDate}
                label={referenceLabels.pickupDate}
                render={({ field }) => (
                  <FormItemEditDatePicker
                    {...field}
                    onBlur={triggerSubmit}
                    disabledDate={isDateBeforeCurrentDateEndOfDay}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.ScheduledPickupDate}
                label={referenceLabels.scheduledPickupDate}
                render={({ field }) => (
                  <FormItemEditDatePicker
                    {...field}
                    onBlur={triggerSubmit}
                    disabledDate={isDateBeforeCurrentDateEndOfDay}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.ExpirationDate}
                label={referenceLabels.expirationDate}
                render={({ field }) => <FormItemEditDatePicker {...field} onBlur={triggerSubmit} />}
              />
              <Container fd="column">
                <EditFormItem
                  name={ReferenceFormAccessor.Notes}
                  label={referenceLabels.notes}
                  layout="vertical"
                  render={({ field }) => (
                    <FormItemEditTextArea placeholder="Type notes" {...field} onConfirm={triggerSubmit} />
                  )}
                />
              </Container>
            </Container>
          </Container>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Related Records</Typography>
            <Container mt={4} gap={4} fd="column">
              <Typography color="secondary" fontWeight="l">
                No Related Records
              </Typography>
            </Container>
          </Container>
          <Container fd="column" width="100%">
            <Typography fontWeight="xl">Details</Typography>
            <Container mt={4} gap={4} fd="column">
              <EditFormItem
                name={ReferenceFormAccessor.Urgency}
                label={referenceLabels.urgency}
                render={({ field }) => (
                  <FormItemEditSelectSingle
                    {...field}
                    options={referenceNumberPickupUrgencyOptions}
                    onSubmit={triggerSubmit}
                  />
                )}
              />
              <FormContactsWithDetailsModal
                name={ReferenceFormAccessor.Contacts}
                title={referenceLabels.contacts}
                contacts={data?.contacts}
                onDelete={deleteContact}
              />
              <FormItemEditSelectMultiAsync
                name={ReferenceFormAccessor.AvailableAssetsIds}
                label={referenceLabels.availableAssets}
                href={route.assets}
                getItems={useAssets}
                defaultOptions={data.availableAssets?.map((asset) => ({ value: asset.id, label: asset.assetNumber }))}
                onBlur={triggerSubmit}
              />
              <FormItemEditSelectSingleAsync
                name={ReferenceFormAccessor.VendorId}
                label={referenceLabels.vendorId}
                href={route.vendors}
                getItems={useVendors}
                queryParams={{
                  type: { $contArr: ['carrier'] },
                }}
                defaultOption={vendorDefaultOption}
                onSubmit={triggerSubmit}
              />
              <FormItemEditSelectSingleAsync
                name={ReferenceFormAccessor.DispatcherId}
                label={referenceLabels.dispatcherId}
                href={route.userManagement}
                getItems={useUsers}
                defaultOption={dispatcherDefaultOption}
                queryParams={{ 'roles.systemRole': { $eq: SystemRoles.Dispatcher } }}
                join={['roles']}
                onSubmit={triggerSubmit}
              />
              <EditFormItem
                name={ReferenceFormAccessor.DispatchedDate}
                label={referenceLabels.dispatchedDate}
                render={({ field }) => <FormItemEditDatePicker {...field} onBlur={triggerSubmit} />}
              />
              <EditFormItem
                name={ReferenceFormAccessor.TransportationPrice}
                label={referenceLabels.transportationPrice}
                render={({ field }) => (
                  <FormItemEditInputNumber
                    {...field}
                    prefix={currency}
                    precision={2}
                    placeholder="Transportation Price"
                    onBlur={triggerSubmit}
                  />
                )}
              />
              <EditFormItem
                name={ReferenceFormAccessor.TransportationCost}
                label={referenceLabels.transportationCost}
                render={({ field }) => (
                  <FormItemEditInputNumber
                    {...field}
                    prefix={currency}
                    precision={2}
                    placeholder="Transportation Cost"
                    onBlur={triggerSubmit}
                  />
                )}
              />
            </Container>
          </Container>
        </GridLayout>
      </Form>
      <Spacer mt={9} />
      <ReferenceItemsEdit id={data?.id} type={data?.type} data={data?.items} isFormViewOnly={canEditOnlyStatus} />
    </>
  )
}

export const Pickup = typedMemo(PickupBase)
