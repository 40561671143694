import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReferenceNumberStatus, ReferenceNumberType } from 'api'
import { useForm } from 'hooks'
import { getReferenceTypeOptionLabel, route } from 'constant'

import { Button, Container, Divider, Typography } from 'designSystem'
import { RedeliveryNumberForm } from 'modules/references/types'

import { useCreateRedeliveryNumber } from './hooks/useCreateRedeliveryNumber'
import { redeliveryNumberFieldsValidation } from 'modules/references/constants/fieldsValidation'
import { RelatedFields } from './elements/RelatedFields'
import { ReferenceItems } from 'modules/references/elements/ReferenceItems'

import { ReferenceNumberDetailsFields } from '../ReferenceNumberDetailsFields'

export const RedeliveryNumber: FC = () => {
  const navigate = useNavigate()
  const title = getReferenceTypeOptionLabel(ReferenceNumberType.Redelivery)

  const { create, isLoading } = useCreateRedeliveryNumber()

  const { Form, handleSubmit } = useForm<RedeliveryNumberForm>({
    mode: 'onChange',
    validationSchema: redeliveryNumberFieldsValidation,
    defaultValues: {
      type: ReferenceNumberType.Redelivery,
      status: ReferenceNumberStatus.PendingRedelivery,
    },
  })

  const onClickCancel = useCallback(() => {
    navigate(route.referencesCreateChooseType)
  }, [navigate])

  return (
    <Form onSubmit={handleSubmit(create)}>
      <Container pa={4}>
        <Typography size="l" fontWeight="xl">
          Create {title} Number
        </Typography>
      </Container>
      <Container pa={4} bg="white" fd="column">
        <Container mb={9}>
          <Container fd="column" width="100%">
            <Typography size="m" fontWeight="xl">
              {title} Details
            </Typography>
            <ReferenceNumberDetailsFields type={ReferenceNumberType.Redelivery} />
          </Container>
          <Divider vertical mx={4} />
          <Container fd="column" width="100%" gap={16}>
            <Typography size="m" fontWeight="xl">
              Related Records
            </Typography>
            <RelatedFields />
          </Container>
        </Container>
        <ReferenceItems type={ReferenceNumberType.Redelivery} minCount={0} />
      </Container>
      <Container pa={4} jc="space-between" gap={8}>
        <Button onClick={onClickCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </Container>
    </Form>
  )
}
