import { FC, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { setAccessToken, setRefreshToken } from 'api'
import { queryClient } from 'config/queryClient'
import { QueryKey } from 'enums'
import { useForm } from 'hooks'
import { route } from 'constant'

import { Button, FormItem, Input, Spacer, Typography } from 'designSystem'
import { UnauthorizedLayout } from 'components/Layout'

import { useSubmitLogin } from './hooks/useSubmitLogin'
import { validationSchema } from './utils'
import { LoginForm } from './types'
import { GoogleAuth } from './elements'
import * as Styled from './styles'

import { SubmitLoginPayload, SubmitLoginResponse } from './queryApi'

const Login: FC = () => {
  const navigate = useNavigate()

  const onSuccess = useCallback(
    (data: SubmitLoginResponse) => {
      setAccessToken(data.accessToken)
      setRefreshToken(data.refreshToken)
      queryClient.invalidateQueries([QueryKey.UserMe])
      navigate(route.home)
    },
    [navigate],
  )

  const { login, isLoading } = useSubmitLogin({ onSuccess })
  const { Form, handleSubmit } = useForm<LoginForm>({
    validationSchema,
  })

  const onSubmit = useCallback(
    (loginData: SubmitLoginPayload) => {
      login(loginData)
    },
    [login],
  )

  return (
    <UnauthorizedLayout>
      <Typography display="block" as="h1" fontWeight="xl" size="xl">
        Welcome!
      </Typography>
      <Spacer mb={3} />
      <Typography display="block" as="h2" size="s" color="secondary">
        Please enter your details
      </Typography>
      <Spacer mb={4} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem name="email" label="Email">
          <Input placeholder="Email" />
        </FormItem>
        <Spacer mb={6} />
        <FormItem name="password" label="Password">
          <Input placeholder="Password" hideChars />
        </FormItem>
        <Spacer mb={6} />
        <Styled.LinkWrapper>
          <Link to={route.forgotPassword}>
            <Button type="link" size="small">
              Forgot Password?
            </Button>
          </Link>
        </Styled.LinkWrapper>
        <Spacer mb={6} />
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Log In
        </Button>
      </Form>
      <Styled.Divider plain>
        <Typography size="xs" color="secondary" fontWeight="xl">
          or
        </Typography>
      </Styled.Divider>
      <GoogleAuth onSuccess={onSuccess} />
    </UnauthorizedLayout>
  )
}

export default Login
