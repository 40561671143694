import type { ApiQueryParams, AssetCode } from 'api'
import { Pagination } from 'types'

import { api } from '../api'

export interface GetAssetCodesResponse extends Pagination {
  data?: AssetCode[]
}

export type GetAssetCodesQueryParams = ApiQueryParams

export const getAssetCodes = (params?: GetAssetCodesQueryParams): Promise<GetAssetCodesResponse> => {
  return api({
    method: 'get',
    url: '/api/v1/asset-codes/',
    params,
  })
}
