import { FC } from 'react'

import { AssetCode } from 'api'
import { route } from 'constant'
import { AppModule } from 'types'

import { Container } from 'designSystem'
import { Table, TableFilterDrawer, TableSearchSingle, TableTitle } from 'components'

import { useAssetCodes } from './hooks/useAssetCodes'
import { getTableColumns } from './utils/getTableColumns'
import { assetCodesFiltersOptions } from './constants/assetCodesFilterOptions'

export const AssetCodesList: FC = () => {
  const {
    data,
    isLoading,
    pagination,
    onTableChange,
    getFieldSorter,
    offsetCount,
    TableContextProvider,
    filterOptions,
    setFilterOptions,
  } = useAssetCodes()
  const columns = getTableColumns(getFieldSorter, offsetCount)

  const assetCodesList: AssetCode[] = data.map(({ id, ...rest }) => ({
    key: id.toString(),
    id,
    ...rest,
  }))

  return (
    <Container pa={4} display="flex" fd="column" gap={16}>
      <TableContextProvider>
        <TableTitle
          withoutDefaultPadding
          title="Asset Codes"
          total={pagination?.total}
          addRoute={route.assetCodesCreate}
          search={<TableSearchSingle searchFieldName="code" width={240} />}
          filter={
            <TableFilterDrawer
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              options={assetCodesFiltersOptions}
              module={AppModule.AssetCodes}
            />
          }
        />

        <Table<AssetCode>
          scroll={{ x: 1000 }}
          dataSource={assetCodesList}
          loading={isLoading}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </TableContextProvider>
    </Container>
  )
}
