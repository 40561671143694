import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { EntityId, PurchaseOrderItem } from 'api'
import { useFormContext } from 'hooks'
import { PurchaseOrderFormAccessor } from 'types'

import { usePatchPurchaseOrderItem } from 'modules/purchaseOrders/hooks/usePatchPurchaseOrderItem'

import { CreateNewReferenceNumberPayload } from '../types/createNewReferenceNumberPayload'

interface UseCreateReferenceNumberProps {
  itemId?: EntityId
  subLineId?: string | number
  toggleCreateReferenceNumberModal: () => void
  createFieldName?: string
}

export const useCreateReferenceNumber = ({
  itemId,
  subLineId,
  createFieldName,
  toggleCreateReferenceNumberModal,
}: UseCreateReferenceNumberProps) => {
  const { hash } = useLocation()
  const purchaseOrderId = Number(hash.split('/')[1])
  const { getValues } = useFormContext()
  const { patchAsync: patchPurchaseOrderItems, isLoading } = usePatchPurchaseOrderItem(Number(purchaseOrderId))

  const createReferenceNumber = useCallback(
    async ({ referenceNumber }: CreateNewReferenceNumberPayload) => {
      if (itemId) {
        const currentItems: PurchaseOrderItem[] = getValues(PurchaseOrderFormAccessor.Items)

        const currentItem = currentItems?.find((item) => {
          return item.id === itemId
        })

        const updatedSubLines = currentItem?.subLines?.map((subLine) => {
          if (subLine.id === subLineId) {
            return {
              id: subLine.id,
              ...(createFieldName && { [createFieldName]: referenceNumber }),
            }
          }
          return { id: subLine.id }
        })
        if (updatedSubLines) {
          await patchPurchaseOrderItems(itemId, { subLines: updatedSubLines } as Partial<PurchaseOrderItem>)
        }
      }
      toggleCreateReferenceNumberModal()
    },
    [toggleCreateReferenceNumberModal, getValues, subLineId, patchPurchaseOrderItems, itemId, createFieldName],
  )

  return {
    createReferenceNumber,
    isLoading,
  }
}
