import type { ApiQueryParams, EntityId, VendorDataMapping } from 'api'
import { api } from 'api'
import { Pagination } from 'types'

import { DataMappingPayload } from './types/dataMappingForm'

export interface GetDataMappingResponse extends Pagination {
  data?: VendorDataMapping[]
}

export type GetDataMappingQueryParams = ApiQueryParams & {
  vendorId: EntityId
}

export const getDataMapping = ({ vendorId, ...params }: GetDataMappingQueryParams): Promise<GetDataMappingResponse> => {
  return api({
    method: 'get',
    url: `/api/v1/vendors/${vendorId}/sku-mapping`,
    params,
  })
}

export const postDataMapping = ({
  vendorId,
  data,
}: {
  vendorId?: EntityId
  data?: DataMappingPayload
}): Promise<VendorDataMapping> => {
  return api({
    method: 'post',
    url: `/api/v1/vendors/${vendorId}/sku-mapping`,
    data,
  })
}

export const patchDataMapping = ({
  vendorId,
  id,
  data,
}: {
  vendorId?: EntityId
  id?: EntityId
  data?: DataMappingPayload
}): Promise<VendorDataMapping> => {
  return api({
    method: 'patch',
    url: `/api/v1/vendors/${vendorId}/sku-mapping/${id}`,
    data,
  })
}

export const deleteDataMapping = ({ vendorId, id }: { vendorId?: EntityId; id?: EntityId }): Promise<void> => {
  return api({
    method: 'delete',
    url: `/api/v1/vendors/${vendorId}/sku-mapping/${id}`,
  })
}
