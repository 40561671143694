import { FC, useCallback } from 'react'

import { Address } from 'api'
import { useForm, useGetCustomerShippingAddresses } from 'hooks'

import { Container } from 'designSystem'
import { FormItemEditAddressGroup, Spinner } from 'components'

import { usePatchCustomerShippingAddress } from './hooks/usePatchCustomerShippingAddress'
import { usePostCustomerShippingAddress } from './hooks/usePostCustomerShippingAddress'
import { useRemoveCustomerShippingAddress } from './hooks/useRemoveCustomerShippingAddress'
import { validationSchema } from './constants/validationSchema'
import { maxShippingAddressesCount } from 'modules/customers/constants/maxRecordsCount'
import { CustomerShippingAddressesForm } from './types/customerShippingAddressesForm'

interface CustomerShippingAddressesProps {
  customerId: number
}

export const CustomerShippingAddresses: FC<CustomerShippingAddressesProps> = ({ customerId }) => {
  const { data: shippingAddresses, isLoading } = useGetCustomerShippingAddresses(customerId)
  const { post, isError: isPostError, isLoading: isPostLoading } = usePostCustomerShippingAddress(customerId)
  const { patch, isError: isPatchError, isLoading: isPatchLoading } = usePatchCustomerShippingAddress(customerId)
  const { remove, isError: isRemoveError, isLoading: isRemoveLoading } = useRemoveCustomerShippingAddress(customerId)

  const { Form } = useForm<CustomerShippingAddressesForm>({
    validationSchema,
    mode: 'onChange',
    isSubmitError: isRemoveError || isPostError || isPatchError,
    isLoading: isRemoveLoading || isPostLoading || isPatchLoading,
    defaultValues: { shippingAddresses },
  })

  const onSubmitPost = useCallback(
    (payload: Address) => {
      post(payload)
    },
    [post],
  )

  const onSubmitPatch = useCallback(
    (payload: Address) => {
      patch(payload)
    },
    [patch],
  )

  const onDelete = useCallback(
    (id: number) => {
      remove(id)
    },
    [remove],
  )

  return (
    <Form>
      <FormItemEditAddressGroup
        title="Shipping Addresses"
        name="shippingAddresses"
        maxCount={maxShippingAddressesCount}
        onSubmitPost={onSubmitPost}
        onSubmitPatch={onSubmitPatch}
        onDelete={onDelete}
        isLoading={isPostLoading || isPatchLoading}
      />
      {isLoading && (
        <Container ai="center" jc="center" minHeight={200}>
          <Spinner size="large" />
        </Container>
      )}
    </Form>
  )
}
